import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import "./Selector.scss"

export function Selector({items, submit, item}) {

      const { t, i18n } = useTranslation("main");
    const [currentItem, setCurrentItem] = useState(items.find(element => element.value === item) || {value: '', text: ''})
    const [itemsExpanded, setItemsExpanded] = useState(false)

    function handleChangeItem(item) {
        setCurrentItem(item)
    }

    useEffect(() => {
        if (currentItem) {
            submit(currentItem.value)
        }
    }, [currentItem])

    function handleExpand() {
        setItemsExpanded(!itemsExpanded)
    }

    useEffect(() => {
        if (itemsExpanded) {
            document.addEventListener("click", closeDropdown)
        }
    }, [itemsExpanded])

    function closeDropdown(event) {
        event.preventDefault()
        setItemsExpanded(false)
        document.removeEventListener("click", closeDropdown)
    }

    return <div className="dropdown-container">
        <div className="drop-down-wrapper" onClick={handleExpand}>
            <div
                className={
                    itemsExpanded ? "drop-down expanded" : "drop-down"
                }
            >
                <input
                    placeholder={t("profile.form.language")}
                    readOnly
                    className={"input-selector"}
                    value={currentItem.label}
                />
                <div className="drop-down-contents">
                    {items.map(item => (
                        <div
                            key={item.value}
                            className={
                                currentItem.value === item.value
                                    ? "drop-down-option active"
                                    : "drop-down-option"
                            }
                            onClick={() => handleChangeItem(item)}
                        >
                            {item.label}
                        </div>
                    ))}
                </div>
            </div>
        </div>
    </div>

}
