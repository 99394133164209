import React from "react"
import Lottie from "react-lottie"
import cutleryAnimationData from "../lotties/dish_loader"

function LoadingPage() {
  const options = {
    loop: true,
    autoplay: true,
    animationData: cutleryAnimationData,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice"
    }
  }

  return (
    <div className="loading-animation-wrapper">
      <Lottie
        options={options}
        height={300}
        width={300}
        isClickToPauseDisabled={true}
      />
    </div>
  )
}

export default LoadingPage
