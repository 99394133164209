import React from "react"

function MessageCard({message}) {
  return (
    <div className="message-card">
      <div>{message}</div>
    </div>
  )
}

export default MessageCard
