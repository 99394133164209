import React from "react"
import { useTranslation } from "react-i18next"
import { connect } from "react-redux"
import "../main/MainPage.scss"

function UsersDropdown({
  usersExpanded,
  onExpand,
  searchRef,
  userSearchText,
  handleSearchUserChange,
  users,
  selectUser,
  searchUser,
  userInfo
}) {
    const { t, i18n } = useTranslation("main");
  const canSelfSelect = !["Department User", "Admin"].includes(userInfo.Role)

  return (
    <div className={usersExpanded ? "drop-down expanded" : "drop-down"}>
      <a onClick={onExpand}>
        {usersExpanded
          ? t("main.select_order_user")
          : searchUser.Name || t("main.no_users")}
      </a>
      <div ref={searchRef} className="search drop-down-search">
        <input
          className="search-field"
          onInput={ev => {
              handleSearchUserChange({
                  target: {
                      value: ev?.currentTarget?.value ?? ''
                  }
              })
          }}
        />
      </div>
      <ul>
        {canSelfSelect && (
          <li
            className={
              searchUser.UserID === userInfo.UserID
                ? "active self-user"
                : "self-user"
            }
            onClick={() => selectUser(userInfo.UserID)}
          >
            <a>{t("main.choose_self")}</a>
          </li>
        )}
        {users.map((el, index) => (
          <li
            className={searchUser.UserID === el.UserID ? "active" : ""}
            onClick={() => selectUser(el.UserID)}
            key={el.UserID}
          >
            <a>{t("main.order_for") + el.Name}</a>
          </li>
        ))}
      </ul>
    </div>
  )
}

function mapStateToProps(state) {
  return {
    searchUser: state.search.searchUser,
    userInfo: state.login.userInfo
  }
}

export default connect(mapStateToProps, null)(UsersDropdown)
