import React, { useState } from "react"
import { connect } from "react-redux"
import NavItem from "./NavItem"
import historyIcon from "../../images/history_icon.svg"
import mainIcon from "../../images/main_icon.svg"
import storeIcon from "../../images/store_icon.svg"
import profileIcon from "../../images/profile_icon.svg"
import supportIcon from "../../images/support_icon.svg"
import faqIcon from "../../images/faq_icon.svg"
import termsIcon from "../../images/terms_icon.svg"
import logoutIcon from "../../images/logout_icon.svg"
import logoutBigIcon from "../../images/logout_icon_big.svg"
import closeIcon from "../../images/header/close-icon.svg"
import mmenuLogoIcon from "../../images/header/mmenu_logo.png"
import { logout } from "../../redux/actions/loginActions"
import { useTranslation } from "react-i18next"
import { Link } from "react-router-dom"
import "./Navbar.scss"
import { setSearchUser } from "../../redux/actions/searchActions"
import { selectCustomer } from "../../redux/actions/adminActions"

function Navbar({
  open,
  setOpen,
  setShowContactUs,
  logout,
  setSearchUser,
  selectCustomer,
  userInfo,
  ...props
}) {
    const { t, i18n } = useTranslation("main");
  const isAuthorized = userInfo ? userInfo.confirmedLogin : false

  const isCordova = window.hasOwnProperty("cordova")
  const [appVersion, setAppVersion] = useState("")

  const isRoleDelivery = isAuthorized && userInfo.Role === "Delivery"
  const isRoleAdmin = isAuthorized && userInfo.Role === "Admin"
  const isRoleDepartmentUser =
    isAuthorized && userInfo.Role === "Department User"

  const menuItems = [
    {
      name: `${t("navigation.history")}`,
      icon: historyIcon,
      url: "history",
      handleClick: () => {
        setOpen(false)
      },
      isHidden: isRoleDepartmentUser || isRoleDelivery
    },

    {
      name: `${t("navigation.main")}`,
      icon: mainIcon,
      url: "main",
      handleClick: () => {
        setOpen(false)
      },
      isHidden: isRoleDelivery
    },
    {
      name: `${t("navigation.store")}`,
      icon: storeIcon,
      url: "store",
      handleClick: () => {
        setOpen(false)
      },
      isHidden: isRoleDelivery || process.env.REACT_APP_FLAVOR=='NY'
    },
    {
      name: `${t("navigation.profile")}`,
      icon: profileIcon,
      url: "account",
      handleClick: () => {
        setOpen(false)
      },
      isHidden: isRoleDepartmentUser || isRoleAdmin || isRoleDelivery
    },
    {
      name: `${t("navigation.support")}`,
      icon: supportIcon,
      url: "support",
      handleClick: event => {
        event.preventDefault()
        setShowContactUs(true)
        setOpen(false)
      }
    },
    {
      name: `${t("navigation.faq")}`,
      icon: faqIcon,
      url: "faq",
      handleClick: () => {
        setOpen(false)
      }
    },
    {
      name: `${t("navigation.logout")}`,
      icon: logoutBigIcon,
      url: "logout",
      handleClick: () => {
        setOpen(false)
        setSearchUser({})
        selectCustomer(null)
        logout()
      }
    }
  ]

  useState(() => {
    if (isCordova) {
      window.cordova.getAppVersion.getVersionNumber(result => {
        setAppVersion(result)
      })
    }
  }, [])

  return (
      <div className={open ? "menu-wrapper" : "menu-wrapper close"}>
      <div className="menu-bg">
        <div className={(i18n.language === 'en' ? "menu-reverse" : "menu")}>
          {isAuthorized ? (
            <div className="menu-content">
              <div className="menu-top">
                <img
                  src={closeIcon}
                  alt="open"
                  onClick={() => setOpen(!open)}
                />
                <img src={mmenuLogoIcon} alt="Dish tag" />
              </div>
              <div className="menu-name__logo">
                <span>
                  {t("navigation.greeting_to_user")}, {userInfo.FirstName}
                </span>
              </div>

              <nav className="menu-nav">
                {menuItems
                  .filter(el => !el.isHidden)
                  .map((el, index) => (
                    <NavItem
                      name={el.name}
                      img={el.icon}
                      url={el.url}
                      key={index}
                      handleClick={el.handleClick}
                    />
                  ))}
              </nav>
            </div>
          ) : null}
          <div className="menu-footer">
            <div className="menu-description">
              {isCordova && <span>{appVersion}</span>}
              <div className="links-wrapper">
                <Link
                  className="menu-terms"
                  to="/terms"
                  onClick={() => setOpen(false)}
                >
                  <span>{t("navigation.terms")}</span>
                </Link>
                <Link
                  className="menu-terms"
                  to="/privacy"
                  onClick={() => setOpen(false)}
                >
                  <span>{t("navigation.privacy")}</span>
                </Link>
              </div>
              <img src={termsIcon} alt="" />
            </div>
            <div className="menu-logout">
              <span
                onClick={() => {
                  setOpen(false)
                  setSearchUser({})
                  selectCustomer(null)
                  logout()
                }}
              >
                <span>{t("navigation.logout")}</span>
                <img src={logoutIcon} alt="" />
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

function mapStateToProps(state) {
  return {
    userInfo: state.login.userInfo
  }
}

const mapDispatchToProps = {
  logout,
  setSearchUser,
  selectCustomer
}

export default connect(mapStateToProps, mapDispatchToProps)(Navbar)
