import React, { useEffect, useRef } from "react"
import { connect } from "react-redux"
import "../main/MainPage.scss"
import { selectCustomer } from "../../redux/actions/adminActions"
import CustomersDropdown from "./CustomersDropdown"
import LocationsDropdown from "./LocationsDropdown"
import UsersDropdown from "./UsersDropdown"
import { useImmer } from "use-immer"
import BudgetsDropdown from "./BudgetsDropdown";
import MealDeliveryTimeDropdown from "./MealDeliveryTimeDropdown";

function DropdownPanel({
  users,
  locations,
  mealDeliveryTimes,
  onSelectUser,
  onSelectLocation,
  onSelectMealDeliveryTime,
  onSelectBudget,
  showLocations,
  showMealDeliveryTimes,
  showOverlay,
  budgets,
  mobile,
  ...props
}) {
  const [state, setState] = useImmer({
    dropDownExpanded: false,
    userSearchText: "",
    locationSearchText: "",
    customerSearchText: "",

    usersExpanded: false,
    locationsExpanded: false,
    customersExpanded: false,

    budgetSearchText: "",
    budgetsExpanded: false,
    mealDeliveryTimesExpanded: false,
  })

  const dropdownSearchCustomerDesktop = useRef(null)
  const dropdownSearchCustomerMobile = useRef(null)
  const dropdownSearchUserDesktop = useRef(null)
  const dropdownSearchUserMobile = useRef(null)
  const dropdownSearchLocationDesktop = useRef(null)
  const dropdownSearchLocationMobile = useRef(null)
  const dropdownSearchMealDeliveryTimeMobile = useRef(null)

  const dropdownSearchBudgetDesktop = useRef(null)
  const dropdownSearchBudgetMobile = useRef(null)

  useEffect(() => {
    if (
      state.usersExpanded ||
      state.locationsExpanded ||
      state.customersExpanded ||
      state.budgetsExpanded ||
        state.mealDeliveryTimesExpanded
    ) {
      document.addEventListener("click", closeDropdown)
    }
  }, [state.usersExpanded, state.locationsExpanded, state.customersExpanded,  state.budgetsExpanded, state.mealDeliveryTimesExpanded])

  function closeDropdown(event) {
    event.preventDefault()
    const isTargetNotSearchField = (dropdownSearchUserDesktop.current
        ? !dropdownSearchUserDesktop.current.contains(event.target)
        : true) && (dropdownSearchUserMobile.current
        ? !dropdownSearchUserMobile.current.contains(event.target)
        : true) && (dropdownSearchCustomerDesktop.current
        ? !dropdownSearchCustomerDesktop.current.contains(event.target)
        : true) && (dropdownSearchCustomerMobile.current
        ? !dropdownSearchCustomerMobile.current.contains(event.target)
        : true) && (dropdownSearchLocationDesktop.current
        ? !dropdownSearchLocationDesktop.current.contains(event.target)
        : true) && (dropdownSearchLocationMobile.current
        ? !dropdownSearchLocationMobile.current.contains(event.target)
        : true) && (dropdownSearchBudgetDesktop.current
          ? !dropdownSearchBudgetDesktop.current.contains(event.target)
          : true) && (dropdownSearchBudgetMobile.current
            ? !dropdownSearchBudgetMobile.current.contains(event.target)
            : true) && (dropdownSearchMealDeliveryTimeMobile.current
        ? !dropdownSearchMealDeliveryTimeMobile.current.contains(event.target)
        : true)
    if (isTargetNotSearchField) {
      setState(draft => {
        draft.usersExpanded = false
        draft.locationsExpanded = false
        draft.customersExpanded = false
        draft.budgetsExpanded = false
        draft.mealDeliveryTimesExpanded = false
        draft.dropDownExpanded = false
      })
      document.removeEventListener("click", closeDropdown)
    }
  }

  function handleSearchBudgetChange(event) {
    setState(draft => {
      draft.budgetSearchText = event?.target?.value ?? ''
    })
  }

  function handleSearchUserChange(event) {
    setState(draft => {
      draft.userSearchText = event?.target?.value ?? ''
    })
  }

  function handleSearchLocationChange(event) {
    setState(draft => {
      draft.locationSearchText = event?.target?.value ?? ''
    })
  }

  function handleSearchMealDeliveryTimeChange(event) {
    setState(draft => {
      draft.mealDeliveryTimeSearchText = event?.target?.value ?? ''
    })
  }

  function handleSearchCustomerChange(event) {
    setState(draft => {
      draft.customerSearchText = event?.target?.value ?? ''
    })
  }

  function handleDesctopUsersExpand(event) {
    setState(draft => {
      draft.usersExpanded = !state.usersExpanded
      draft.userSearchText = ""
    })
  }

  function handleDesctopLocationsExpand(event) {
    setState(draft => {
      draft.locationsExpanded = !state.locationsExpanded
      draft.locationSearchText = ""
    })
  }

  function handleDesctopBudgetsExpand(event) {
    setState(draft => {
      draft.budgetsExpanded = !state.budgetsExpanded
      draft.budgetsearchText = ""
    })
  }


  function handleDesctopCustomersExpand(event) {
    setState(draft => {
      draft.customersExpanded = !state.customersExpanded
      draft.customersearchText = ""
    })
  }

  function handleExpand(targetName) {
    switch (targetName) {
      case "user":
        setState(draft => {
          draft.usersExpanded = !state.usersExpanded
          draft.userSearchText = ""
          draft.dropDownExpanded = !state.usersExpanded
        })
        break
      case "location":
        setState(draft => {
          draft.locationsExpanded = !state.locationsExpanded
          draft.locationSearchText = ""
          draft.dropDownExpanded = !state.locationsExpanded
        })
        break
      case "customer":
        setState(draft => {
          draft.customersExpanded = !state.customersExpanded
          draft.customerSearchText = ""
          draft.dropDownExpanded = !state.customersExpanded
        })
        break
      case "budget":
        setState(draft => {
          draft.budgetsExpanded = !state.budgetsExpanded
          draft.budgetSearchText = ""
          draft.dropDownExpanded = !state.budgetsExpanded
        })
        break
      case "mealDeliveryTime":
        setState(draft => {
          draft.mealDeliveryTimesExpanded = !state.mealDeliveryTimesExpanded
          draft.mealDeliveryTimeSearchText = ""
          draft.dropDownExpanded = !state.mealDeliveryTimesExpanded
        })
        break
      default:
    }
  }

  function renderCustomerDropdown(onExpand, ref) {
    return (
      <CustomersDropdown
        customersExpanded={state.customersExpanded}
        onExpand={onExpand}
        searchRef={ref}
        customerSearchText={state.customerSearchText}
        handleSearchCustomerChange={handleSearchCustomerChange}
      />
    )
  }



  function renderMealDeliveryTimeDropdown(onExpand, ref) {
    return (
        <MealDeliveryTimeDropdown
            mealDeliveryTimesExpanded={state.mealDeliveryTimesExpanded}
            onExpand={onExpand}
            searchRef={ref}
            handleSearchMealDeliveryTimeChange={handleSearchMealDeliveryTimeChange}
            mealDeliveryTimes={filterMealDeliveryTimes()}
            selectedMealDeliveryTime={props.searchMealDeliveryTime}
            selectMealDeliveryTime={onSelectMealDeliveryTime}
        />
    )
  }

  function renderLocationDropdown(onExpand, ref) {
    return (
      <LocationsDropdown
        locationsExpanded={state.locationsExpanded}
        onExpand={onExpand}
        searchRef={ref}
        locationSearchText={state.locationSearchText}
        handleSearchLocationChange={handleSearchLocationChange}
        locations={filterLocations()}
        selectedLocation={props.searchLocation}
        selectLocation={onSelectLocation}
      />
    )
  }

  function renderBudgetDropdown(onExpand, ref) {
    return (
        <BudgetsDropdown
            budgetsExpanded={state.budgetsExpanded}
            onExpand={onExpand}
            searchRef={ref}
            budgets={filterBudgets()}
            selectedBudget={props.selectedBudget}
            selectBudget={onSelectBudget}
        />
    )
  }

  function renderUserDropdown(onExpand, ref) {
    return (
      <UsersDropdown
        usersExpanded={state.usersExpanded}
        onExpand={onExpand}
        searchRef={ref}
        userSearchText={state.userSearchText}
        handleSearchUserChange={handleSearchUserChange}
        users={filterUsers()}
        selectUser={onSelectUser}
      />
    )
  }

  function filterBudgets() {
    return budgets
  }

  function filterUsers() {
    return users.filter(
      el =>
        el.Name.toLocaleLowerCase().includes(
          state.userSearchText.toLocaleLowerCase()
        ) && el.UserID !== props.userInfo.UserID
    )
  }

  function filterMealDeliveryTimes() {
    return mealDeliveryTimes
  }

  function filterLocations() {
    return locations.filter(el =>
      el.Name.toLocaleLowerCase().includes(
        state.locationSearchText.toLocaleLowerCase()
      )
    )
  }
  if (mobile) {
    return (
      <>
        <div
          className={
            showOverlay || state.dropDownExpanded ? "modal-overlay" : ""
          }
        ></div>
        {budgets && budgets.length > 1 ? (
            <div className="configuration budget">
              {renderBudgetDropdown(
                  () => handleExpand("budget"),
                  dropdownSearchBudgetMobile
              )}
            </div>
        ) : null}
        {props.selectedCustomer ? (
          <div className="configuration customer">
            {renderCustomerDropdown(
              () => handleExpand("customer"),
              dropdownSearchCustomerMobile
            )}
          </div>
        ) : null}
        {users && users.length > 1 ? (
          <div className="configuration user">
            {renderUserDropdown(
              () => handleExpand("user"),
              dropdownSearchUserMobile
            )}
          </div>
        ) : null}
        {showLocations && locations && locations.length > 1 ? (
          <div className="configuration location">
            {renderLocationDropdown(
              () => handleExpand("location"),
              dropdownSearchLocationMobile
            )}
          </div>
        ) : null}
        {showMealDeliveryTimes && mealDeliveryTimes && mealDeliveryTimes.length > 1 ? (
            <div className="configuration location  ">
              {renderMealDeliveryTimeDropdown(
                  () => handleExpand("mealDeliveryTime"),
                  dropdownSearchMealDeliveryTimeMobile
              )}
            </div>
        ) : null}
      </>
    )
  }

  return (
    <>
      {props.selectedCustomer ? (
        <div
          className={
            state.customersExpanded
              ? "configuration customer expanded-parent"
              : "configuration customer"
          }
        >
          {renderCustomerDropdown(
            handleDesctopCustomersExpand,
            dropdownSearchCustomerDesktop
          )}
        </div>
      ) : null}
      {budgets && budgets.length > 0 ? (
          <div
              className={
                state.budgetsExpanded
                    ? "configuration budget expanded-parent"
                    : "configuration budget"
              }
          >
            {renderBudgetDropdown(
                handleDesctopBudgetsExpand,
                dropdownSearchBudgetDesktop
            )}
          </div>
      ) : null}
      {showLocations && locations.length > 1 ? (
        <div
          className={
            state.locationsExpanded
              ? "configuration location expanded-parent"
              : "configuration location"
          }
        >
          {renderLocationDropdown(
            handleDesctopLocationsExpand,
            dropdownSearchLocationDesktop
          )}
        </div>
      ) : null}
      {users && users.length > 1 ? (
        <div
          className={
            state.usersExpanded
              ? "configuration user expanded-parent"
              : "configuration user"
          }
        >
          {renderUserDropdown(
            handleDesctopUsersExpand,
            dropdownSearchUserDesktop
          )}
        </div>
      ) : null}
    </>
  )
}

function mapStateToProps(state) {
  return {
    selectedCustomer: state.admin.selectedCustomer,
    searchLocation: state.search.searchLocation,
    searchBudget: state.search.searchBudget,
    userInfo: state.login.userInfo
  }
}

const mapDispatchToProps = {
  selectCustomer
}

export default connect(mapStateToProps, mapDispatchToProps)(DropdownPanel)
