import React, {useState} from "react"
import ProfileIcon from "../../images/profile_icon_outline_small.svg"
import {useTranslation} from "react-i18next"
import "./BankAccountPage.scss"
import {connect} from "react-redux"
import {setError} from "../../redux/actions/errorHandlerActions"
import {setSuccess} from "../../redux/actions/successHandlerActions"
import {editBankUserInfo} from "../../redux/actions/loginActions"
import NotifyWindow from "../NotifyWindow"
import {withRouter} from "react-router"
import Field from "../profile/Field";
import {editUserBankInfo, getBankList, getBankBranchList, editUserBankInfoSendOtp, editUserBankInfoReSendOtp} from "./profileCrud"

import {getBankImage} from "./bankImageMapper";
import {useImmer} from "use-immer";
import InputMask from "react-input-mask";

function BankPersonalInformation(props) {

    const { t, i18n } = useTranslation("main");

    const {
        BankAccountName: bankAccountName,
        BankAccountNumber: bankAccountNumber,
        BankNumber: bankNumber,
        BankBranchNumber: bankBranchNumber
    } = props.bankInfo || {}

    const [formState, setFormState] = useState({
        bankAccountName: {value: bankAccountName || '', error: ""},
        bankAccountNumber: {value: bankAccountNumber || '', error: ""},
        bankNumber: {value: bankNumber || '', error: ""},
        bankBranchNumber: {value: bankBranchNumber || '', error: ""},
        otpId: {value: '', error: ""},
        otp: {value: '', error: ""},
    })

    const [state, setState] = useImmer({
        isOtpSending: false,
        timerValidTo: undefined,
        isLoading: false,
    })

    const [showSuccessDialog, setShowSuccessDialog] = useState(false)
    const [bankList, setBankList] = React.useState(null)
    const [bankBranchList, setBankBranchList] = React.useState(null)

    const generalInfo = [{
        label: `${t("profile.form.account_name")}:`,
        icon: ProfileIcon,
        name: "bankAccountName",
        value: formState.bankAccountName.value,
        error: formState.bankAccountName.error,
    }, {
        label: `${t("profile.form.account_number")}:`,
        icon: ProfileIcon,
        name: "bankAccountNumber",
        value: formState.bankAccountNumber.value,
        error: formState.bankAccountNumber.error,
    },]

    const bankNumberInfo = {
        label: `${t("profile.form.bank_number")}:`,
        icon: ProfileIcon,
        name: "bankNumber",
        value: formState.bankNumber.value,
        error: formState.bankNumber.error,
        type: 'select'
    };

    const bankBranchInfo = {
        label: `${t("profile.form.branch_number")}:`,
        icon: ProfileIcon,
        name: "bankBranchNumber",
        value: formState.bankBranchNumber.value,
        error: formState.bankBranchNumber.error,
        type: 'select'
    }

    function handleFormChange(event) {
        const {name, value} = event.target
        setFormState({
            ...formState, [name]: {value, isInvalid: false}
        })
    }

    function handleResend() {
        if (state.isLoading) {
            return;
        }
        setState(draft => {
            draft.isLoading = true;
        })
        editUserBankInfoReSendOtp(formState.otpId.value)
            .then((response) => {
                props.setSuccess({}, t("profile.api_success.bank_info_otp_resend"))
                setState(draft => {
                    draft.isLoading = false;
                })
            })
            .catch(error => {
                console.error(error)
                props.setError(error, t("profile.api_errors.failed_otp_resend_not_expired"))
                setState(draft => {
                    draft.isLoading = false;
                })
            })
    }

    function handleOTPSave() {
        if (state.isLoading) {
            return;
        }
        const isInValid = handleValidationForm();
        if (isInValid) {
            return;
        }
        setState(draft => {
            draft.isLoading = true;
        })
        editUserBankInfoSendOtp()
            .then((response) => {
                setFormState({
                    ...formState,
                    otpId: {
                        value: response.data.OTPID,
                        error: ''
                    }
                })
                const validTo = new Date(response.data.ValidTo);
                setState(draft => {
                    draft.timerValidTo = validTo;
                    draft.isOtpSending = true;
                    draft.isLoading = false;
                })
                const intervalId = setInterval(() => {
                    if (validTo < new Date()) {
                        console.log('Completed')
                        resetTempDataForm();
                        clearInterval(intervalId);
                    }
                }, 1000);
            })
            .catch(error => {
                console.error(error)
                props.setError(error, t("profile.api_errors.failed_edit_bank_user_info"))
                setState(draft => {
                    draft.isLoading = false;
                })
            })
    }

    function handleValidationForm() {
        if (!formState.bankAccountName.value || !formState.bankAccountNumber.value ||
            !formState.bankNumber.value || !formState.bankBranchNumber.value) {
            setFormState({
                ...formState, bankAccountName: {
                    ...formState.bankAccountName,
                    error: !formState.bankAccountName.value ? t("login.contact_panel.account_name_invalid") : ""
                }, bankAccountNumber: {
                    ...formState.bankAccountNumber,
                    error: !formState.bankAccountNumber.value ? t("login.contact_panel.account_number_invalid") : ""
                }, bankNumber: {
                    ...formState.bankNumber,
                    error: !formState.bankNumber.value ? t("login.contact_panel.bank_number_invalid") : ""
                }, bankBranchNumber: {
                    ...formState.bankBranchNumber,
                    error: !formState.bankBranchNumber.value ? t("login.contact_panel.bank_number_invalid") : ""
                }
            })
            return true;
        }
        return false;
    }

    function resetTempDataForm() {
        setFormState({
            ...formState,
            otpId: {
                value: '',
                error: ''
            },
            otp: {
                value: '',
                error: ''
            }
        });
        setState((draft) => {
            draft.timerValidTo = undefined;
            draft.isOtpSending = false;
            draft.isLoading = false;
        })
    }

    function handleSave(event) {
        event.preventDefault()
        if (state.isLoading) {
            return;
        }
        if (/^[0-9] [0-9] [0-9] [0-9] [0-9] [0-9]$/.test(formState.otp.value)) {
            const newUserData = {
                ...props.bankInfo,
                BankAccountName: formState.bankAccountName.value,
                BankAccountNumber: formState.bankAccountNumber.value,
                BankNumber: formState.bankNumber.value,
                BankBranchNumber: formState.bankBranchNumber.value,
                OTPID: formState.otpId.value,
                OTPCode: formState.otp.value.replace(/ /g,'')
            }
            const isInValid = handleValidationForm();
            if (isInValid) {
                return;
            }
            setState(draft => {
                draft.isLoading = true;
            })
            editUserBankInfo(newUserData)
                .then(() => {
                    props.editBankUserInfo(newUserData)
                    setShowSuccessDialog(true)
                    resetTempDataForm();
                    setState(draft => {
                        draft.isLoading = false;
                    })
                })
                .catch(error => {
                    console.error(error)
                    props.setError(error, t("profile.api_errors.failed_edit_bank_user_info"))
                    setState(draft => {
                        draft.isLoading = false;
                    })
                })
        }
    }

    useState(() => {
        loadBanks(() => {
            if (formState.bankNumber.value) {
                handleChangeBankNumber(formState.bankNumber)
            }
        });
    }, [])

    function loadBanks(callback) {
        getBankList().then(({data}) => {
            const items = data.result.records.map(it => {
                return {
                    value: it.Bank_Code, text: it.Bank_Name,
                }
            });
            setBankList(items);
            if (callback) {
                callback();
            }
        })
        .catch(error => {
            props.setError(error, error?.response?.data?.Message);
        });
    }

    function handleChangeBankBranchNumber(env) {
        setFormState({
            ...formState, bankBranchNumber: {
                value: +env.value,
            },
        })
    }

    function handleChangeBankNumber(env) {
        setFormState({
            ...formState, bankNumber: {
                value: +env.value,
            },
        })
        loadBankBranchNumbers(+env.value);
    }

    function loadBankBranchNumbers(bankId) {
        getBankBranchList(bankId).then(({data}) => {
            setBankBranchList(data.result.records.map(item => {
                return {
                    value: item.Branch_Code, text: item.Branch_Name,
                }
            }));
        })
        .catch(error => {
            props.setError(error, error?.response?.data?.Message);
        });
    }

    return (<>
            <NotifyWindow
                show={showSuccessDialog}
                primaryText={t("profile.success_window.primary")}
                confirmText={t("profile.success_window.continue")}
                onConfirm={() => {
                    setShowSuccessDialog(false)
                    props.history.push("/main")
                }}
                onClose={() => setShowSuccessDialog(false)}
            />
            <div className="profile-content-wrapper">
                <h1 className="mobile-only">{t("profile.personal_bank_information")}</h1>
                <div className="content-wrapper" dir={i18n.dir()}>
                    <div className="forms-wrapper">
                        <h1 className="desktop-only">
                            {t("profile.personal_bank_information")}
                        </h1>
                        <div className="forms-section">
                            {generalInfo.map((el, index) => (<Field
                                    type={el.type}
                                    key={el.label}
                                    label={el.error || el.label}
                                    icon={el.icon}
                                    name={el.name}
                                    value={el.value}
                                    error={el.error}
                                    inputMask={el.inputMask}
                                    direction={el.direction}
                                    onChange={handleFormChange}
                                    disabled={el.disabled || state.isOtpSending}
                                ></Field>))}

                            <div className='full-width'>
                                <div>
                                    {bankNumberInfo && bankList && bankList.length > 0 && (<Field
                                        type={bankNumberInfo.type}
                                        key={bankNumberInfo.label}
                                        label={bankNumberInfo.error || bankNumberInfo.label}
                                        icon={bankNumberInfo.icon}
                                        name={bankNumberInfo.name}
                                        value={+formState.bankNumber.value}
                                        error={bankNumberInfo.error}
                                        direction={bankNumberInfo.direction}
                                        onChange={ev => handleChangeBankNumber(ev)}
                                        disabled={bankNumberInfo.disabled || state.isOtpSending}
                                        values={bankList || []}
                                        className={'full-width'}
                                    ></Field>)}
                                    <img src={getBankImage(bankNumberInfo.value)} className='info-image'/>
                                </div>
                                <div>
                                    {bankBranchInfo && bankBranchList && bankBranchList.length > 0 && (<Field
                                        type={bankBranchInfo.type}
                                        key={bankBranchInfo.label}
                                        label={bankBranchInfo.error || bankBranchInfo.label}
                                        icon={bankBranchInfo.icon}
                                        name={bankBranchInfo.name}
                                        value={+formState.bankBranchNumber.value}
                                        error={bankBranchInfo.error}
                                        direction={bankBranchInfo.direction}
                                        onChange={ev => handleChangeBankBranchNumber(ev)}
                                        disabled={bankBranchInfo.disabled || state.isOtpSending}
                                        values={bankBranchList || []}
                                        className={'full-width'}
                                    ></Field>)}
                                </div>
                            </div>

                        </div>
                        <div className="divider"/>
                        <div className="divider"/>
                        {
                            !state.isOtpSending && (
                                <a className="forms-save-button" onClick={handleOTPSave}>
                                    {t("profile.form.save")}
                                </a>
                            )
                        }
                        {
                            state.isOtpSending && (
                                <div>
                                    <div className="lowest-row" dir={i18n.dir()}>
                                        <div
                                            className={
                                                formState.otp?.error
                                                    ? "common-border-row invalid-input-field error-field"
                                                    : "common-border-row"
                                            }
                                            dir="ltr"
                                        >
                                            <InputMask
                                                name="otp"
                                                mask="9 9 9 9 9 9"
                                                className="otp-input"
                                                value={formState.otp?.value}
                                                onChange={handleFormChange}
                                            ></InputMask>
                                        </div>
                                    </div>
                                    <div className='otp-form-resend'>
                                        <a className="forms-save-button" onClick={handleSave}>
                                            {t("profile.form.submit")}
                                        </a>
                                        <a onClick={handleResend} className="forms-link-button">
                                            {t("profile.form.resend")}
                                        </a>
                                    </div>

                                </div>
                            )
                        }

                    </div>
                </div>
            </div>
        </>)
}

function mapStateToProps(state) {
    return {}
}

const mapDispatchToProps = {
    setError, setSuccess, editBankUserInfo,
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(BankPersonalInformation))
