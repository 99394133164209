import React from "react"
import Lottie from "react-lottie"
import animationData from "../lotties/start_button_anim"
import animationDataEn from "../lotties/start_button_anim_En"

function SendOtpButton({ lang }) {
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: lang === "En" ? animationDataEn : animationData,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice"
    }
  }
  return <Lottie options={defaultOptions} isClickToPauseDisabled={true} />
}

export default SendOtpButton
