import React from "react"
import { useTranslation } from "react-i18next"
import { Link } from 'react-router-dom'
import "./MainPage.scss"
import dishLogoWhite from "../../images/dish_logo_white.png"

function LogoFooter() {
    const { t, i18n } = useTranslation("main");

  return (
    <div className="main-page-footer">
      <div className="main-page-footer-wrapper">
        <img className="main-page-footer-img" src={dishLogoWhite}></img>
        <span className="main-page-footer-span">Digital work-day menu</span>
      </div>
      <div className="main-page-footer-link">
        <Link to="/terms">{t("main.footer.terms")}</Link>
        <Link to="/privacy">{t("main.footer.privacy")}</Link>
      </div>
    </div>
  )
}

export default LogoFooter
