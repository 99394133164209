import moment from "moment"
import { useEffect, useState } from "react"
import i18next from "i18next";

export function toFixedNumber(num, digits) {
  const pow = Math.pow(10, digits)
  return Math.round(num * pow) / pow
}

export function useCurrentWidth() {
  const getWidth = () =>
    window.innerWidth ||
    document.documentElement.clientWidth ||
    document.body.clientWidth
  let [width, setWidth] = useState(getWidth())

  useEffect(() => {
    let timeoutId = null
    const resizeListener = () => {
      clearTimeout(timeoutId)
      timeoutId = setTimeout(() => setWidth(getWidth()), 150)
    }
    window.addEventListener("resize", resizeListener)

    return () => {
      window.removeEventListener("resize", resizeListener)
    }
  }, [])

  return width
}

const dateFormat = "DD/MM/YYYY"
const timeFormat = "HH:mm"

export function formatDate(date) {
  return moment(date).format(dateFormat)
}

export function formatDateForApi(date) {
  return moment(date).format("YYYY-MM-DD")
}

export function ignoreTimeZone(dateSource) {
  const date = new Date(dateSource.slice(0, 10));
   const TZoffset = date.getTimezoneOffset() * 60000;
   if (TZoffset < 0){
    return new Date(date.getTime() - TZoffset);
   }
   else{
    return new Date(date.getTime() + TZoffset);
   }

}

export function ignoreTimeZoneStr(dateSource) {
  return ignoreTimeZone(dateSource).toUTCString();
}

export function formatDateWithWeekDay(date, dayFirst, divider) {
  const format = divider
    ? dateFormat.replaceAll("/", divider)
    : dateFormat
  return moment(date)
    .locale(i18next.language || process.env.REACT_APP_APPLICATION_LANGUAGE || 'he')
    .format(dayFirst ? `dddd ${format}` : `${format} dddd`)
}

export function formatDateToWeekDay(date) {
  return moment(date).locale(i18next.language || process.env.REACT_APP_APPLICATION_LANGUAGE || 'he').format("dddd")
}

export function formatDateForTimer(date) {
  return moment(date).format(`${dateFormat} | ${timeFormat}`)
}

export function formatTime(date) {
  return moment(date).format(timeFormat)
}
