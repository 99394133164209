import axios from "axios"

export function editUserBankInfo(newUserData) {
  return axios.patch(`/users/bankinfo`, newUserData)
}

export function editUserBankInfoSendOtp() {
  return axios.post(`/users/bankinfo/sendotp`, {})
}

export function editUserBankInfoReSendOtp(OTPID) {
  return axios.post(`/users/bankinfo/resendotp`, {OTPID})
}

export function getBankList() {
  const banks = axios.create({})
  return banks.get(process.env.REACT_APP_BANK_PATH + process.env.REACT_APP_BANK_LIST, {})
}

export function getBankBranchList(bankId) {
  const banks = axios.create({
    baseURL: process.env.REACT_APP_BANK_PATH
  })
  return banks.get(process.env.REACT_APP_BANK_PATH +
      process.env.REACT_APP_BANK_BRANCH_LIST + JSON.stringify({Bank_Code: bankId}), {})
}

