import { Types } from "../actionTypes"

export function saveTempItemToOrder(userID, orderID) {
  return { type: Types.SEND_ITEM_TO_TEMP_ORDER, data: {userID, orderID} }
}
export function clearCart(userID) {
  return { type: Types.CLEAR_CART, data: userID, employeeSum: 0 }
}
export function incCount(userID, quantity, employeeSum) {
  quantity = quantity || 1;
  return { type: Types.INCREASE_COUNT, data: {userId: userID, quantity: quantity || 1, employeeSum: employeeSum || 0} }
}
export function decCount(userID, employeeSum) {
  return { type: Types.DECREASE_COUNT, data: userID, employeeSum: employeeSum || 0 }
}
