import React, {useState} from "react"
import ModalWindow from "./ModalWindow"
import closeGray from "../images/header/close-gray-icon.svg"
import dishTagRed from "../images/dish_logo_full.svg"
function PreviewWindow({
  show,
  url,
  onConfirm,
  onClose
}) {

  return (
    <ModalWindow show={show}>
      <img
        className="confirmation-window-close-icon"
        src={closeGray}
        alt="Close icon"
        onClick={(ev) => onClose(ev)}
      />

      <div className="confirmation-window-tag">

          <div style={{overflow:'scroll',height:600}}>
              <img src={url} onError={({ currentTarget }) => {
                  currentTarget.onerror = null; // prevents looping
                  currentTarget.src=dishTagRed;
              }} className='full-width'/>
          </div>
      </div>

      <div className="confirmation-window-footer justify-content-center">
        <button className="button" onClick={(ev) => {
            onConfirm(ev);
        }}>
          OK
        </button>
      </div>
    </ModalWindow>
  )
}

export default PreviewWindow
