import React from "react"
import "./SummaryMobile.scss"
import { useTranslation } from "react-i18next"
import {connect, useSelector} from "react-redux"

function SummaryMobile({ searchUser, handleRedirectToOrders}) {
    const { t, i18n } = useTranslation("main");
  const userCart = useSelector(state => state.cart[searchUser?.UserID]);

  return userCart?.count > 0 ? (
    <div className='summary-order' onClick={handleRedirectToOrders}>
      <div>
          <span className='counter'>{userCart?.count}</span>
      </div>
      <div className='label'>
        <span>לצפייה בהזמנה</span>
      </div>
      <div className='sum'>
        <span>₪{userCart?.employeeSum}</span>
      </div>
    </div>
  ) :
      (
          <></>
      )
}

function mapStateToProps(state) {
  return {
    userInfo: state.login.userInfo
  }
}

export default connect(mapStateToProps, null)(SummaryMobile)
