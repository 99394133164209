import { Types } from "../actionTypes"

const initialState = {
  headerVisible: false
}

export default function headerReducer(state = initialState, action) {
  switch (action.type) {
    case Types.SET_HEADER_VISIBLE:
      return {
        ...state,
        headerVisible: true
      }
    default:
      return state
  }
}
