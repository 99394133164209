import React, { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import "./DeliveryPage.scss"

function DeliveryPage(props) {
    const { t, i18n } = useTranslation("main");

  return <div></div>
}

export default DeliveryPage
