import axios from "axios"

export function getUserInfo(id, cancelToken) {
  return axios.get(`/Login/GetUserInfo/${id}`, {
    cancelToken
  })
}

export function getUserInfoWithoutId(id, cancelToken) {
    return axios.get(`/Login/GetUserInfo`, {
        cancelToken
    })
}

export function getUserBankInfoWithoutId(cancelToken) {
    return axios.get(`/users/bankinfo`, {
        cancelToken
    })
}

export function getUserSettings(cancelToken) {
    return axios.get(`/users/usersettings`, {
        cancelToken
    })
}

export function getUserBudget(id, date, orderId, cancelToken) {
    return axios.get(`/Budget/${date}/${id}?orderId=` + (orderId || ''), {
        cancelToken
    })
}

export function GetUserMealInfoItem(itemId, meal, cancelToken) {
  return axios.get(`/UserMealInfo/Item?itemId=${itemId}&meal=${meal}`, {
    cancelToken
  })
}

export function getUserMealInfo(userID, mealID, date, orderId, deliveryTimeId, cancelToken) {
  return axios.get(`/UserMealInfo/${userID}/${mealID}/${date}?orderId=${orderId || ''}&deliveryTimeId=${deliveryTimeId || ''}`, {
    cancelToken
  })
}

export function getUserSpecialDateInfo(userID, mealID, date, cancelToken) {
    return axios.get(`/SpecialDate/${userID}/${date}`, {
        cancelToken
    })
}

export function getStoreMealInfo(userID, mealID, date, deliveryTimeId) {
  return axios.get(`/UserMealInfo/${userID}/${mealID}/Store${date ? '?date=' + date : ''}${
      deliveryTimeId && date ?
      `&deliveryTimeId=${deliveryTimeId}` : (deliveryTimeId && !date ? `?deliveryTimeId=${deliveryTimeId}` : '')}`)
}

export function updateOrder(userID, orderID, orderItemID, data) {
  return axios.patch(
    `/User/${userID}/Orders/${orderID}/Item/${orderItemID}`,
    data
  )
}

export function updateComboOrder(userID, orderID, orderItemID, data) {
    return axios.patch(
        `/User/${userID}/Orders/${orderID}/Combo/${orderItemID}`,
        data
    )
}

export function deleteOrder(userID, orderID, itemID) {
  return axios.post(
    `/User/${userID}/Orders/${orderID}/Item/${itemID}/Cancellation`,
    null
  )
}

export function deleteComboOrder(userID, orderID, comboID) {
    return axios.post(
        `/User/${userID}/Orders/${orderID}/Combo/${comboID}/Cancellation`,
        null
    )
}

export function saveItemToTempStorageByAgainOrder(userID, orderItemId, date) {
    return axios.post(`/User/${userID}/Orders/Item/${orderItemId}/${date}`, null)
}

export function getAviableDays(userID, orderItemId, cancelToken) {
    return axios.get(`/User/${userID}/Orders/Item/Days/${orderItemId}`, {
        cancelToken
    })
}

export function saveItemToTempStorage(userID, orderID, orderItem, mealDeliveryTimeId) {
  return axios.post(`/User/${userID}/Orders/Item`, {
    Id: orderID,
    Item: orderItem,
    mealDeliveryTimeId
  })
}

export function saveItemsToTempStorage(userID, orderID, orderCombo, mealDeliveryTimeId) {
    return axios.post(`/User/${userID}/Orders/Combo`, {
        Id: orderID,
        Combo: orderCombo,
        mealDeliveryTimeId
    })
}

export function updateFavorites(FavoriteItems) {
    return axios.patch(
        `/users/usersettings`,
        FavoriteItems
    )
}

export function getMealItemUserInfo(
  itemID,
  mealID,
  userID,
  date,
  userOrderId,
  tempItemId,
  orderItemId
) {
  return axios.get(
    `/Item/${itemID}/MealItemUserInfo/${mealID}/${userID}/${date}/details`,
    { params: { userOrderId, tempItemId, orderItemId } }
  )
}

export function getMealComboUserInfo(
    comboID,
    mealID,
    userID,
    date,
    userOrderId,
    tempComboId,
    orderComboId
) {
    return axios.get(
        `/Combo/${comboID}/MealComboUserInfo/${mealID}/${userID}/${date}/details`,
        { params: { userOrderId, tempComboId, orderComboId } }
    )
}

export function getPriceRanges(id) {
    return axios.get(`/PriceRanges?locationId=${id}`)
}
