import React from "react"
import ModalWindow from "./../ModalWindow"
import closeGray from "../../images/header/close-gray-icon.svg"
import {useTranslation} from "react-i18next";

function OrderConfirmationWindow({
  show,
  onConfirm,
  onClose,
    order,
    orderDate
}) {
    const { t, i18n } = useTranslation("main");
  if (!order) {
    return;
  }
  const budget = +order[1]
  const wallet = +order[2]
  const customerCredit = +order[3]
  const creditCard = +order[4]
  return (
    <ModalWindow show={show}>
      <img
        className="confirmation-window-close-icon"
        src={closeGray}
        alt="Close icon"
        onClick={onClose}
      />
      <div className="confirmation-window-text">
          <span className="date">{t("main.confirmation.date")} : </span>
          <span className="value"><b>{orderDate}</b></span>
      </div>
      <div className="confirmation-window-text">
          <span className="value">{t("main.confirmation.budget")} : </span>
          <span className="item"><b>{budget} {t("main.confirmation.currency")}</b></span>
      </div>
      <div className="confirmation-window-text">
          <span className="value">{t("main.confirmation.wallet")} : </span>
          <span className="item"><b>{wallet} {t("main.confirmation.currency")}</b></span>
      </div>
      <div className="confirmation-window-text">
          <span className="value">{t("main.confirmation.creditCard")} : </span>
          <span className="item"><b>{creditCard} {t("main.confirmation.currency")}</b></span>
      </div>
      <div className="confirmation-window-text">
          <span className="value">{t("main.confirmation.customerCredit")} : </span>
          <span className="item"><b>{customerCredit} {t("main.confirmation.currency")}</b></span>
      </div>
      <div className="confirmation-window-footer">
        <button className="button confirmation-window-footer-btn" onClick={onConfirm}>
            {t("main.confirmation.pay")}
        </button>
      </div>
    </ModalWindow>
  )
}

export default OrderConfirmationWindow
