function isRetina(){
    var mediaQuery = "(-webkit-min-device-pixel-ratio: 1.5),\
            (min--moz-device-pixel-ratio: 1.5),\
            (-o-min-device-pixel-ratio: 3/2),\
            (min-resolution: 1.5dppx)";
    if (window.devicePixelRatio > 1)
        return true;
    if (window.matchMedia && window.matchMedia(mediaQuery).matches)
        return true;
    return false;
}
export const ItemImageHelper ={
 small_webp : (imageArray)=>{
    if (isRetina()){
        return  imageArray.find(i=>i.Size === 1 && i.Type === 'webp').URL;
    }
    else{
        return imageArray.find(i=>i.Size === 0 && i.Type ==='webp').URL;
    }
},

 small_jpg :  (imageArray)=>{
    if (isRetina()){
        return  imageArray.find(i=>i.Size ===1 && i.Type ==='jpg').URL;
    }
    else{
        return imageArray.find(i=>i.Size === 0 && i.Type ==='jpg').URL;
    }
},

 larg_webp : (imageArray)=>{
    if (isRetina()){
        return imageArray.find(i=>i.Size ===3 && i.Type==='webp').URL;
    }
    else{
        return imageArray.find(i=>i.Size === 2 && i.Type ==='webp').URL;
    }
},

 larg_jpg : (imageArray)=>{
    if (isRetina()){
        return imageArray.find(i=>i.Size === 3 && i.Type === 'jpg').URL;
    }
    else{
        return imageArray.find(i=>i.Size === 2 && i.Type === 'jpg').URL;
    }
}
}

export default ItemImageHelper;