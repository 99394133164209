import React from "react"
import ModalWindow from "./ModalWindow"
import closeGray from "../images/header/close-gray-icon.svg"

function ConfirmationWindow({
  show,
  icon,
  primaryText,
  secondaryText,
  confirmText,
  cancelText,
  onConfirm,
  onCancel,
  onClose,
  onConfirmHidden,
  boothWhite,
    children
}) {
  return (
    <ModalWindow show={show}>
      <img
        className="confirmation-window-close-icon"
        src={closeGray}
        alt="Close icon"
        onClick={onClose}
      />
      <div className="confirmation-window-tag">
          {
              icon && (
                  <img src={icon} alt="icon" />
              )
          }
      </div>
      <div className="confirmation-window-primary-text">{primaryText}</div>
      <div className="confirmation-window-secondary-text">{secondaryText}</div>
        {
            children && (
                <div>{
                    children
                }</div>
            )
        }

      <div className="confirmation-window-footer">
        <button className={`button white ${onConfirmHidden ? 'center' : ''}`} onClick={onCancel}>
          {cancelText}
        </button>
          {
              !onConfirmHidden && (
                  <button className={`button ${boothWhite ? 'white' : ''}`} onClick={onConfirm}>
                      {confirmText}
                  </button>
              )
          }
      </div>
    </ModalWindow>
  )
}

export default ConfirmationWindow
