import { Types } from "../actionTypes"

const initialState = { status: "" }

export default function errorHandlerReducer(state = initialState, action) {
  switch (action.type) {
    case Types.SET_ERROR:
      return {
        status: action.data.status,
        message: action.data.message
      }
    case Types.CLEAR_ERROR:
      return { status: "", message: "" }
    default:
      return state
  }
}
