import React, { useEffect, useState } from "react"
import "./CardsDropdown.scss"

function CardsDropdown({cards, selectedCard, onChangeCard}) {
  const [cardsExpanded, setCardsExpanded] = useState(false)

  function handleExpand() {
    setCardsExpanded(!cardsExpanded)
  }

  useEffect(() => {
    onChangeCard(cards[0])
  }, [])

  return (
    <div className="cards-drop-down-wrapper">
      <div className="drop-down-wrapper" onClick={handleExpand}>
        {
          selectedCard.creditCardToken && (
              <div
                  className={
                    cardsExpanded ? "drop-down expanded" : "drop-down"
                  }
              >
                <input
                    readOnly
                    className="input-pointer"
                    value={`${selectedCard.creditCardToken.slice(-4)} ***`}
                />
                <div className="drop-down-contents">
                  {cards.filter(card => card.creditCardToken !== selectedCard.creditCardToken).map(card => (
                      <div
                          key={card.creditCardToken}
                          className="drop-down-option"
                          onClick={() => onChangeCard(card)}
                      >
                        {`${card.creditCardToken?.slice(-4)} ***`}
                      </div>
                  ))}
                </div>
              </div>
          )
        }
      </div>
    </div>
  )
}

export default CardsDropdown
