import React from "react"
import dishTagRedFull from "../../images/dish_logo_full.svg"
import emailIcon from "../../images/login/email_icon.svg"
import phoneIcon from "../../images/login/phone_icon.svg"
import invalidInputIcon from "../../images/login/invalid_input_icon.svg"
import { useTranslation } from "react-i18next"
import googlePlayBtn from "../../images/login/google_play_btn.png"
import appStoreBtn from "../../images/login/app_store_btn.png"
import ErrorWindow from "../ErrorWindow"
import SendOtpButton from "../SendOtpButton"
import InputMask from "react-input-mask"

function EnterContactDataPanel({
  formState,
  onChange,
  onSubmit,
  currentMobileOS,
  onCloseError
}) {
    const { t, i18n } = useTranslation("main");
    function updateLanguage() {
        const language = localStorage.getItem('lang');
        if (!language || language == 'he') {
            localStorage.setItem('lang', 'en');
        } else {
            localStorage.setItem('lang', 'he');
        }
        window.location.reload();
    }

  return (
    <>
      {window.hasOwnProperty("cordova") && (
        <div className="get-on-mobile-panel">
          {currentMobileOS === "Android" ? (
            <img
              src={googlePlayBtn}
              alt="Google play"
              onClick={() =>
                (window.location.href =
                  "https://play.google.com/store/apps/details?id=il.dish.clientapp")
              }
            />
          ) : (
            currentMobileOS === "iOS" && (
              <img
                src={appStoreBtn}
                alt="AppStore"
                onClick={() =>
                  (window.location.href =
                    "https://apps.apple.com/us/app/dish-digital-work-day-menu/id1564618781")
                }
              />
            )
          )}
          <div dir={i18n.dir()}>{t("login.got_on_mobile_panel")}</div>
        </div>
      )}
      <div className="login-panel">
        <div 
          className={`justify-content-start px-4 py-3 lang-switcher-auth ${localStorage.getItem('lang') === 'he' ? 'lang-he' : 'lang-en'}`}
          onClick={updateLanguage}>
             {t("login.contact_panel.lang")}
        </div>
        <div className="enter-contact-data-panel">
          <div className="first-row" dir={i18n.dir()}>
            <div className="dish-tag">
              <img id="small-dish-tag" src={dishTagRedFull} alt="Dish tag" />
              <img
                id="full-dish-tag"
                src={dishTagRedFull}
                alt="Dish tag full"
              />
            </div>
            <div className="titles">
              <ErrorWindow
                show={formState.error.show}
                message={formState.error.message}
                onClose={onCloseError}
              />
              <div className="full-title">
                <div className="title">{t("login.contact_panel.title")}</div>
                <div className="after-title">
                  {t("login.contact_panel.after_title")}
                </div>
              </div>
              <div className="short-title">
                <div className="title">
                  {t("login.contact_panel.short_title")}
                </div>
                <div className="after-title">
                  {t("login.contact_panel.short_after_title")}
                </div>
              </div>
            </div>
          </div>
          <hr />
          <form onSubmit={onSubmit} noValidate>
            <div className="input-wrapper">
              <div
                className={
                  formState.email.isInvalid ? "invalid-input-field" : ""
                }
              >
                <label>
                  {formState.email.isInvalid
                    ? t("login.contact_panel.email_invalid")
                    : t("login.contact_panel.emaillbl")}{" "}
                  <img
                    src={invalidInputIcon}
                    alt="Invalid icon"
                    className="invalid-input-icon"
                  />
                </label>
                <div className="input-field-wrapper">
                  <input
                    name="email"
                    type="email"
                    value={formState.email.value}
                    disabled={formState.email.disabled}
                    onChange={onChange}
                    className="email-input"
                    placeholder="dish@dish.co.il"
                  ></input>
                  <img
                    src={emailIcon}
                    alt="Email icon"
                    className="input-type-icon"
                  />
                </div>
              </div> 
              <div className="or-column">
                <span>{t("login.contact_panel.or")}</span>
              </div>
              <div
                className={
                  formState.phone.isInvalid ? "invalid-input-field" : ""
                }
              >
                <label>
                  {formState.phone.isInvalid
                    ? t("login.contact_panel.phone_invalid")
                    : t("login.contact_panel.phonelbl")}{" "}
                  <img
                    src={invalidInputIcon}
                    alt="Invalid icon"
                    className="invalid-input-icon"
                  />
                </label>
                <div className="input-field-wrapper">
                  <InputMask
                    name="phone"
                    mask="999-9999999"
                    className="phone-input"
                    placeholder="052-0000000"
                    disabled={formState.phone.disabled}
                    value={formState.phone.value}
                    onChange={onChange}
                  ></InputMask>
                  <img
                    src={phoneIcon}
                    alt="Phone icon"
                    className="input-type-icon"
                  />
                </div>
              </div>
            </div>
            <div className="lowest-row">
              <button disabled={formState.disableSubmitForm} type="submit">
                <SendOtpButton lang={t("login.lang")} />
              </button>
            </div>
          </form>
        </div>
      </div>
    </>
  )
}

export default EnterContactDataPanel
