import React, {useState, useEffect} from "react"
import {useTranslation} from "react-i18next"
import "./ProfilePage.scss"
import {connect} from "react-redux"
import walletIcon from "../../images/wallet.png"
import walletTransferIcon from "../../images/wallet/wallet.png"
import plusIcon from "../../images/plus.png"
import minusIcon from "../../images/minus.png"
import {getWalletInfo} from "./profileCrud"
import WalletBankTransferInformation from "./WalletBankTransferInformation";
import { setError } from "../../redux/actions/errorHandlerActions"
import {useHistory} from "react-router-dom";

const WALLET_LIST = 1;
const WALLET_TRANSFER = 2;

function WalletInformation(props) {
    const history = useHistory();
    const {allowTransfer, account} = props;
    const { t, i18n } = useTranslation("main");
    const [data, setData] = useState(null);
    const [loading, setLoading] = useState(null);
    const [walletMode, setWalletMode] = useState(WALLET_LIST);

    useEffect(() => {
        loadWalletInfo();
    }, []);

    function loadWalletInfo() {
        setLoading(true);
        getWalletInfo(props.userInfo.UserID)
            .then(({data}) => {
                setData(data)
            })
            .catch(error => {
                props.setError(error, error?.response?.data?.Message);
            })
            .finally(() => {
                setLoading(false);
        });
    }

    function getDate(createdDate) {
        var allDate = createdDate.split("T")[0];
        var year = allDate.split("-")[0];
        var month = allDate.split("-")[1];
        var day = allDate.split("-")[2];
        return <span className="date">{year}.{month}.{day}</span>
    }

    function getWeekDay(createdDate) {
        var date = new Date(createdDate.split("T")[0]);
        switch (date.getDay()) {
            case 0:
                return <span className="date">{t("profile.sunday")}</span>;
                break;
            case 1:
                return <span className="date">{t("profile.monday")}</span>;
                break;
            case 2:
                return <span className="date">{t("profile.tuesday")}</span>;
                break;
            case 3:
                return <span className="date">{t("profile.wednesday")}</span>;
                break;
            case 4:
                return <span className="date">{t("profile.thursday")}</span>;
                break;
            case 5:
                return <span className="date">{t("profile.friday")}</span>;
                break;
            case 6:
                return <span className="date">{t("profile.saturday")}</span>;
                break;
            default:
                return <span className="date"></span>;
                break;
        }
    }

    function getTime(createdDate) {
        var allTime = createdDate.split("T")[1];
        var hour = allTime.split(":")[0];
        var min = allTime.split(":")[1];
        return <span className="time">{hour}:{min}</span>
    }

    function getIcon(amount) {
        if (amount > 0)
            return <img src={plusIcon}/>
        else
            return <img src={minusIcon}/>

    }

    function getStatus(amount) {
        if (amount > 0)
            return <span className="refunded-item"><b> {t("profile.budget_information_details.refunded")} </b></span>
        else
            return <span> </span>
    }

    function getDescription(description) {
        if (description != "")
            return <span><b>{description}</b></span>
        else
            return <span> </span>
    }

    const ColoredLine = ({color}) => (
        <hr
            style={{
                color: color,
                backgroundColor: color,
                height: 1,
                opacity: 0.1
            }}
        />
    );

    function transferStart() {
        if (!account) {
            history.push("/bank-profile")
        } else {
            setWalletMode(WALLET_TRANSFER);
        }
    }

    function getTransferStatus(transferStatus) {
        if (transferStatus) {
            return <span style={{color: transferStatusClass(transferStatus)}}>{t(`profile.budget_information_details.status.${transferStatus}`)}</span>
        }
        return <></>
    }

    function transferStatusClass(transferStatus) {
        switch (transferStatus) {
            case 'PENDING': {
                return '#e24047';
            }
            case 'TRANSFERRED': {
                return '#40e25b';
            }
            default: {
                return;
            }
        }
    }

    return (
        <div className="profile-content-wrapper" dir={i18n.dir()}>
            {
                walletMode == WALLET_LIST && (
                    <>
                        <div>
                            <h1><img src={walletIcon}/> {t("profile.wallet_information")} Dish </h1>
                        </div>
                        {
                            allowTransfer && (
                                <div onClick={(ev) => transferStart()}>
                                    <div className='wallet-btn'>
                            <span>
                                <img src={walletTransferIcon}/>
                            </span>
                                        <span>
                                {t("profile.wallet_information_transfer")}
                            </span>
                                    </div>
                                </div>
                            )
                        }
                        {
                            !loading && (
                                <>
                                    {(data && data.length > 0) &&
                                        <div>
                                            <div style={{
                                                background: 'rgba(255, 255, 255, 1)',
                                                boxShadow: '0px 4px 30px rgb(110 20 39 / 12%)',
                                                borderRadius: '10px',
                                                height: '52px',
                                                marginBottom: '20px',
                                                marginTop: '14px'
                                            }}>
                  <span style={{
                      fontSize: '24px',
                      textAlign: 'right',
                      marginRight: '24px'
                  }}>
                      {t("profile.balance")} : <span style={{
                      fontSize: '30px',
                      color: 'rgba(188, 30, 70, 1)',
                      fontWeight: '700'
                  }}>{t("profile.wallet_currency")}</span>
                      <span style={{
                          color: 'rgba(188, 30, 70, 1)',
                          fontWeight: '700'
                      }}>{data && data[data.length - 1] && data[data.length - 1].balance}</span>
                  </span>
                                            </div>
                                        </div>
                                    }
                                    {(data && data.length > 0) &&
                                        <h4> {t("profile.wallet_top_text")} </h4>}
                                    <div>
                                        {(data && data.length > 0) &&
                                            data.map(({description, createdDate, balance, amount, transferStatus}) => (
                                                <div key={createdDate + description}>
                                                    <div style={{height: '50px'}}>
                                                        <div style={{float: 'left', fontSize: '20px'}}>
                                                <span>
                                                    {!transferStatus && getStatus(amount)}
                                                </span>
                                                            <span style={{fontSize: '26px'}}>{t("profile.wallet_currency")}</span><span
                                                            className="amount-sum">{Math.abs(amount)}</span>
                                                            {!transferStatus && getIcon(amount)}
                                                        </div>
                                                        <div>
                                                            {getWeekDay(createdDate)} {getDate(createdDate)} {getTime(createdDate)}
                                                        </div>
                                                        {getDescription(description)}
                                                        {
                                                            transferStatus && (
                                                                <div>
                                                                    <span>{t(`profile.budget_information_details.status.description`)}: </span>
                                                                    <span>{getTransferStatus(transferStatus)}</span>
                                                                </div>
                                                            )
                                                        }

                                                    </div>
                                                    <ColoredLine color="black" style={{float: 'bottom'}}/>
                                                </div>
                                            ))}
                                    </div>
                                </>
                            )
                        }

                    </>
                )
            }
            {
                walletMode === WALLET_TRANSFER && (
                    <>
                        <WalletBankTransferInformation onBankTransferHandle={(ev) => {
                            loadWalletInfo();
                            setWalletMode(WALLET_LIST);
                        }}/>
                    </>
                )
            }
        </div>
    )
}

function mapStateToProps(state) {
    return {
        userInfo: state.login.userInfo
    }
}
const mapDispatchToProps = {
    setError
}

export default connect(mapStateToProps, mapDispatchToProps)(WalletInformation)




