import React, {useState, useEffect} from "react"
import {useTranslation} from "react-i18next"
import "./ProfilePage.scss"
import {connect} from "react-redux"
import {getWalletInfo, setBankTransfer} from "./profileCrud"
import axios from "axios";
import {getUserBankInfoWithoutId} from "../main/mainCrud";
import ConfirmationWindow from "../ConfirmationWindow";
import NotifyWindow from "../NotifyWindow";
import { setError } from "../../redux/actions/errorHandlerActions"
import warningIcon from "../../images/warning_icon_circle.svg";

function WalletBankTransferInformation(props) {

    const { t, i18n } = useTranslation("main");
    const [userBankInfo, setUserBankInfo] = React.useState(null)
    const [data, setData] = useState(null);
    const [walletBalance, setWalletBalance] = useState(null);
    const [selectedAmount, setSelectedAmount] = useState(0);
    const [selectedAmountWithFee, setSelectedAmountWithFee] = useState(0);
    const [isInvalid, setIsInvalid] = useState(false);
    const [showConfirmation, setShowConfirmation] = useState(false);
    const [showWarningPopup, setWarningPopup] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    function onChangeAmount(ev) {
        setSelectedAmount(+ev);
    }

    useEffect(() => {
        if (!userBankInfo) {
            const cancelToken = axios.CancelToken.source()
            getUserBankInfoWithoutId(cancelToken.token).then(({data}) => {
                setUserBankInfo(data);
            });
        }
    }, [userBankInfo])

    useEffect(() => {

        if (userBankInfo) {
            const amountFee = Math.round((selectedAmount * (1 + (+userBankInfo?.BankTransferFee / 100))) * 100) / 100;
            setSelectedAmountWithFee(+amountFee);
            setIsInvalid((+amountFee > walletBalance) || (+amountFee < +userBankInfo?.BankTransferMin) ||
                (+amountFee > userBankInfo?.BankTransferMax))
        }
    }, [selectedAmount])

    useEffect(() => {
        setIsLoading(true);
        getWalletInfo(props.userInfo.UserID)
            .then(({data}) => {
                setIsLoading(false);
                setData(data)
                if (data && data[data.length - 1] && data[data.length - 1].balance) {
                    setWalletBalance(data[data.length - 1].balance);
                }
            })
            .catch(error => {
                props.setError(error, error?.response?.data?.Message);
            })
    }, []);

    function submit() {
        if (isInvalid) {
            return;
        }
        setIsLoading(true);
        setBankTransfer(selectedAmount)
            .then(({data}) => {
                setIsLoading(false);
                if (props.onBankTransferHandle) {
                    props.onBankTransferHandle();
                }
            })
            .catch(error => {
                props.setError(error, error?.response?.data?.Message);
            })
    }

    return (
        <div className="profile-content-wrapper" dir={i18n.dir()}>
            <div>
                <h1>{t("profile.wallet_transfer.header")}</h1>
            </div>
            <div className='balance-title'>
                {t("profile.wallet_transfer.title")}
            </div>
            <div className='balance-title'>
                <span className='amount'>{walletBalance}</span>
            </div>
            {
                userBankInfo && (
                    <>
                        <div className='attention-container'>
                            {
                                userBankInfo.BankTransferTimeType === 'END_MONTH' && (
                                    <>
                                        {t("profile.wallet_transfer.end_month")}
                                    </>
                                )
                            }
                            {
                                userBankInfo.BankTransferTimeType === 'NOW' && (
                                    <>
                                        {t("profile.wallet_transfer.now")}
                                    </>
                                )
                            }
                        </div>
                        <div className='balance-title'>
                            <span className='amount'>{t("profile.wallet_transfer.min")}: </span>
                            <span className='amount'><b>{userBankInfo?.BankTransferMin}</b></span>
                        </div>
                        <div className='balance-title'>
                            <span className='amount'>{t("profile.wallet_transfer.max")}: </span>
                            <span className='amount'><b>{userBankInfo?.BankTransferMax}</b></span>
                        </div>
                    </>
                )
            }

            <div>
                <input
                    type="number"
                    value={selectedAmount}
                    disabled={isLoading}
                    onChange={(event) => onChangeAmount(event.target.value)}
                    className={`balance-form__amount-input ${isInvalid ? "--error" : ""}`}
                    dir="ltr"
                />
            </div>
            {
                userBankInfo?.BankTransferFee && (
                    <div className='balance-description'>
                        <span>{t("profile.wallet_transfer.balance")}</span>
                        <span className='amount'>{selectedAmountWithFee}</span>
                    </div>
                )
            }
            <div className='confirm-transfer-button-desctop'
                 onClick={(ev) => {
                     if (selectedAmountWithFee > userBankInfo?.BankTransferMax) {
                        setWarningPopup(true);
                     } else {
                         setShowConfirmation(true)
                     }
                 }}
            >
                <span>{t("profile.wallet_transfer.submit")}</span>
            </div>
            <>
                {
                    showWarningPopup && (
                        <NotifyWindow
                            show={showWarningPopup}
                            icon={warningIcon}
                            primaryText={t("order.error_window.failed_transfer_data")}
                            confirmText={t("order.error_window.continue")}
                            onConfirm={() => setWarningPopup(false)}
                            onClose={() => setWarningPopup(false)}
                        />
                    )
                }
            </>
            <>
                {
                    showConfirmation && (
                        <ConfirmationWindow
                            show={showConfirmation}
                            primaryText={t("profile.transfer_info.title")}
                            confirmText={t("profile.transfer_info.submit")}
                            cancelText={t("profile.transfer_info.cancel")}
                            onConfirm={() => {
                                setShowConfirmation(false);
                                submit();
                            }}
                            onCancel={() => {
                                setShowConfirmation(false);
                            }}
                            onClose={() => {
                                setShowConfirmation(false);
                            }}
                            children={
                                <div>
                                    <div className='balance-description'>
                                        <span>{t("profile.wallet_transfer.amount")}</span>
                                        <span className='amount'>{selectedAmount}</span>
                                    </div>
                                    <div className='balance-description'>
                                        <span>{t("profile.wallet_transfer.fee")}</span>
                                        <span className='amount'>{+userBankInfo?.BankTransferFee}</span>
                                    </div>
                                    <div className='balance-description'>
                                        <span>{t("profile.wallet_transfer.total")}</span>
                                        <span className='amount'>{selectedAmountWithFee}</span>
                                    </div>
                                    <div className='balance-description'>
                                        <span>{t("profile.wallet_transfer.estimatedDate")}</span>
                                        <span className='amount'>
                                             {
                                                 userBankInfo.BankTransferTimeType === 'END_MONTH' && (
                                                     <>
                                                         {t("profile.wallet_transfer.end_month")}
                                                     </>
                                                 )
                                             }
                                            {
                                                userBankInfo.BankTransferTimeType === 'NOW' && (
                                                    <>
                                                        {t("profile.wallet_transfer.now")}
                                                    </>
                                                )
                                            }
                                        </span>
                                    </div>
                                </div>
                            }
                        />
                    )
                }
            </>
        </div>
    )
}

function mapStateToProps(state) {
    return {
        userInfo: state.login.userInfo
    }
}

const mapDispatchToProps = {
    setError
}

export default connect(mapStateToProps, mapDispatchToProps)(WalletBankTransferInformation)




