import React, { useState, useEffect } from "react"
import { useTranslation } from "react-i18next"
import "./ProfilePage.scss"
import { connect } from "react-redux"
import budgetIcon from "../../images/billing_info_sidebar.svg"
import { getBudgetInfo, getBudgetTransactionInfo } from "./profileCrud"
import { setError } from "../../redux/actions/errorHandlerActions"
import DropdownPanel from "../configuration/DropdownPanel";
import plusIcon from "../../images/plus.png";
import minusIcon from "../../images/minus.png";

function BudgetInformation(props) {
      const { t, i18n } = useTranslation("main");
    const { innerWidth: width } = window
    const [data, setData] = useState(null);
    const [dataTransaction, setDataTransaction] = useState(null);
    const [budget, setBudget] = useState(null);

    useEffect(() => {
        getBudgetInfo(props.userInfo.UserID)
            .then(({ data }) => {
                setData(data)
                if (data.length > 0) {
                    selectBudget(data[0]);
                }
            })
            .catch(error => {
                props.setError(error, error?.response?.data?.Message );
            })
    }, []);

    function selectBudget(budget) {
        setBudget(budget)
        getBudgetTransactionInfo(budget.budgetID)
            .then((data) => {
                setDataTransaction(data.data);
            })
            .catch(error => {
                props.setError(error, error?.response?.data?.Message);
            })
    }

    const ColoredLine = ({ color }) => (
        <hr
            style={{
                color: color,
                backgroundColor: color,
                height: 1,
                opacity: 0.1
            }}
        />
    );

    function getWeekDay(createdDate) {
        var date = new Date(createdDate.split("T")[0]);
        switch (date.getDay()) {
            case 0: return <span className="date">{t("profile.sunday")}</span>; break;
            case 1: return <span className="date">{t("profile.monday")}</span>; break;
            case 2: return <span className="date">{t("profile.tuesday")}</span>; break;
            case 3: return <span className="date">{t("profile.wednesday")}</span>; break;
            case 4: return <span className="date">{t("profile.thursday")}</span>; break;
            case 5: return <span className="date">{t("profile.friday")}</span>; break;
            case 6: return <span className="date">{t("profile.saturday")}</span>; break;
            default: return <span className="date"></span>; break;
        }
    }

    function getStatus(amount) {
        if (amount < 0)
            return <span className="refunded-item"><b> {t("profile.budget_information_details.refunded")} </b></span>
        else
            return <span> </span>
    }

    function getDescription(description) {
        if (description != "")
            return <span><b>{description}</b></span>
        else
            return <span> </span>

    }

    function getDate(createdDate) {
        var allDate = createdDate.split("T")[0];
        var year = allDate.split("-")[0];
        var month = allDate.split("-")[1];
        var day = allDate.split("-")[2];
        return day + '.' + month + '.' + year;
    }

    function getIcon(amount) {
        if (amount < 0)
            return <img src={plusIcon} />
        else
            return <img src={minusIcon} />

    }

    function getTime(createdDate) {
        var allTime = createdDate.split("T")[1];
        var hour = allTime.split(":")[0];
        var min = allTime.split(":")[1];
        return <span className="time">{hour}:{min}</span>
    }

    return (
        <div className="profile-content-wrapper" dir={i18n.dir()}>
            <div>
                <h1> <img src={budgetIcon} />  {t("profile.budget_information")} Dish </h1>
            </div>
            {
                data &&
                (
                    width < 1024 ?
                        <div className="configurations mobile-only">
                            <DropdownPanel
                                budgets={data}
                                onSelectBudget={selectBudget}
                                selectedBudget={budget}
                                mobile={true}
                            />
                        </div> :
                            <div className="configurations">
                                <DropdownPanel
                                    budgets={data}
                                    onSelectBudget={selectBudget}
                                    selectedBudget={budget}
                                />
                            </div>
                )
            }
            <div >
                {dataTransaction && dataTransaction.map(({ description, createdDate, balance, amount, budgetTransactionType }) => (
                        <div key={createdDate + description}>
                            <div style={{ height: '59px' }}>
                                <div style={{ float: 'left', fontSize: '20px' }}>
                                    <span>{getStatus(amount)}</span>
                                    <span style={{ fontSize: '26px' }}>{t("profile.budget_currency")}</span>
                                    <span className="amount-sum"> {Math.abs(amount)} </span>
                                    {getIcon(amount)}
                                </div>
                                <div>
                                    {getWeekDay(createdDate)} {getDate(createdDate)}  {getTime(createdDate)}
                                </div>
                                {getDescription(description)}
                            </div>
                            <ColoredLine color="black" style={{ float: 'bottom' }} />
                        </div>
                    ))}
                    
            </div>

        </div>
    )
} 
function mapStateToProps(state) {
    return {
        userInfo: state.login.userInfo
    }
}

const mapDispatchToProps = {
    setError
}

export default connect(mapStateToProps, mapDispatchToProps)(BudgetInformation)




