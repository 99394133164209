import React, { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import Footer from "../footer/Footer"
import "./FaqPage.scss"
import { withRouter } from "react-router"
import Accordion from "./Accordion"
import axios from "axios"
import { connect } from "react-redux"
import LoadingPage from "../LoadingPage"
import backIcon from "../../images/faq/arrow_back_icon.svg"
import LogoFooter from "../main/LogoFooter"
import faqIcon from "../../images/faq_icon.svg"
import { setError } from "../../redux/actions/errorHandlerActions"
import { getFaq } from "./faqCrud"

function FaqPage(props) {
    const { t, i18n } = useTranslation("main");
  const { innerWidth: width } = window

  const [faqData, setFaqData] = useState({ data: [], isLoading: true })

  function fetchFaq(cancelToken) {
    getFaq(cancelToken.token)
      .then(({ data }) => setFaqData({ data: data, isLoading: false }))
      .catch(error => {
        console.error(error)
        props.setError(error, t("faq.api_errors.failed_to_get_faq"))
      })
  }

  function handleGoBack() {
    props.history.go(-1)
  }

  useEffect(() => {
    const cancelToken = axios.CancelToken.source()
    fetchFaq(cancelToken)
    return () => cancelToken.cancel()
  }, [])

  return (
    <div className="main-page-content-wrapper">
      <div className="faq-page-wrapper" dir={i18n.dir()}>
        <div className="top-row">
          <div className="back-link">
            {width < 1024 ? (
              <img src={backIcon} alt="back" onClick={handleGoBack} />
            ) : (
              <span onClick={handleGoBack}>{t("back")}</span>
            )}
          </div>
          <div className="title">
            <img src={faqIcon} alt="faq" className="title__icon" />
            {t("faq.title")}
          </div>
        </div>
        {faqData.isLoading ? (
          <LoadingPage />
        ) : (
          faqData.data.map(faq => (
            <Accordion key={faq.id} title={faq.question} content={faq.answer} />
          ))
        )}
      </div>
      {width < 1024 ? <Footer /> : <LogoFooter />}
    </div>
  )
}

const mapDispatchToProps = {
  setError
}

export default withRouter(connect(null, mapDispatchToProps)(FaqPage))
