import React from "react"
import { useTranslation } from "react-i18next"
import Footer from "../footer/Footer"
import "./TermsPage.scss"
import { withRouter } from "react-router"
import { connect } from "react-redux"
import LogoFooter from "../main/LogoFooter"
import { setError } from "../../redux/actions/errorHandlerActions"


function TermsPage(props) {
    const { innerWidth: width } = window
    const { t, i18n } = useTranslation("main");


return(
    <div className="page-content-wrapper">
              <div className="terms-page-wrapper" dir={i18n.dir()}>

<p className="title">
    תנאי שימוש
</p>
<p>
קבוצת S&amp;P בעלת המותג Dish (להלן: "החברה") המפעילה מערכת להזמנת ארוחות בחדר אוכל וירטואלי, מזמינה את המשתמשים במערכת, באתר ובאפליקציה, אשר באמצעותם ניתן לבצע הזמנות של ארוחות ארוזות, מוצרי מזון ומשקאות, וכל מוצר אחר לקרוא ולאשר את התקנון ותנאי השימוש כמפורט להלן:
</p>
<p>
1. בהיכנסך ל-Dish באמצעות האתר ו/או האפליקציה ו/או המערכת (להלן: 'האתר') וטרם ביצוע עסקה להזמנת ארוחות ארוזות מוצרי מזון ומשקאות ו/או כל מוצר אחר המוצע באתר, הינך מתבקש לקרוא בעיון את התנאים המפורטים להלן המהווים את תנאי ההתקשרות המשפטיים המחייבים לכל עסקה שתתבצע באמצעות האתר. התקשרויות אשר נעשות באמצעות אתר זה וכן באמצעות עובדי ונציגי Dish  ו/או קבוצת S&amp;P יהיו כפופים לתנאים הקבועים בתקנון זה. 
</p>
<p>
2. כל משתמש באתר (להלן: 'המשתמש' ו/או "המשתמש הרשום ו/או "הלקוח הרשום") מסכים כי הינו מודע לתקנון האתר, מדיניות הפרטיות (מצורף קישור) ולכללי השימוש באתר, בעצם השימוש וביצוע פעולות באתר, בכל צורה שהיא, המשתמש מצהיר ומאשר כי קרא בעיון, הבין והוא מסכים ומקבל על עצמו את תנאי השימוש ולא תהיה לו ו/או למי מטעמו כל טענה, דרישה ו/או תביעה נגד החברה, לרבות כנגד בעליה, עובדיה, נציגיה, נושאי המשרה שלה, יועציה וכן כל מי שפועל מטעמה של החברה. מלבד טענות הקשורות בהפרת התחייבויות מפורשות שניתנו למשתמש ע"י החברה והמפורטות בתקנון זה.
</p>
<p>
3. תנאים לביצוע פעולות באתר 
</p>
<p>
"לקוח רשום" – תאגיד ו/או גוף אשר התקשר עם החברה לאספקת מנות ארוזות, מוצרי מזון ומשקאות ו/או כל מוצר אחר עבור עובדיו ו/או לקוחותיו ו/או כל מי מטעמו. 
</p>
<p>
"משתמש רשום" – משתמש שאושר ע"י הלקוח הרשום לביצוע הזמנות באמצעות האתר והוקם בבסיס הנתונים באתר החברה בין אם פועל מטעם עצמו או מטעם תאגיד ו/או גוף כלשהו, המבצע הזמנת מוצרים באתר
</p>
<p>
3.1 .כל משתמש רשום יכול לבצע פעולות באתר ובלבד שהתקיימו לגביו התנאים המצטברים הבאים: 
המשתמש הרשום הינו אישיות משפטית כשירה להתחייב בכל ההתחייבויות הנובעות מביצוע פעולות באתר ובכלל זה אינו קטין, בכל הנוגע להזמנת משקאות משכרים. 
</p>
<p>
משתמש רשום המבצע תשלום באתר מבצע זאת כדין, באמצעות כרטיס אשראי תקף בישראל השייך לו ומונפק ע"י אחת מחברות האשראי המורשות לפעול בישראל, או הינו בעל הרשאה מפורשת מבעל הכרטיס, או כל אמצעי תשלום אחר שהחברה מאפשרת את השימוש בו במסגרת האתר. 
</p>
<p>
3.2 כחלק מעדכונים המתבצעים מעת לעת באתר ובשירותים הניתנים בו, החברה תהיה רשאית על פי שיקול דעתה לערוך שינויים בכל מרכיב שקיים באתר ואופן השימוש בו ובכלל זה באופן הכניסה לאתר, והזיהוי והאימות של המשתמש הרשום.
</p>
<p>
לצורך ביצוע זיהוי ואימות לאתר ולשירותיו, נדרש להזין את מספר הטלפון הנייד או את כתובת המייל שהוזן כמשתמש רשום בעת הרישום לאתר, על מנת שניתן יהיה לשלוח אליו קוד אימות או אמצעי אימות אחר לצורך זיהוי ואבטחת מידע ושמירה על הפרטיות.  יובהר שככל שלא יוזן מספר הטלפון הנייד או כתובת המייל , לא ניתן יהיה להשתמש באתר.  מספר הטלפון או הכתובת המייל ישמשו לצורך ביצוע הזיהוי והאימות והתפעול הכרוך בכך, כדי לאבטח בצורה טובה יותר את המידע, וכן ליצירת קשר לצרכי תפעול השירות, ההזמנות, תשלומים, תאום משלוחים וכדומה.
</p>
<p>
4.. הגבלת אחריות
</p>
<p>
"מפרט הרכישה" ו/או "הזמנה" – פריטים שהוזמנו ע"י משתמש רשום באתר
</p>
<p>
4.1 האתר משמש כחדר אוכל וירטואלי אשר מאפשר למשתמשים רשומים לבצע הזמנות של ארוחות ארוזות ו/או כל מוצר אחר ממטבחים, מסעדות ו/או כל ספק אחר (להלן "ספקים") המציג את מוצריו ו/או תפריטו באתר והמאפשר לבצע הזמנות באמצעות האתר באמצעי תשלום שונים החברה תהא רשאית בהתאם לשיקול דעתה הבלעדי להציג או שלא להציג את שם הספק באתר וללקוח הרשום ו/או למשתמש הרשום לא יהיו שום טענות בנוגע לכך, הפרטים וכל המידע המוצגים באתר נמסרו לחברה על ידי הספקים ונמצאים באחריותם המלאה והבלעדית, לפיכך לא תהיה לחברה או למי מטעמה אחריות בשום צורה ואופן לכל מצג וכל מידע באתר לרבות בכל הנוגע, לטיב המזון, איכותו, כשרות המזון, מרכיביו, רכיבים אלרגנים כלשהם הכלולים בו, תאורו כמנה צמחונית או טבעונית או נטולת גלוטן, ו/או לטעויות או לאי דיוקים שנפלו בכל מידע שנפל בהם. 
</p>
<p>
4.2  יובהר, כי האחריות הבלעדית על המוצרים בהזמנה חלים על הספק המייצר אותם, בהתאם לחוקים שונים ובכלל זה חוקי אחריות למוצרים פגומים, חוקי הגנת הצרכן ותעודות הכשרות של הספקים, ככל שאלו קיימים. יובהר עוד כי הפריטים המוצעים (להלן: "מוצרים")  באתר אינם מיוצרים על ידי החברה. והחברה אינה אחראית למוצרים אלו, ובכלל זה אין החברה אחראית לטריותם של מוצרים כאמור, לרכיביהם, לייצורם, להפצתם (לרבות תנאי ואופן הובלתם), לזמינותם, ויודגש כי מוצרים אלה הינם באחריותו המלאה של הספק היצרן. לפיכך, כל תלונה, טענה ו/או דרישה בקשר עם מוצרים אלה יש להפנות ישירות לספק היצרן, לפי העניין. 
</p>
<p>
4.3 למשתמש הרשום לא תעמוד כל טענה כלפי החברה או מי מטעמה בכל הנוגע למפרט הרכישה. להצגת שם הספק או לאי הצגת שם הספק. המוצרים באתר זה מוצגים בתום לב ואין בהצגתם משום המלצה או הבעת דעה לגבי טיב המוצרים, איכותם, מחירם וכ"ו, הפריטים המוצגים למשתמש המורשה באתר הינם בהתאם להסכם ההתקשרות שבין החברה ללקוח הרשום ולמשתמש הרשום ו/או למי מטעמו לא יהיו שום טענות בקשר לכך. 
</p>
<p>
4.4 החברה או מי מטעמה אינה ולא תהייה אחראית, לכל שימוש לרעה, או שלא כדין, או  שימוש במרמה או שימוש בלתי מורשה, הנעשה בכל דרך לרבות בכרטיס אשראי או כל אמצעי תשלום אחר, לשם ביצוע פעולה באתר. החברה אינה אחראית לכל נזק, לרבות בשל נוזקות או יישומי תוכנה מזיקים למיניהם, לציוד המחשב של המשתמש המורשה, למכשיר הטלפון הנייד או לכל רכוש אחר של המשתמש המורשה ו/או של צד ג' כלשהו, שיגרמו בשל גישה, גלישה או שימוש באתר.
</p>
<p>
4.5 החברה או מי מטעמה, אינה ולא תהייה אחראית כלפי המשתמש המורשה בגין אובדן רווחים ישירים או עקיפים, אובדן חסכונות, או נזקים ישירים או לא ישירים, מיוחדים או עקיפים, הנובעים מהשימוש באתר וכן מהשימוש במוצרים והשירותים שירכשו על ידי המשתמש המורשה במסגרת האתר. 
</p>
<p>
4.6 האתר תלוי גם בספקים חיצוניים (ספקיות אינטרנט) ולכן החברה, או מי מטעמה אינם אחראים לתקלות כאלה ואחרות שעלולות לפגוע בפעילות התקנית של האתר, לרבות ליצור תקלות בביצוע הזמנות, החברה לא תהיה אחראית, ואיננה מבטיחה או מתחייבת כי האתר יפעל ו/או יהיה זמין בכל עת ללא הפרעות או תקלות, וכי יהיה ללא פגם. החברה לא תהיה אחראית לאי היכולת של האתר לפעול או להיות נגיש, מכל סיבה שהיא; לרבות הפסקות בפעילות האינטרנט או רשתות, או בשל בעיות טכניות או אחרות שאינן בשליטת החברה (למשל, סיכול, כוח עליון, רשלנות של צדדים שלישיים וכ"ו) או עקב תקלה או טעות של החברה או מי מטעמה..
</p>
<p>
4.7 מקום האספקה של מפרט הרכישה יסופקו לפי הכתובת שנמסרה בעת הרישום כלקוח רשום באתר, במקרה בו ההזמנה סופקה שלא במלואה, החברה שומרת לעצמה את הזכות לספק למשתמש הרשום את המוצרים החסרים למקום האספקה או לחילופין להחזיר למשתמש המורשה את התמורה ששולמה בגין המוצרים החסרים ככל ששולמו ע"י המשתמש המורשה בלבד (ולא ע"י הלקוח הרשום) באמצעות כרטיס אשראי או אמצעי תשלום אחר.  ו/או שלא לחייב את המוצרים החסרים ו/או לזכותם במסגרת החשבונית החודשית ללקוח הרשום.
</p>
<p>
4.8 מבלי לגרוע מכלליות האמור לעיל, במידה וגורמים ו/או אירועים שאינם בשליטת החברה ו/או ספקיה יעכבו ו/או ימנעו את הזמנת מפרט הרכישה באופן מלא או באופן חלקי ו/או את אספקתם במועד האספקה ו/או למקום האספקה, ו/או אם יפגעו תקלות במחשוב ו/או מערכות הטלפון ו/או כל גורם תקשורת אחר בהשלמת הליך ההזמנה, ו/או אם בשל פגעי טבע ו/או הגבלות תנועה ו/או פעולות איבה ו/או שביתה ו/או השבתה ו/או גיוס מילואים נרחב ו/או כל גורם אחר שהינו בגדר כוח עליון ימנע ו/או ייפגע בתהליך ההזמנה ו/או ההכנה ו/או האספקה רשאית החברה להודיע על ביטול ההזמנה, כולה או חלקה, בכל עת, והחברה לא תישא בכל אחריות בקשר עם ביטול ההזמנה בנסיבות המפורטות בסעיף זה לעיל.
</p>
<p>
4.9 בנוגע לפניות בנוגע להזמנה שנתקבלה על ידי הלקוח המזמין ו/או המשתמש הרשום החברה עושה ככל שביכולתה להעניק שירות ברמה הגבוהה ביותר. במקרה של אי שבע רצון, יש לפנות לשירות הלקוחות של החברה דרך האתר או בפנייה ישירה לחברה בתוך 12 שעות ממועד האספקה, ולפרט מהן הנסיבות אשר בגינן הלקוח המזמין ו/או המשתמש הרשום אינו שבע רצון מההזמנה. שירות הלקוחות יצור קשר לבירור הפנייה, אולם יובהר כי אין בעריכת בירור כאמור כדי להוות התחייבות מראש מצד החברה לפיצוי בכל אופן ו/או בכל סכום שהוא. 
</p>
<p>
4.10 הטיפול בהזמנה לאחר אספקתה הינה באחריות הלקוח הרשום ו/או המשתמש הרשום. החברה עושה את כל הנדרש כדי למסור את ההזמנה כאשר מוצרי מזון טריים וארוזים בצורה המתאימה להם ביותר, ובטמפרטורה המתאימה להם ביותר. החברה ממליצה לשמור עליהם באותם התנאים. יודגש כי החל ממועד מסירת ההזמנה , לא תהייה החברה אחראית למצבם של ההזמנות שסופקו, לרבות לתנאים בהם אוחסנו ו/או נצרכו הזמנות אלו.
</p>
<p>
4.11. צריכת המוצרים, צריכת המזונות, המשקאות וכל יתר הפריטים במפרט ההזמנה הינה באחריותם הישירה והבלעדית של הלקוח הרשום ו/או המשתמש הרשום, והחברה לא תהיה אחראית לכל תקלה ו/או נזק מכל מין וסוג שהוא שיגרמו עקב כך, לאי התאמתם לצורכי הלקוח הרשום ו/או המשתמש הרשום ו/או למצבם הבריאותי והפיזיולוגי.
</p>
<p>
4.12. מובהר בזאת, כי באחריות הלקוח הרשום ו/או המשתמש הרשום לוודא כי אין לו ו/או לצד שלישי רגישות למוצרים ו/או רכיביהם וכן שהמוצרים אינם מכילים רכיבים העשויים להפריע ו/או לגרום למשתמש הרשום ו/או לכל צד שלישי לנזק כלשהו במידה ויצרכו סוגי מזון כלשהם המפורטים באתר, וכן לוודא כי המוצרים מתאימים לצרכיהם. החברה אינה אחראית לכל נזק בריאותי או אחר שיגרם למשתמש הרשום ו/או לכל צד שלישי בגין הזמנת מפרט הרכישה ו/או צריכתם הנובע ממצבו הבריאותי של משתמש רשום ו/או לכל צד שלישי כאמור, לרבות אלרגיות לרכיבי המוצרים.
</p>
<p>
4.13  ההצגה באתר של פריטים היא תצוגה להמחשה בלבד. באתר מושקע מאמץ רב כדי שהמוצרים השירותים והמידע המצוי בו יהיו מלאים, מדויקים ועדכניים. כל התמונות באתר, לרבות אופן וצורת ההגשה וכלי ההגשה המופיעים בהן מיועדים להמחשה בלבד. ייתכן שינוי בין מראה המוצר באתר למראהו במציאות וכן שגיאות ו/או אי דיוקים בתום לב וללקוח הרשום ו/או המשתמש הרשום לא יהיו טענות בקשר לכך. 
</p>
<p>
5 . שינוי או ביטול הזמנה 
</p>
<p>
5.1 משתמש רשום יהיה רשאי לשנות ו/או לבטל את מפרט הרכישה שהזמין עד ערב לפני מועד האספקה (בימים א' – ה') בהתאם לשעה שנקבעה במועד הרישום לאתר של הלקוח הרשום (להלן: "המועד הנקוב")  לא יתאפשרו שינויים או ביטולים של הזמנה לאחר המועד הנקוב.
</p>
<p>
5.2 כל טעות שהזין הלקוח הרשום ו/או המשתמש הרשום יהיו באחריותו המלאה לרבות כל נזק מכל מין וסוג שהוא שייגרם לו ו/או לחברה ו/או לצד שלישי אחר כלשהו בגין הטעות בהזנה בהקלדת נתונים בקשר עם ביצוע ההזמנה באתר, לרבות טעות בהקלדת מועד האספקה, מקום האספקה, פרטי אמצעי תשלום, הערות שונות וכיו"ב.
</p>
<p>
6. הגנה על זכויות קניין רוחני וסודות מסחר ותנאי שימוש
</p>
<p>
6.1 כל זכויות הקניין והקניין הרוחני הנוגעות לאתר וכל מידע אחר הקשור לאתר או הכלול בו, הינם בבעלות בלעדית של החברה ולאף אדם לא תהיה זכות, דרישה או תביעה כלפי החברה בנוגע לזכויות אלה, ולא יותר לו לעשות שימוש כלשהו בזכויות אלו, אלא באישור מראש ובכתב מהחברה בלבד. כל חומר טכנולוגי הכולל תוכנות, חומרות, קוד פיתוח, תוכניות, מפרטים, נתונים, תהליכים, המצאות ושיפורים, סימני מסחר, סודות מסחר וכל מידע אחר שניתן לזהותו בדרך כלשהי כמידע הקשור לאתר יהוו רכוש החברה בלבד ולא תהא לאיש כל זכות בהם, לרבות זכות שימוש.
</p>
<p>
6.2 שמות המסחר והסימנים המסחריים הקשורים אליהם, כפי שיוצגו באתר הינם שמות מסחריים בבעלות החברה ולא תהא לאיש כל זכות שהיא לעשות שימוש בשמות אלה או לטעון לזכויות כלשהן בשמות אלה.
</p>
<p>
6.3 כל זכויות היוצרים והקניין הרוחני באתר, בשירותים המוצעים בו ובכל התוכן הכלול והמאוגד בו הינן של החברה בלבד. אין להעתיק, להפיץ, להציג בפומבי, לבצע בפומבי, לשדר, להעמיד לרשות הציבור, לשנות, לעבד, ליצור יצירות נגזרות, למכור או להשכיר כל חלק מהם, בין על-ידי המשתמש הרשום ו/או הלקוח הרשום ובין באמצעות ו/או בשיתוף צד שלישי, לרבות בכל דרך או אמצעי בין אם אלקטרוניים, ממוחשבים, מכאניים, אופטיים, אמצעי צילום או הקלטה, או בכל אמצעי או דרך אחרים, בלא קבלת הסכמה בכתב ומראש של החברה, לפי העניין ובכפוף לתנאי ההסכמה (ככל שתינתן). בשום מקרה אין להשתמש בכל שם, סימן או סמליל ('לוגו') הזהה או דומה באופן מטעה לסימני המסחר, לסמלילים או לשמות המותגים של החברה. יש להימנע מכל פעולה או מחדל העלולים לפגוע בצורה כלשהי במוניטין של החברה. אם וככל שניתנה הסכמה כאמור, יש להימנע מלהסיר, למחוק או לשבש כל הודעה או סימן בעניין זכויות קניין רוחני, כדוגמת – סימון זכויות היוצרים ©, או סימן מסחר ® וכ"ו. 
</p>
<p>
6.4 החברה מכבדת זכויות יוצרים של אחרים ולפיכך במידה וקיים במידע או תמונה או כל מצג אחר אשר פוגעים בזכויות היוצרים של אדם או ישות כלשהי יש לפנות לחברה באמצעות דוא'ל ולציין את תוכנן ומיקומם והחברה תפעל להסירם במהירות האפשרית. 
</p>
<p>
6.5  החברה אוסרת כל שימוש בנתוני האתר. מבלי לגרוע מכלליות האמור לעיל, מובהר בזאת כי אין להשתמש בנתונים כלשהם המתפרסמים באתר ו/או המופקים ממנו לצורך הצגתם באתר אינטרנט אחר, בלא קבלת הרשאה מפורשת לכך מאת החברה, מראש ובכתב. בכלל זה, נאסר לאסוף נתונים מן האתר באמצעות תוכנות מסוג Crawlers, Robots וכיו"ב או להפיץ נתונים כאלה ברבים באופן מסחרי או במסגרת מסחרית.
</p>
<p>
6.6 ניתן להשתמש בתכני האתר באופן אישי ובלתי מסחרי בלבד, בכפוף לתנאים אלו. אין להשתמש באתר באופן אחר. האחריות לשימוש באתר ולכל תוצאה הנובעת ממנו, חלה על הלקוח הרשום ו/או המשתמש הרשום, השימוש בשירותי האתר מותר למטרות חוקיות בלבד. 
</p>
<p>
6.7 החברה אוסרת לקשר לאתר כל אתר אחר המכיל תכנים פורנוגראפיים, תכנים המעודדים לגזענות או להפליה פסולה, או המנוגדים לחוק, או שפרסומם מנוגד לחוק או המעודדים פעילות המנוגדת לחוק. אין לקשר לתכנים מהאתר, שאינם דף הבית של האתר ('קישור עמוק') ואין להציג, או לפרסם תכנים כאמור בכל דרך אחרת. החברה רשאית להורות לבטל כל קישור עמוק כאמור לפי שיקול דעתה הבלעדי. ולבטל את הקישור העמוק לאלתר ולא תהיה כל טענה, דרישה או תביעה כלפי החברה, בעלי האתר, מפעיליו, או מי מטעמם בעניין זה.
</p>
<p>
6.8 החברה אוסרת על עקיפת האמצעים בהם היא משתמשת על מנת למנוע או להגביל את הגישה לאתר. החברה אוסרת להעתיק, לתקן, לשנות, להתאים, למסור, להנגיש, לתרגם, להפנות  או להפריד כל חלק בתכנים או באתר ליצור יצירות נגזרות, לבצע, להפיץ, לתת רישיון משנה, לעשות כל שימוש מסחרי, למכור, להעביר, לעבד, לאסוף, לשלב עם תוכנה אחרת - של כל חומר הכפוף לזכויות קנייניות של החברה בכל אופן או בכל אמצעי שהוא. אין לפגוע או לשבש את פעילות האתר או השרתים או הרשתות המאחסנות את האתר. אין לבצע כל פעולה היוצרת או העלולה ליצור עומס גדול ובלתי סביר על תשתית האתר.
</p>
<p>
6.9 החברה רשאית בכל עת, לחסום ו/או להגביל באופן קבוע גישה של משתמש רשום לאתר ו ו/או למנוע ממשתמש לבצע פעולות באתר, באופן חלקי או מלא, על פי שיקול דעתה הבלעדי (כולל בין היתר, מבלי לגרוע, בשל פעולות שנעשות על-ידי המשתמש הרשום בזדון בקשר עם האתר ו/או הפרת תנאים אלה על-ידי המשתמש הרשום וכדומה) וזאת בנוסף לכל סעד אחר העשוי לעמוד לרשות החברה על פי כל דין.
</p>
<p>
6.10  המשתמש המורשה מתחייב כי פרטי האשראי שמסר הם פרטים נכונים ומדויקים, כי אמצעי התשלום שבו הוא משתמש הוא בבעלותו וכי הינו מורשה לעשות בו שימוש. הפרטים שנמסרו ונקלטו באתר החברה יהוו ראיה חלוטה לאמיתות הפעולה ולנכונות הפרטים. האחריות למלא פרטים מדויקים ונכונים מוטלת על המשתמש הרשום. מסירת פרטים שגויים או כוזבים או לא מדויקים, תיחשב הטעיה והפרה יסודית של ההסכם עם החברה והחברה תהיה זכאית לבטל את העסקה לאלתר וללא הודעה מוקדמת וזאת מבלי לגרוע מכל סעד אחר העומד לחברה בשל ההפרה כאמור.
</p>
<p>
6.11  השימוש בכרטיסי אשראי כפוף לכך שהחברה התקשרה עם אותה חברת אשראי ושקיימת אפשרות לסלוק את העסקאות באמצעותן, וייתכן ששימוש בכרטיסי אשראי של חברות אשראי מסוימות ורכישה באמצעות כרטיסי אשראי מסוימים לא יתאפשרו באתר והמשתמש המורשה מוותר על כל טענה בעניין.</p>
<p>
6.12 כל שימוש באתר כפוף לדיני מדינת ישראל, ובין היתר, לחוק כרטיסי חיוב, תשמ"-ו-1986. כל טענה להונאה או לשימוש לא מורשה בפרטי אשראי או לשימוש לא מורשה או כוזב או לא חוקי בכרטיס אשראי - תופנה לחברת האשראי והמשתמש המורשה מוותר על כל טענה או תביעה נגד החברה בעניין.
</p>
<p>
6.13. החברה אינה מתחייבת לבצע כל עסקה וכל הזמנה באתר, ביצוע והשלמת עסקה נתונים לשיקול דעתה, והיא רשאית לסרב לבצע עסקה או לשלוח הזמנה במקרה בו הלקוח הרשום ו/או המשתמש המורשה הוא בעל חוב שלא סולק או שיש לה יסוד להניח או לחשוש כי הלקוח הרשום ו/או המשתמש המורשה אינו מסוגל או לא יהיה מסוגל לפרוע את התשלום אותו התחייב לשלם או כי לא תקבל לידיה מכל סיבה שהיא את התשלום המגיע ממנו. 
</p>
<p>
6.14  החברה רשאית להגביל באופן חד פעמי או קבוע או להציב מגבלות על כמות הזמנות או על סכום של עסקה או מספר עסקאות שנעשו ע"י המשתמשים המורשים של הלקוח המורשה ו/או באמצעות אותו אמצעי תשלום או על ידי אותו המשתמש המורשה או מי מטעמו. 
</p>
<p>
6.15 החברה רשאית לחסום לקוח מורשה ו/או משתמש רשום או לסרב לבצע עסקה נוספת או למסור הזמנה קיימת במקרה בו הלקוח המורשה ו/או המשתמש המורשה טרם פרע חוב קודם, או שקיימת בעיה עם אמצעי התשלום שנמסרו, או שאמצעי התשלום לא בתוקף, או שהלקוח המורשה ו/או המשתמש הרשום הפרו כלפיה את תנאי ההסכם או תנאי השימוש באתר, או שהלקוח המורשה ו/או המשתמש הרשום מצויים עמה בסכסוך משפטי או שקיימת בין הצדדים מחלוקת לגבי הזמנה או לגבי כל עניין אחר, או שהתעורר חשד או כל חשש או בעיה אחרת עמם, הכל לפי שיקול דעתה הבלעדי של החברה.
</p>
<p>
7. פרטיות וסודיות המידע
</p>
<p>
7.1  לצורכי פעילות האתר קיים מאגר מידע ודיוור ישיר במסגרתו  ייאסף מידע אגבי אודות המשתמשים הרשומים בו, ובכלל זה שימוש בקבצי COOKIES וזאת לצורך שיפור השירות המוצע. האתר מציע למשתמשים הרשומים אפשרות לקבל עדכונים באמצעות דואר אלקטרוני ו/או מסרונים. בהתאם לתיקון 40 לחוק התקשורת (בזק ושידורים), בעת הרישום לאתר המשתמש מאשר את האופציה לקבל שירות זה והחברה תהא רשאית לשלוח למזמין מידי פעם בדואר אלקטרוני ו/או במסרונים מידע בדבר שירותיה וכן תוכן פרסומי – בין אם מדובר במידע שהחברה עצמה תפרסם ובין אם מדובר במידע שהחברה תקבל לצורך משלוח מידי מפרסמים אחרים, וכן להעביר את פרטיו לצדדים שלישיים לצורך קבלת הטבות. אם אין ברצון המשתמש הרשום להמשיך ולקבל מידע מסחרי כאמור, ברשותו לבטל את הסכמתו ולחדול מקבלת המידע המסחרי הנ"ל באמצעות פניה לחברה.
</p>
<p>
7.2 המידע אודות המשתמש הרשום נדרש לשם פניה למשתמש הרשום להשלמת הליך ההזמנה, וכן לצורך שליחת דואר אלקטרוני ו/או מסרונים לטלפונים ויצירת קשר עם המשתמש הרשום בדרכים אחרות, יודגש כי מסירת נתונים על ידי המשתמש המורשה אינה מחויבת על פי חוק אולם ללא מסירתם, לא ניתן יהיה להשתמש באתר, לרבות לערוך הזמנות.
</p>
<p>
7.3 החברה תהייה רשאית להשתמש בפרטים שנמסרו על ידי המשתמש המורשה ובמידע שייאסף אודות דפוסי השימוש באתר ודפוסי הקנייה של המשתמש המורשה, וזאת לצורך שיפור השירותים והקשר עם המשתמש המורשה, לצורך דיוור ישיר, ו/או לצורכי ניתוח ו/או עיבוד מידע וסטטיסטיקה, ולצורך כך תהייה החברה רשאית להעביר את המידע לצדדים שלישיים ובלבד שלא יהיה בהעברת המידע כאמור בכדי לזהות את המשתמש המורשה. 
</p>
<p>
7.4 המשתמש המורשה ו/או הלקוח הרשום מצהיר בזאת כי הוא מאשר את מסירת הנתונים ו/או המידע ו/או איסופו ו/או שמירתו ו/או עיבודו ו/או עשיית שימוש במידע לצרכים מסחריים ו/או שליחת תוכן פרסומי ו/או כל מידע אחר והכל בהתאם למפורט לעיל, וכי הוא מוותר בזאת באופן מוחלט ובלתי חוזר על כל זכות, טענה, תביעה ו/או דרישה כלפי החברה בקשר עם האמור.
</p>
<p>
7.5 החברה לא תעביר לצדדים שלישיים את פרטיהם האישיים של המשתמשים המורשים ומידע המזהה אותם אישית, ואשר נאסף בעת פעילותם באתר, למעט במקרים הבאים: (א) אם יתקבל בידי החברה צו שיפוטי המורה לה למסור את פרטי המשתמש המורשה ו/או הלקוח המורשה או מידע אודותיו לצד שלישי או מסירת פרטים אודותיהם, המסירה תתבצע על פי כל דין (ב) במקרה של מחלוקת, טענה, תביעה, דרישה או הליכים משפטיים, ככל שיהיו, בין הלקוח המורשה ו/או המשתמש המורשה לחברה (ג) העברה לתאגידים הקשורים לחברה, ובלבד שתאגידים אלה ישתמשו במידע בכפוף להוראות תקנון זה (ד) אם החברה תמזג את פעילותה או את פעילות האתר במסגרת תאגיד אחר, ו/או תעביר פעילותה לצד שלישי, במקרה בו תמחה החברה את פעילותה המסחרית ו/או כל זכות שלה מכוח תקנון זה, ובלבד שתאגיד הנעבר כאמור יקבל על עצמו את הוראות השימוש במידע בהתאם לתקנון זה (ה) לצד שלישי המספק לחברה שירותים בקשר עם ההזמנות, לרבות לצורך תמיכה באתר, שירות לקוחות, שילוח ההזמנות.
</p>
<p>
8. . עדכון האתר ושינוי תנאי שימוש
</p>
<p>
8.1 החברה עשייה להרחיב את מגוון המוצרים והשירותים הניתנים באתר מעת לעת, לאור זאת יתכנו שינויים, עדכונים ו/או תוספות באתר, שיערכו על פי שיקול דעתה הבלעדי של החברה וללא כל התראה מוקדמת. מבלי לגרוע מכלליות האמור לעיל, החברה רשאית לשנות את האתר, מבנהו, מראהו, תכניו, היקף השירותים המוצעים בו, היקף ו/או סוג המוצרים המוצעים בו, לרבות מחיריהם, וכן אף לסגור את האתר לאלתר, הכל על פי שיקול דעתה הבלעדי, כראות עיניה, וללא מתן כל התראה מוקדמת. משתמשי האתר מצהירים כי הינם מכירים את האמור ומוותרים בזאת באופן מוחלט ובלתי חוזר על כל זכות, טענה, תביעה ו/או דרישה כלפי החברה בקשר עם האמור בסעיף זה.
</p>
<p>
8.2 החברה רשאית לשנות את תנאי השימוש בכל עת, לפי שיקול דעתה הבלעדי. הלקוח הרשום ו/או המשתמש הרשום מאשרים בזאת כי כל שימוש שייעשה באתר, לרבות ביצוע הזמנה, לאחר עדכון התנאים, יחשב כהסכמת המשתמש ו/או המזמין, לפי העניין, לתנאים החדשים.
</p>
<p>
8.3 החברה רשאית בכל עת, על פי שיקול דעתה הבלעדי, להפסיק את פעילות האתר, באופן זמני או לצמיתות, מבלי לתת כל הודעה מוקדמת וכן להגביל את הגישה לאתר, לצורך עבודות תחזוקה וייעול השירותים הניתנים בו. הלקוח הרשום ו/או המשתמש הרשום מסכים ומאשר כי החברה לא תהיה אחראית לאובדן מידע ו/או נזקים כלשהם הנובעים או הקשורים בהחלטתה להפסיק או להשהות את פעילות האתר כאמור. 
</p>
<p>
9. . שונות
</p>
<p>
9.1  כותרות הסעיפים בתקנון זה הוכנסו לשם הנוחות וההתמצאות בלבד ואין להשתמש בהן לפירוש התקנון.
</p>
<p>
9.2 לא יראו בהסכמה מצד החברה לסטות מתנאי השימוש במקרה מסוים משום ויתור גורף על זכויות החברה ו/או כתקדים מחייב, ולא יסיקו ממנה מסקנות למקרה אחר. אי הפעלה ו/או דרישה בין באופן כללי ובין במועד ספציפי של זכות מזכויות החברה, לא תחשב כוויתור על זכות כאמור ולא תהווה בסיס לטענת השתק כנגד החברה.
</p>
<p>
9.3  במידה וסעיפי תקנון זה, כולם או מקצתם, או יישומם, יהפכו או יוצהרו על ידי בית משפט מוסמך כבלתי חוקיים, בטלים או בלתי ניתנים לאכיפה, יתרת סעיפי תקנון זה או חלקם כאמור יישארו בתוקף מלא ויישום סעיף או חלק הימנו כאמור יפורש באופן כזה שיגשים את מהותם הכלכלית והמסחרית.
</p>
<p>
9.4  הלקוח הרשום ו/או המשתמש הרשום באתר מסכימים בזאת כי כל תביעה או דרישה שתופנה כנגד החברה בקשר עם האתר תוגבל בתקופה של 3 חודשים בדיוק ממועד היווצרות העילה, וכי יש לראות בסעיף זה משום חוזה נפרד בכתב בדבר קיצור תקופת ההתיישנות בהתאם להוראות סעיף 19 לחוק ההתיישנות.
</p>
<p>
9.5  החברה תהיה רשאית, בכל עת, להמחות את זכויותיה וחובותיה על פי תקנון זה לאחר, ללא הסכמת הלקוח המורשה ו/או המשתמש המורשה ו/או כל משתמש אחר באתר ומבלי שתהיה לו עילה, דרישה או תביעה כלפיה.
</p>
<p>
9.6  בכל סכסוך הנובע מהתקנון ותנאי השימוש באתר, תהיה סמכות השיפוט הבלעדית נתונה לבית המשפט המוסמך במחוז תל אביב-יפו בישראל. הדין החל על כל סכסוך בין הצדדים בעניין הנוגע לתקנון ותנאי השימוש באתר יהיה הדין הישראלי ללא סעיפי הפניית דינים.
</p>
<p>
ניתן לעיין ולקרוא את מדיניות הפרטיות של החברה בקישור הבא.
</p>
<p>
עודכן בתאריך 5/5/2021
</p>
</div>

              
    </div>
)
}


function mapStateToProps(state) {
    return {
      
    }
  }
  
  const mapDispatchToProps = {
    setError
  }
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(TermsPage))

