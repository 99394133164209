import React, {useEffect} from "react"
import Tab from "../Tab"
import BillingInformation from "./BillingInformation"
import PersonalInformation from "./PersonalInformation"
import WalletInformation from "./WalletInformation"
import BudgetInformation from "./BudgetInformation"
import { useTranslation } from "react-i18next"
import "./ProfilePage.scss"
import { withRouter } from "react-router"
import LogoFooter from "../main/LogoFooter"
import ProfileSidebarIcon from "../../images/profile_icon_outline_sidebar.svg"
import BillingInfoSidebarIcon from "../../images/billing_info_sidebar.svg"
import walletIcon from "../../images/wallet.png"
import budgetIcon from "../../images/billing_info_sidebar.svg"
import {getUserInfoWithoutId} from "../main/mainCrud";
import axios from "axios";

function ProfilePage(props) {
    const { t, i18n } = useTranslation("main");
  const { innerWidth: width } = window

  const menuItems = [
    {
      itemId: "personal",
      name: `${t("profile.personal_information")}`,
      icon: ProfileSidebarIcon
    },
    {
      itemId: "billing",
      name: `${t("profile.billing_information")}`,
      icon: BillingInfoSidebarIcon
    },
    {
      itemId: "wallet",
        name: `${t("profile.wallet_information")}`,
      icon: walletIcon
    },
    {
      itemId: "budget",
      name: `${t("profile.budget_information")}`,
      icon: budgetIcon
    }
  ]

  const tab = new URLSearchParams(window.location.search).get("tab");
  const [activeTab, setActiveTab] = React.useState(
    menuItems.map(el => el.itemId).includes(tab) ? tab : "personal"
  )
  const [settings, setSettings] = React.useState({
    personal: true,
    billing: true,
    wallet: true,
    budget: true,
  })
  const [userInfo, setUserInfo] = React.useState(null)

  useEffect(() => {
    if (!userInfo) {
      const cancelToken = axios.CancelToken.source()
      getUserInfoWithoutId(cancelToken.token).then(({data}) => {
        setSettings({
          personal: true,
          billing: true,
          wallet: data.UserInfo.AllowWallet,
          budget: true,
        });
        if (process.env.REACT_APP_FLAVOR==='NY'){
          setSettings({
            personal: true,
            billing: false,
            wallet: false,
            budget: false,
          });
        } 
        setUserInfo(data.UserInfo);
      });
    }
  }, [userInfo])

  function handleGoBack() {
    props.history.go(-1)
  }

  function renderConfiguration() {
    return (
      <>
        <div className="list desktop-only">
          <span className="list-title">{t("history.menu")}</span>
          {menuItems.map((el, index) => (
              settings[el.itemId] ?
                <div
                  key={el.itemId}
                  onClick={() => setActiveTab(el.itemId)}
                  className={activeTab === el.itemId ? "list-item active" : "list-item"}
                >
                  <img
                    src={el.icon}
                    alt="icon"
                    className="list-inner-icon"
                  />
                  <span>{el.name}</span>
                </div>
                  :
                <></>
          ))}
        </div>
      </>
    )
  }

  return (
    <div className="profile-page-wrapper">
      <div className="top-row desktop-only" dir={i18n.dir()}>
        <div className="back-link">
          <span onClick={handleGoBack}>{t("back")}</span>
        </div>
        <div className="desktop-title">{t("profile.title")}</div>
      </div>
      <div className="main-wrapper">
        <div className="configurations">
          {renderConfiguration()}
        </div>
        <div className="main-content-wrapper">
          <Tab activeTab={activeTab === "personal"}>
            <PersonalInformation />
          </Tab>
          <Tab activeTab={activeTab === "billing"}>
            <BillingInformation />
          </Tab>
          <Tab activeTab={activeTab === "wallet"}>
            <WalletInformation allowTransfer={userInfo?.AllowBankTransfer} account={!!userInfo?.BankAccountName}/>
          </Tab>
          <Tab activeTab={activeTab === "budget"}>
            <BudgetInformation />
          </Tab>
          
        </div>
      </div>
      {width >= 1024 && <LogoFooter />}
    </div>
  )
}

export default withRouter(ProfilePage)
