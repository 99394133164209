import { Types } from "../actionTypes"

export function setSearchText(data) {
  return { type: Types.SET_SEARCH_TEXT, data }
}
export function setSearchUser(data) {
  return { type: Types.SET_SEARCH_USER, data }
}
export function setSearchDate(data) {
  return { type: Types.SET_SEARCH_DATE, data }
}
export function setSearchBudget(data) {
  return { type: Types.SET_SEARCH_BUDGET, data }
}
export function setSearchLocation(data) {
  return { type: Types.SET_SEARCH_LOCATION, data }
}
export function setSearchMealType(data) {
  return { type: Types.SET_SEARCH_MEAL_TYPE, data }
}
export function setSearchConfiguration(data) {
  return { type: Types.SET_SEARCH_CONFIGURATION, data }
}
