import React, { useEffect } from "react"
import { connect } from "react-redux"
import {
  getUserInfo,
  sendOtp,
  resendOtp,
  validateOtp,
  confirmLogin,
  logout
} from "../../redux/actions/loginActions"
import { setHeaderVisible } from "../../redux/actions/headerActions"
import { useImmer } from "use-immer"
import EnterContactDataPanel from "./EnterContactDataPanel"
import EnterOtpPanel from "./EnterOtpPanel"
import TermsOfServiceWindow from "../TermsOfServiceWindow"
import "./LoginPage.scss"
import { Trans } from "react-i18next"
import { useTranslation } from "react-i18next"
import { withRouter } from "react-router"

function LoginPage({
  sendOtp,
  resendOtp,
  validateOtp,
  getUserInfo,
  confirmLogin,
  logout,
  setHeaderVisible,
  ...props
}) {
    const { t, i18n } = useTranslation("main");
    var desktop = [
    {
      jpgDesktop:
        "https://media-prod.dish.co.il/fsxrl6CxdpkRbn8LDaQFUCAHvblXF3YvohULXCSxXW0/rs:fit:1250::0/g:no/czM6Ly9jb250ZW50LmRpc2guY28uaWwvaW1hZ2VzL2xvZ2luL2Rlc2t0b3AxLmpwZw.jpg",
      jpgMobile:
        "https://media-prod.dish.co.il/wnBaG7hfGHT9XVYvt8HjwjhxTBzKmevQE0bHQHWORJc/rs:fit:700::0/g:no/czM6Ly9jb250ZW50LmRpc2guY28uaWwvaW1hZ2VzL2xvZ2luL2Rlc2t0b3AxLmpwZw.jpg",
      webpDesktop:
        "https://media-prod.dish.co.il/bwIMDV-moYz8oqIz89geJzsdNr6kdUpyFAb9lQdlLHQ/rs:fit:1250::0/g:no/czM6Ly9jb250ZW50LmRpc2guY28uaWwvaW1hZ2VzL2xvZ2luL2Rlc2t0b3AxLmpwZw.webp",
      webpMobile:
        "https://media-prod.dish.co.il/L4NNVJJWyL_13PtEQUvDwnKCJCKlcNVccWMuoU5quDs/rs:fit:700::0/g:no/czM6Ly9jb250ZW50LmRpc2guY28uaWwvaW1hZ2VzL2xvZ2luL2Rlc2t0b3AxLmpwZw.webp"
    },
    {
      jpgDesktop:
        "https://media-prod.dish.co.il/EbHZeGJM18UDngLaCTGwLcGanxi7nJfA0oISJ_Vip08/rs:fit:1250::0/g:no/czM6Ly9jb250ZW50LmRpc2guY28uaWwvaW1hZ2VzL2xvZ2luL2Rlc2t0b3AyLmpwZw.jpg",
      jpgMobile:
        "https://media-prod.dish.co.il/SUC8OIf-GQvPoWyN4jIpWBKyttWRI5RkhoUQB3LB49s/rs:fit:700::0/g:no/czM6Ly9jb250ZW50LmRpc2guY28uaWwvaW1hZ2VzL2xvZ2luL2Rlc2t0b3AyLmpwZw.jpg",
      webpDesktop:
        "https://media-prod.dish.co.il/keWey2iruv1e9EZuyyNgtx3i_IXkb8ap68WcHKr95NM/rs:fit:1250::0/g:no/czM6Ly9jb250ZW50LmRpc2guY28uaWwvaW1hZ2VzL2xvZ2luL2Rlc2t0b3AyLmpwZw.webp",
      webpMobile:
        "https://media-prod.dish.co.il/ssS0euvjC2FJc3fin4UO-JHTZKWZDUhntUtgEXm2rRg/rs:fit:700::0/g:no/czM6Ly9jb250ZW50LmRpc2guY28uaWwvaW1hZ2VzL2xvZ2luL2Rlc2t0b3AyLmpwZw.webp"
    },
    {
      jpgDesktop:
        "https://media-prod.dish.co.il/FHmOghWWJAGWg6x67Y0oByKKOeQai4OFZdaigYGNfpM/rs:fit:1250::0/g:no/czM6Ly9jb250ZW50LmRpc2guY28uaWwvaW1hZ2VzL2xvZ2luL2Rlc2t0b3AzLmpwZw.jpg",
      jpgMobile:
        "https://media-prod.dish.co.il/TVqWjY9b1P3USNPBH98VvB0Ea7qiF3gmAhqNl6ttJcw/rs:fit:700::0/g:no/czM6Ly9jb250ZW50LmRpc2guY28uaWwvaW1hZ2VzL2xvZ2luL2Rlc2t0b3AzLmpwZw.jpg",
      webpDesktop:
        "https://media-prod.dish.co.il/pdrBUF2GMRQh_w4OI7PUjE5hnl72I2qYcWtLgtMJD-Y/rs:fit:1250::0/g:no/czM6Ly9jb250ZW50LmRpc2guY28uaWwvaW1hZ2VzL2xvZ2luL2Rlc2t0b3AzLmpwZw.webp",
      webpMobile:
        "https://media-prod.dish.co.il/w3urxcbFGjLQVFb26vGbgDKOvCmNBtqYDmwnSlIGLTc/rs:fit:700::0/g:no/czM6Ly9jb250ZW50LmRpc2guY28uaWwvaW1hZ2VzL2xvZ2luL2Rlc2t0b3AzLmpwZw.webp"
    }
  ]
  if (process.env.REACT_APP_FLAVOR=='NY'){
   desktop = [
    {
      jpgDesktop:
        "https://media-ny.dish.co.il/YFLVxxH-ckA-WOESJHJ4SI9TFt7JjmyUqpFmUL-tEuo/rs:fit:1250::1/g:no/czM6Ly9jb250ZW50LW55LmRpc2guY28uaWwvaW1hZ2VzL2FwcC9sb2dpbjEuanBn.jpg",
      jpgMobile:
        "https://media-ny.dish.co.il/xcTdEu0rvHrOegPcNW3agGODiPvQH27UI5txv_uj2-Q/rs:fit:700::1/g:no/czM6Ly9jb250ZW50LW55LmRpc2guY28uaWwvaW1hZ2VzL2FwcC9sb2dpbjEuanBn.jpg",
      webpDesktop:
        "https://media-ny.dish.co.il/ZLj6MbFQWrlh4uV06uLBM0KQapO5alsxGWvKlk8G6N4/rs:fit:1250::1/g:no/czM6Ly9jb250ZW50LW55LmRpc2guY28uaWwvaW1hZ2VzL2FwcC9sb2dpbjEuanBn.webp",
      webpMobile:
        "https://media-ny.dish.co.il/GUbRq9p-ZsIGUNAGH8mk4Jo5fk6AzxXSGl3Vc716y0M/rs:fit:700::1/g:no/czM6Ly9jb250ZW50LW55LmRpc2guY28uaWwvaW1hZ2VzL2FwcC9sb2dpbjEuanBn.webp"
    },
    {
      jpgDesktop:
        "https://media-ny.dish.co.il/YFLVxxH-ckA-WOESJHJ4SI9TFt7JjmyUqpFmUL-tEuo/rs:fit:1250::1/g:no/czM6Ly9jb250ZW50LW55LmRpc2guY28uaWwvaW1hZ2VzL2FwcC9sb2dpbjEuanBn.jpg",
      jpgMobile:
        "https://media-ny.dish.co.il/xcTdEu0rvHrOegPcNW3agGODiPvQH27UI5txv_uj2-Q/rs:fit:700::1/g:no/czM6Ly9jb250ZW50LW55LmRpc2guY28uaWwvaW1hZ2VzL2FwcC9sb2dpbjEuanBn.jpg",
      webpDesktop:
        "https://media-ny.dish.co.il/ZLj6MbFQWrlh4uV06uLBM0KQapO5alsxGWvKlk8G6N4/rs:fit:1250::1/g:no/czM6Ly9jb250ZW50LW55LmRpc2guY28uaWwvaW1hZ2VzL2FwcC9sb2dpbjEuanBn.webp",
      webpMobile:
        "https://media-ny.dish.co.il/GUbRq9p-ZsIGUNAGH8mk4Jo5fk6AzxXSGl3Vc716y0M/rs:fit:700::1/g:no/czM6Ly9jb250ZW50LW55LmRpc2guY28uaWwvaW1hZ2VzL2FwcC9sb2dpbjEuanBn.webp"
    },
    {
      jpgDesktop:
        "https://media-ny.dish.co.il/YFLVxxH-ckA-WOESJHJ4SI9TFt7JjmyUqpFmUL-tEuo/rs:fit:1250::1/g:no/czM6Ly9jb250ZW50LW55LmRpc2guY28uaWwvaW1hZ2VzL2FwcC9sb2dpbjEuanBn.jpg",
      jpgMobile:
        "https://media-ny.dish.co.il/xcTdEu0rvHrOegPcNW3agGODiPvQH27UI5txv_uj2-Q/rs:fit:700::1/g:no/czM6Ly9jb250ZW50LW55LmRpc2guY28uaWwvaW1hZ2VzL2FwcC9sb2dpbjEuanBn.jpg",
      webpDesktop:
        "https://media-ny.dish.co.il/ZLj6MbFQWrlh4uV06uLBM0KQapO5alsxGWvKlk8G6N4/rs:fit:1250::1/g:no/czM6Ly9jb250ZW50LW55LmRpc2guY28uaWwvaW1hZ2VzL2FwcC9sb2dpbjEuanBn.webp",
      webpMobile:
        "https://media-ny.dish.co.il/GUbRq9p-ZsIGUNAGH8mk4Jo5fk6AzxXSGl3Vc716y0M/rs:fit:700::1/g:no/czM6Ly9jb250ZW50LW55LmRpc2guY28uaWwvaW1hZ2VzL2FwcC9sb2dpbjEuanBn.webp"
    }
  ]
}

  const currentMobileOS = getMobileOperatingSystem()

  function getMobileOperatingSystem() {
    let userAgent =
      window.navigator.userAgent || window.navigator.vendor || window.opera

    if (/android/i.test(userAgent)) {
      return "Android"
    }

    if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
      return "iOS"
    }

    return ""
  }

  const [state, setState] = useImmer({
    isContactDataSubmitted: false,
    isOtpSending: false,
    areTermsAccepted: false,
    showTerms: false,
    showError: false,
    error: { show: false, message: "" },
    disableSubmitForm: false,
    email: { value: "", isInvalid: false },
    phone: { value: "", isInvalid: false },
    chosen: "",
    otp: { value: "", isInvalid: false },
    wallpaperIndex: Math.floor(Math.random() * 3),
    isOtpResend: false,
    timer: 0,
    attemptCount: 0,
    isLocked: false
  })


  function handleFormChange(event) {
    const { name, value } = event.target
    setState(draft => {
      if (
        name !== "otp" &&
        (name !== "phone" || value.replace(/\D/g, "").length !== 0)
      ) {
        draft.chosen = name
      }
      draft[name].value = value
      switch (name) {
        case 'email': {
          draft['phone'].value = '';
          draft['phone'].disabled = !!draft['email'].value;
          break;
        }
        case 'phone': {
          draft['email'].value = '';
          draft['email'].disabled = !!draft['phone'].value;
          break;
        }
        default: {
          console.log('Not supported type')
        }
      }
    })
  }

  function handleSubmitContactData(event) {
    event.preventDefault()

    const checkForInput = state.chosen.length !== 0

    const isEmailInvalid =
      state.chosen === "email" &&
      (state.email.value.length === 0 ||
        !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(state.email.value))

    const isPhoneInvalid =
      state.chosen === "phone" &&
      (state.phone.value.length === 0 ||
        !/^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s\./0-9]*$/.test(state.phone.value))

    if (checkForInput && !isEmailInvalid && !isPhoneInvalid) {
      setState(draft => {
        draft.isOtpSending = true
        draft.disableSubmitForm =  true
      })
      state.chosen === "email"
        ? sendOtp({
            Email: state.email.value
          })
        : sendOtp({
            Mobile: state.phone.value.replace(/\D/g, "")
          })
    } else {
      setState(draft => {
        if (checkForInput) {
          draft.email.isInvalid = isEmailInvalid
          draft.phone.isInvalid = isPhoneInvalid
        } else {
          draft.email.isInvalid = true
          draft.phone.isInvalid = true
        }
      })
    }
  }

  function handleResendOtp(event) {
      event.preventDefault()
      if (state.isLocked) return;

      resendOtp({ OTPID: props.otp.OTPID })
          .then(() => {
              setState((draft) => {
                  draft.isOtpResend = true;
                  draft.attemptCount += 1;
                  draft.timer = 60;
              });

              setTimeout(() => {
                  setState((draft) => {
                      draft.isOtpResend = false;
                  });
              }, 60000);
          })
          .catch(() => {
              setState((draft) => {
                  draft.isLocked = true;
                  draft.error.show = true
                  draft.error.message = t("login.error_window.user_locked")
              });
          })
  };

  function handleContactDataChange(event) {
    setState(draft => {
      draft.isContactDataSubmitted = false
      draft.error.show = false
    })
  }

  function handleSubmitOtp(event) {
    event.preventDefault()
    if (/^[0-9] [0-9] [0-9] [0-9] [0-9] [0-9]$/.test(state.otp.value)) {
      validateOtp({
        OTPID: props.otp.OTPID,
        OTP: state.otp.value.replace(/\D/g, "")
      })
    } else {
      setState(draft => {
        draft.otp.isInvalid = true
      })
    }
  }

  function handleAcceptTerms(event) {
    setState(draft => {
      draft.areTermsAccepted = !draft.areTermsAccepted
    })
  }

  function handleCloseError(event) {
    setState(draft => {
      draft.error.show = false
    })
  }

  function handleCloseTerms(event) {
    if (state.areTermsAccepted) {
      finishLogin()
    }
  }

  function finishLogin() {
    setHeaderVisible()
    confirmLogin()
    props.history.push("/")
    }


  useEffect(() => {
      let timerInterval;
      if (state.timer > 0) {
          timerInterval = setInterval(() => {
              setState((draft) => {
                  draft.timer -= 1;
              });
          }, 1000);
      }

      return () => {
          clearInterval(timerInterval);
      };
  }, [state.timer, setState]);

    useEffect(() => {
        if (props.otp) {
            if (props.otp.error) {
                setState((draft) => {
                    draft.error.show = true;
                    draft.disableSubmitForm = false;

                    if (props.otp.error.code === 409) {
                        draft.error.message = (t("login.error_window.user_locked"));
                    } else {
                        draft.error.message = (
                            <Trans t={t} i18nKey="login.error_window.invalid_phone">
                                Unidentified phone number in system, if you have a user and are
                                unable to log in please contact <a href="">for support</a>
                            </Trans>
                        );
                    }
                });
            } else {
                setState((draft) => {
                    draft.isOtpSending = false;
                    draft.isContactDataSubmitted = true;
                    draft.error.show = false;
                });
            }
        }
    }, [props.otp]);

  useEffect(() => {
    if (props.token) {
      if (props.token.error) {
        setState(draft => {
          draft.error.show = true
          draft.error.message = t("login.error_window.invalid_login")
        })
      } else if (props.token.isValidated) {
        getUserInfo(props.token.Token)
      }
    }
  }, [props.token])

  useEffect(() => {
    if (props.userInfo) {
      if (
        !props.userInfo.Customer &&
        !["Admin", "Delivery"].includes(props.userInfo.Role)
      ) {
        setState(draft => {
          draft.error.show = true
          draft.error.message = t("login.error_window.invalid_user")
          draft.otp.value = ""
          draft.phone = { value: "", isInvalid: false }
          draft.email = { value: "", isInvalid: false }
          draft.chosen = ""
          draft.isContactDataSubmitted = false
        })
        logout()
      } else {
        if (props.userInfo.ApproveTerms !== false) {
          setState(draft => {
            draft.showTerms = true
          })
        } else {
          finishLogin()
        }
      }
    }
  }, [props.userInfo])

  return (
    <div className="page-wrapper">
      <TermsOfServiceWindow
        show={state.showTerms}
        isAccepted={state.areTermsAccepted}
        onChange={handleAcceptTerms}
        onClose={handleCloseTerms}
      />
      <picture className="decorative-image">
        <source
          srcSet={desktop[state.wallpaperIndex].webpDesktop}
          media="(min-width:1024px)"
          type="image/webp"
        />
        <source
          srcSet={desktop[state.wallpaperIndex].jpgDesktop}
          media="(min-width:1024px)"
          type="image/jpeg"
        />
        <source
          srcSet={desktop[state.wallpaperIndex].webpMobile}
          type="image/webp"
        />
        <source
          srcSet={desktop[state.wallpaperIndex].jpgMobile}
          type="image/jpeg"
        />
        <img src={desktop[state.wallpaperIndex].jpgDesktop} alt="Background" />
      </picture>
      <div className="login-panel-wrapper">
        {state.isContactDataSubmitted ? (
          <EnterOtpPanel
            formState={state}
            handleSubmit={handleSubmitOtp}
            onDataChange={handleContactDataChange}
            onOtpResend={handleResendOtp}
            onChange={handleFormChange}
            onCloseError={handleCloseError}
          />
        ) : (
          <EnterContactDataPanel
            formState={state}
            onSubmit={handleSubmitContactData}
            onChange={handleFormChange}
            onCloseError={handleCloseError}
            currentMobileOS={currentMobileOS}
          />
        )}
      </div>
    </div>
  )
}

function mapStateToProps(state) {
  return {
    otp: state.login.otp,
    token: state.login.token,
    userInfo: state.login.userInfo
  }
}

const mapDispatchToProps = {
  sendOtp,
  resendOtp,
  validateOtp,
  getUserInfo,
  confirmLogin,
  logout,
  setHeaderVisible
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(LoginPage)
)
