import React from "react"
import "./Footer.scss"
import historyIcon from "../../images/history_icon.svg"
import mainIcon from "../../images/main_icon.svg"
import storeIcon from "../../images/store_icon.svg"
import profileIcon from "../../images/profile_icon.svg"
import { useTranslation } from "react-i18next"
import { NavLink } from "react-router-dom"
import { connect } from "react-redux"

function Footer({ userInfo, reference }) {
    const { t, i18n } = useTranslation("main");

  const isAuthorized = userInfo ? userInfo.confirmedLogin : false
  const isRoleDelivery = isAuthorized && userInfo.Role === "Delivery"
  const isRoleAdmin = isAuthorized && userInfo.Role === "Admin"
  const isRoleDepartmentUser =
    isAuthorized && userInfo.Role === "Department User"

  const menuItems = [
    {
      name: `${t("footer.profile")}`,
      icon: profileIcon,
      url: "profile",
      isHidden: isRoleDepartmentUser || isRoleAdmin || isRoleDelivery
    },
    {
      name: `${t("footer.store")}`,
      icon: storeIcon,
      url: "store",
      isHidden: isRoleDelivery
    },
    {
      name: `${t("footer.main")}`,
      icon: mainIcon,
      url: "main",
      isHidden: isRoleDelivery
    },
    {
      name: `${t("footer.history")}`,
      icon: historyIcon,
      url: "history",
      isHidden: isRoleDelivery || isRoleDepartmentUser
    }
  ]

  return (
    <div className="footer" ref={reference}>
      {menuItems
        .filter(el => !el.isHidden)
        .map((el, index) => (
          <NavLink
            to={`/${el.url}`}
            className="footer-nav"
            activeClassName="active"
            key={el.name}
          >
            <div className="footer-nav-img">
              <img src={el.icon} />
            </div>
            <span>{el.name}</span>
          </NavLink>
        ))}
    </div>
  )
}

function mapStateToProps(state) {
  return {
    userInfo: state.login.userInfo
  }
}

export default connect(mapStateToProps, null)(Footer)
