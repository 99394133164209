import React from "react"

function Tab({ ...props }) {
  if (props.activeTab) {
    return <>{props.children}</>
  }
  return null
}

export default Tab
