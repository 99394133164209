import { Types } from "../actionTypes"
import axios from "axios"
import { setError } from "./errorHandlerActions"

export function getCustomersListSuccess(data) {
  return { type: Types.GET_CUSTOMER_LIST_SUCCESS, data }
}

export function selectCustomer(data) {
  return { type: Types.SELECT_CUSTOMER, data }
}

export function getUsersListSuccess(data) {
  return { type: Types.GET_USERS_LIST_SUCCESS, data }
}

export function getCustomersList(payload) {
  return function (dispatch) {
    return getCustomersListRequest(payload)
      .then(({ data }) => {
        dispatch(getCustomersListSuccess(data))
      })
      .catch(error => {
        console.error(error)
        dispatch(setError(error))
      })
  }
}

export function getCustomersListRequest(data) {
  return axios.get(`/Customers/List`)
}

export function getUsers(payload) {
  return function (dispatch) {
    return getUsersRequest(payload)
      .then(({ data }) => {
        dispatch(
          getUsersListSuccess(
            data.items.map(el => ({
              Name: `${el.FirstName} ${el.LastName}`,
              UserID: el.UserID
            }))
          )
        )
      })
      .catch(error => {
        console.error(error)
        dispatch(setError(error))
      })
  }
}

export function getUsersRequest(customerID) {
  return axios.get("/users", {
    params: { active: true, customerid: customerID }
  })
}
