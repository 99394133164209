import React, {useEffect} from "react"
import { useTranslation } from "react-i18next"
import "./BankAccountPage.scss"
import { withRouter } from "react-router"
import BankPersonalInformation from "./BankPersonalInformation";
import LogoFooter from "../main/LogoFooter";
import axios from "axios";
import {getUserBankInfoWithoutId} from "../main/mainCrud";

function BankAccountPage(props) {
    const { t, i18n } = useTranslation("main");
    const [userBankInfo, setUserBankInfo] = React.useState(null)

  const { innerWidth: width } = window
    useEffect(() => {
        if (!userBankInfo) {
            const cancelToken = axios.CancelToken.source()
            getUserBankInfoWithoutId(cancelToken.token).then(({data}) => {
                setUserBankInfo(data);
            });
        }
    }, [userBankInfo])
    function handleGoBack() {
        props.history.go(-1)
    }

  return (
      <div className="profile-page-wrapper bank-container">
          <div className="top-row desktop-only" dir={i18n.dir()}>
              <div className="back-link">
                  <span onClick={handleGoBack}>{t("back")}</span>
              </div>
              <div className="desktop-title">{t("profile.bank.title")}</div>
          </div>
          <div className="main-wrapper">
              <div className="configurations"></div>
              <div className="main-content-wrapper">
                  {
                      userBankInfo && (
                          <BankPersonalInformation bankInfo={userBankInfo} userBankInfo={userBankInfo}/>
                      )
                  }
              </div>
          </div>
          {width >= 1024 && <LogoFooter />}
      </div>
  )
}

export default withRouter(BankAccountPage)
