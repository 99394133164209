export default function setupAxios(axios, store) {
  axios.defaults.baseURL = process.env.REACT_APP_BASE_PATH
  const defaultLanguage =  localStorage.getItem('lang') || (process.env.REACT_APP_APPLICATION_LANGUAGE || 'he');

  axios.interceptors.request.use(
    config => {
      const authToken = store.getState().login.token ? store.getState().login.token.Token : null

      if (authToken) {
        config.headers.Authorization = `Bearer ${authToken}`
      }

      config.headers['Accept-Language'] = defaultLanguage;

      return config
    },
    err => Promise.reject(err)
  )
}