import { Types } from "../actionTypes"

export default function adminReducer(state = { users: [] }, action) {
  switch (action.type) {
    case Types.GET_CUSTOMER_LIST_SUCCESS: {
      return {
        ...state,
        customers: [...action.data],
        users: []
      }
    }
    case Types.SELECT_CUSTOMER: {
      return {
        ...state,
        selectedCustomer: action.data
      }
    }
    case Types.GET_USERS_LIST_SUCCESS: {
      return {
        ...state,
        users: [...action.data]
      }
    }
    default:
      return state
  }
}
