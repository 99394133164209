import option_icon_0 from "../images/option-icons/0.svg"
import option_icon_1 from "../images/option-icons/1.svg"
import option_icon_2 from "../images/option-icons/2.svg"
import option_icon_3 from "../images/option-icons/3.svg"
import option_icon_4 from "../images/option-icons/4.svg"
import option_icon_5 from "../images/option-icons/5.svg"
import option_icon_6 from "../images/option-icons/6.svg"
import option_icon_7 from "../images/option-icons/7.svg"
import option_icon_8 from "../images/option-icons/8.svg"
import option_icon_9 from "../images/option-icons/9.svg"
import option_icon_10 from "../images/option-icons/10.svg"

export const optionIcons = [
    option_icon_0,
    option_icon_1,
    option_icon_2,
    option_icon_3,
    option_icon_4,
    option_icon_5,
    option_icon_6,
    option_icon_7,
    option_icon_8,
    option_icon_9,
    option_icon_10
];


export default optionIcons;