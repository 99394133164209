import axios from "axios"

export function getItemHistory(userID, from, to, cancelToken) {
  return axios.post(`/ItemHistory/${userID}`, {
    DateFrom: from,
    DateTo: to
  }, {
    cancelToken: cancelToken
  })
}

// export function getOrderHistory(userID, from, to, cancelToken) {
//   return axios.post(`/OrderHistory/${userID}`, {
//     DateFrom: from,
//     DateTo: to
//   }, {
//     cancelToken: cancelToken
//   })
// }

export function sendRating(Rating, Comment, id) {
  return axios.post(`/User/RateOrderItem/${id}`, {
    Rating,
    Comment
  })
}
