import { Types } from "../actionTypes"

const initialState = 
  localStorage.getItem("Cards")
  ? [ ...JSON.parse(localStorage.getItem("Cards")) ]
  : []

export default function cardReducer(state = initialState, action) {
  const cards = [...state]
  let newCards = []
  switch (action.type) {
    case Types.ADD_CARD:
      newCards = [...cards, action.data]
      localStorage.setItem("Cards", JSON.stringify(newCards))
      return newCards
    case Types.REMOVE_CARD:
      newCards = cards.filter(card => card.creditCardToken !== action.data)
      localStorage.setItem("Cards", JSON.stringify(newCards))
      return newCards
    default:
      return state
  }
}
