import React from "react"
import ModalWindow from "./ModalWindow"
import closeGray from "../images/header/close-gray-icon.svg"

function PushWindow({
  show,
  imageUrl,
  primaryText,
  secondaryText,
  onClose
}) {
  return (
    <ModalWindow show={show}>
      <img
        className="confirmation-window-close-icon"
        src={closeGray}
        alt="Close icon"
        onClick={onClose}
      />
      <div className="confirmation-window-image">
        <img src={imageUrl}/>
      </div>
      <div className="confirmation-window-primary-text">{primaryText}</div>
      <div className="confirmation-window-secondary-text">{secondaryText}</div>
    </ModalWindow>
  )
}

export default PushWindow
