import axios from "axios"

export function editUserInfo(newUserData) {
  return axios.patch(`/users/userinfo`, newUserData)
}

export function getWalletInfo(userID) {
    return axios.get(`/Wallet/Transactions/${userID}`)
}

export function setBankTransfer(amount) {
    return axios.post(`/users/bankinfo/wallet/transfer/`, {
        amount,
        device:
            navigator && navigator.userAgent ? navigator.userAgent : ''
    })
}

export function getBudgetInfo(userID) {
    return axios.get(`/Budget/UsersBudgets/${userID}`)
}

export function getBudgetTransactionInfo(budgetID) {
    return axios.get(`/Budget/Transaction/${budgetID}`)
}
