import React, {useEffect, useState} from "react"
import InputMask from "react-input-mask"
import "./ProfilePage.scss"

function Field({
                   type,
                   label,
                   icon,
                   name,
                   value,
                   error,
                   inputMask,
                   direction,
                   onChange,
                   disabled,
                   values,
                   className
               }) {

    const [valuesExpanded, setValuesExpanded] = useState(false)
    const [labelValue, setLabelValue] = useState('')
    const [errorFields, setErrorFields] = useState([])

    function handleExpand() {
        setValuesExpanded(!valuesExpanded)
    }

    function handleChangeValue(value) {
        onChange(value)
        setErrorFields(errorFields.filter(error => error !== "value"))
    }

    useEffect(() => {
        if (valuesExpanded) {
            document.addEventListener("click", closeDropdown)
        }
    }, [valuesExpanded])

    function closeDropdown(event) {
        event.preventDefault()
        setValuesExpanded(false)
        document.removeEventListener("click", closeDropdown)
    }

    function getLabel() {
        const item = (values || []).find(it => +it.value === +value);
        return (item?.text || '') + (value ? ' (' + value + ') ' : '');
    }

    useEffect(() => {
        setLabelValue(getLabel());
    }, [value])

    return (
        <div className={`form ${error ? "error-field" : ""}  ${className}`}>
            <label>{label}</label>
            {
                type === 'select' && (
                    <div className={`select drop-down-wrapper`} onClick={handleExpand}>
                        <div
                            className={
                                valuesExpanded ? "drop-down expanded" : "drop-down"
                            }
                        >
                            <input
                                dir={direction}
                                style={{background: "url(" + icon + ")"}}
                                value={labelValue}
                                name={name}
                                onChange={onChange}
                                readOnly={true}
                                disabled={disabled}
                            />
                            <div className="drop-down-contents">
                                {(values || []).map(item => (
                                    <div
                                        key={item.value}
                                        className={
                                            value.value === item.value
                                                ? "drop-down-option active"
                                                : "drop-down-option"
                                        }
                                        onClick={() => handleChangeValue(item)}
                                    >
                                        {item.text || ''} {item.value ? '(' + item.value + ')' : ''}
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                )
            }
            {
                type !== 'select' && (
                    !inputMask ? (
                        <input
                            dir={direction}
                            style={{background: "url(" + icon + ")"}}
                            value={value}
                            name={name}
                            onChange={onChange}
                            disabled={disabled}
                        />
                    ) : (
                        <InputMask
                            dir="ltr"
                            name={name}
                            mask="999-9999999"
                            style={{background: "url(" + icon + ")"}}
                            value={value}
                            onChange={onChange}
                            disabled={disabled}
                        ></InputMask>
                    )
                )
            }
        </div>
    )
}

export default Field
