import { Types } from "../actionTypes"

const initialState = {}

export default function cartReducer(state = initialState, action) {
  switch (action.type) {
    case Types.SEND_ITEM_TO_TEMP_ORDER:
      return {
        ...state,
        [action.data.userID]: {
          ...state[action.data.userID],
          orderID: action.data.orderID
        }
      }
    case Types.CLEAR_CART:
      return {
        ...state,
        [action.data]: {
          count: 0,
          employeeSum: 0
        }
      }
    case Types.INCREASE_COUNT:
      return {
        ...state,
        [action.data.userId]: {
          ...state[action.data.userId],
          count: state[action.data.userId] && state[action.data.userId].count
            ? state[action.data.userId].count + action.data.quantity
            : 1,
          employeeSum: action.data.employeeSum
        }
      }
    case Types.DECREASE_COUNT:
      return {
        ...state,
        [action.data]: {
          ...state[action.data],
          count: state[action.data].count - 1,
          employeeSum: action.data.employeeSum
        }
      }
    default:
      return state
  }
}
