import React from "react"
import { useTranslation } from "react-i18next"
import { connect } from "react-redux"
import "../main/MainPage.scss"
import { selectCustomer } from "../../redux/actions/adminActions"

function CustomersDropdown({
  customersExpanded,
  onExpand,
  searchRef,
  customerSearchText,
  handleSearchCustomerChange,
  customers,
  selectedCustomer,
  selectCustomer
}) {
    const { t, i18n } = useTranslation("main");

  return (
    <div className={customersExpanded ? "drop-down expanded" : "drop-down"}>
      <a onClick={onExpand}>
        {customersExpanded
          ? t("main.select_order_user")
          : selectedCustomer.Name}
      </a>
      <div ref={searchRef} className="search drop-down-search">
        <input
          className="search-field"
          value={customerSearchText}
          onChange={handleSearchCustomerChange}
        />
      </div>
      <ul>
        {customers
          .filter(el =>
            el.Name.toLocaleLowerCase().includes(
              customerSearchText.toLocaleLowerCase()
            )
          )
          .map((el, index) => (
            <li
              className={
                selectedCustomer.CustomerID === el.CustomerID ? "active" : ""
              }
              onClick={() => selectCustomer(el)}
              key={el.CustomerID}
            >
              <a>{el.Name}</a>
            </li>
          ))}
      </ul>
    </div>
  )
}

function mapStateToProps(state) {
  return {
    selectedCustomer: state.admin.selectedCustomer,
    customers: state.admin.customers
  }
}

const mapDispatchToProps = {
  selectCustomer
}

export default connect(mapStateToProps, mapDispatchToProps)(CustomersDropdown)
