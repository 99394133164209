import React from "react"
import { Redirect } from "react-router-dom"

const PrivateRoute = ({
  component: Component,
  isAuthenticated,
  isForbidden,
  isLoading,
  ...rest
}) => {
  //TODO: add a loading page for login
  // if(isLoading) {
  //     return <div>Loading...</div>
  // }

  if (!isAuthenticated) {
    return <Redirect to="/login" />
  }
  if (isForbidden) {
    return <Redirect to="/" />
  }
  return <Component {...rest} />;
}

export { PrivateRoute }
