import image4 from "../../images/bank/4.jpeg";
import image10 from "../../images/bank/10.png";
import image11 from "../../images/bank/11.png";
import image12 from "../../images/bank/12.png";
import image13 from "../../images/bank/13.jpeg";
import image17 from "../../images/bank/17.jpeg";
import image18 from "../../images/bank/18.jpeg";
import image20 from "../../images/bank/20.png";
import image31 from "../../images/bank/31.webp";
import image46 from "../../images/bank/46.png";
import image54 from "../../images/bank/54.png";

export function getBankImage(number) {
    switch (+number) {
        case 4: {
            return image4
        }
        case 10: {
            return image10
        }
        case 11: {
            return image11
        }
        case 12: {
            return image12
        }
        case 13: {
            return image13
        }
        case 17: {
            return image17
        }
        case 18: {
            return image18
        }
        case 20: {
            return image20
        }
        case 31: {
            return image31
        }
        case 46: {
            return image46
        }
        case 54: {
            return image54
        }
        default: {
            return;
        }
    }

}
