import { Types } from "../actionTypes"

const initialState = {
  searchText: "",
  searchDate: "",
  searchUser: {},
  searchLocation: null,
  searchMealType: 1
}

export default function searchReducer(state = initialState, action) {
  switch (action.type) {
    case Types.SET_SEARCH_TEXT:
      return {
        ...state,
        searchText: action.data
      }
    case Types.SET_SEARCH_DATE:
      return {
        ...state,
        searchDate: action.data
      }
    case Types.SET_SEARCH_USER:
      return {
        ...state,
        searchUser: action.data
      }
    case Types.SET_SEARCH_LOCATION:
      return {
        ...state,
        searchLocation: action.data
      }
    case Types.SET_SEARCH_MEAL_TYPE:
      return {
        ...state,
        searchMealType: action.data
      }
    case Types.SET_SEARCH_CONFIGURATION:
      return {
        ...state,
        searchUser: action.data.user,
        searchLocation: action.data.location,
        searchDate: action.data.date,
        searchMealType: action.data.mealType
      }
    default:
      return state
  }
}
