import React from "react"
import { useTranslation } from "react-i18next"
import "../main/MainPage.scss"
import moment from "moment/moment";
import deliveryIcon from "../../images/delivery-time.svg"

function MealDeliveryTimeDropdown( {
  mealDeliveryTimesExpanded,
  onExpand,
  mealDeliveryTimes,
  selectedMealDeliveryTime,
  selectMealDeliveryTime
}) {
    const { t, i18n } = useTranslation("main");
  return (
    <div className={mealDeliveryTimesExpanded ? "drop-down expanded" : "drop-down"}>
      <div className='drop-down-container'>
          <img src={deliveryIcon}/>
          <a onClick={onExpand}>
              <span className='drop-down-container-title'>
                  <span className='subtitle'>
                      {t("main.select_order_meal_delivery_time")}
                  </span>
                  <span className='title'>
                       {mealDeliveryTimesExpanded
                           ? t("main.select_order_meal_delivery_time")
                           : selectedMealDeliveryTime
                               ? `${moment(selectedMealDeliveryTime.From, "hh:mm:ss").format("HH:mm")}-${moment( selectedMealDeliveryTime.To , "hh:mm:ss").format("HH:mm")}`
                               : t("main.no_meal_delivery_time")}
                  </span>
              </span>
          </a>
      </div>
      <ul>
        {mealDeliveryTimes.map((el, index) => (
          <li
            className={
              selectedMealDeliveryTime && selectedMealDeliveryTime.Id === el.Id
                ? "active"
                : ""
            }
            onClick={() => selectMealDeliveryTime(el)}
            key={el.Id}
          >
            <a>{moment( el.From ,  "hh:mm:ss").format("HH:mm")}-{moment(el.To  , "hh:mm:ss").format("HH:mm")}</a>
          </li>
        ))}
      </ul>
    </div>
  )
}

export default MealDeliveryTimeDropdown
