import React from "react"

function SuccessWindow({ show, onClose, message }) {
  if (!show) {
    return null
  }
  return (
    <div className="success-window" onClick={onClose}>
      <div className="info-row">{message}</div>
    </div>
  )
}

export default SuccessWindow
