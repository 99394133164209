import React from "react"
import minusIcon from "../../images/dish/minus_icon.svg"
import plusIcon from "../../images/dish/plus_icon.svg"

function QuantityCounter({ quantityIsValid, quantity, setQuantity, limit, disabled }) {
  return (
    <div
      className={
        quantityIsValid ? "quantity-counter" : "quantity-counter --error"
      }
    >
      <div
        onClick={() => {
          if (!disabled && quantity !== 1) {
            setQuantity(quantity - 1)
          }
        }}
      >
        <img src={minusIcon} alt="-" />
      </div>
      <div>
        <input
          className="input-quantity"
          type="number"
          value={quantity}
          disabled={disabled}
          onChange={event => {
              if (!disabled) {
                  const value = +event.target.value
                  setQuantity(value)
              }
          }}
        />
      </div>
      <div
        onClick={() => {
            if (!disabled) {
                if (limit) {
                    if (quantity < limit) {
                        setQuantity(quantity + 1)
                    }
                } else {
                    setQuantity(quantity + 1)
                }
            }
        }}
      >
        <img src={plusIcon} alt="+" />
      </div>
    </div>
  )
}

export default QuantityCounter
