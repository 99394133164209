import React, { useRef, useState } from "react"
import { useTranslation } from "react-i18next"
import "./ItemAccordion.scss"

function ItemSummary({
  name,
  value
}) {
  const {t} = useTranslation("main")
  const { innerWidth: width } = window

  return (
      <div>
        <span className="summary-item-name">
          {name}
        </span>
        <span>
          {value}
        </span>
      </div>
  )
}

export default ItemSummary
