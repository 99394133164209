/* eslint-disable react/jsx-no-target-blank */
import React, { useEffect, useState } from "react"
import ModalWindow from "../ModalWindow"
import closeGray from "../../images/header/close-gray-icon.svg"
import contactCircleIcon from "../../images/contact/contact_circle_icon.svg"
import emailIcon from "../../images/login/email_icon.svg"
import phoneIcon from "../../images/login/phone_icon.svg"
import { ReactComponent as Timer } from "../../images/timer_icon.svg"
import penListIcon from "../../images/contact/pen_list_icon.svg"
import dialogIcon from "../../images/contact/dialog_icon.svg"
import commentsIcon from "../../images/dish/comments_icon.svg"
import "./ContactWindow.scss"
import { connect } from "react-redux"
import { useTranslation } from "react-i18next"
import { Trans } from "react-i18next"
import NotifyWindow from "../NotifyWindow"
import { setError } from "../../redux/actions/errorHandlerActions"
import { sendMessage } from "./contactUsCrud"

function ContactWindow({ show, onClose, ...props }) {
    const { t, i18n } = useTranslation("main");

  const { innerWidth: width } = window
  const [currentSubject, setCurrentSubject] = useState({ value: 0, text: "" })
  const [currentBody, setCurrentBody] = useState("")
  const [subjectsExpanded, setSubjectsExpanded] = useState(false)
  const [errorFields, setErrorFields] = useState([])
  const [showSuccessDialog, setShowSuccessDialog] = useState(false)

  const contactPhone = "+972-50-3755855"
  const contactEmail = (process.env.REACT_APP_FLAVOR === 'NY') ? "pm@dish.co.il" : "eat@dish.co.il";

  const subjects = [
    { value: 1, text: t("contact_us.form.subject.service_inquiry") },
    { value: 2, text: t("contact_us.form.subject.quality") },
    { value: 3, text: t("contact_us.form.subject.billing") },
    { value: 4, text: t("contact_us.form.subject.mismatch_in_order") },
    { value: 5, text: t("contact_us.form.subject.other") }
  ]

  useEffect(() => {
    if (subjectsExpanded) {
      document.addEventListener("click", closeDropdown)
    }
  }, [subjectsExpanded])

  function closeDropdown(event) {
    event.preventDefault()
    setSubjectsExpanded(false)
    document.removeEventListener("click", closeDropdown)
  }

  function handleExpand() {
    setSubjectsExpanded(!subjectsExpanded)
  }

  function handleChangeSubject(subject) {
    setCurrentSubject(subject)
    setErrorFields(errorFields.filter(error => error !== "subject"))
  }

  function handleChangeBody(event) {
    event.preventDefault()
    setCurrentBody(event.target.value)
    setErrorFields(errorFields.filter(error => error !== "body"))
  }

  function openChat() {
    window.open("https://api.whatsapp.com/send?phone=972503755855", "_blank")
  }

  function openEmail() {
    window.open("mailto:" + contactEmail)
  }

  function openDailer() {
    window.open("tel:" + contactPhone)
  }

  function handleSend(event) {
    event.preventDefault()
    const errors = []
    if (!currentBody) {
      errors.push("body")
    }
    if (!currentSubject.value) {
      errors.push("subject")
    }
    if (errors.length !== 0) {
      setErrorFields(errors)
      return
    }
    sendMessage(currentSubject.value, currentBody)
      .then(() => setShowSuccessDialog(true))
      .catch(error =>
        props.setError(error, t("contact_us.api_errors.failed_to_send_message"))
      )
  }

  function handleCloseWindow(event) {
    event.preventDefault()
    setShowSuccessDialog(false)
    onClose()
  }

  return (
    <>
      <NotifyWindow
        show={showSuccessDialog}
        primaryText={t("contact_us.success_window.primary")}
        confirmText={t("contact_us.success_window.continue")}
        onConfirm={handleCloseWindow}
        onClose={() => setShowSuccessDialog(false)}
      />
      <div className="contact-window-wrapper">
        <ModalWindow show={show}>
          <img
            className="confirmation-window-close-icon"
            src={closeGray}
            alt="Close icon"
            onClick={handleCloseWindow}
          />
          <div className="confirmation-window-tag">
            <img src={contactCircleIcon} alt="contact" />
          </div>
          <div className="confirmation-window-primary-text">
            {t("contact_us.title")}
          </div>
          <div className="divider" />
          <div className="confirmation-window-secondary-text">
            <div className="email-phone-wrapper">
              <div className="info-line" onClick={openDailer}>
                <img src={phoneIcon} alt="phone" className="info-icon" />
                <span className="info phone px-2">{contactPhone}</span>
              </div>
              {width >= 1024 && <div className="horizontal-divider">|</div>}
              <div className="info-line" onClick={openEmail}>
                <img src={emailIcon} alt="email" className="info-icon" />
                <span className="info px-2">{contactEmail}</span>
              </div>
            </div>
            <div className="info-line">
              <Timer className="info-icon fill-red" />
              <span className="info px-2">{t("contact_us.active_time_text")}</span>
            </div>
          </div>
          <div className="divider" />
          <div className="confirmation-window-message">
            <div className={`${localStorage.getItem('lang') === 'he' ? 'form-title' : 'form-title form-title-en'}`} >{t("contact_us.form.title")}</div>
            <span>{t("contact_us.form.message")}</span>
          </div>
          <div
            className={
              errorFields.includes("subject")
                ? "input-field subject-field error-field"
                : "input-field subject-field"
            }
          >
            <label>{t("contact_us.form.subject.label")}</label>
            <div className="drop-down-wrapper" onClick={handleExpand}>
              <div
                className={
                  subjectsExpanded ? "drop-down expanded" : "drop-down"
                }
              >
                <input
                  style={{ 
                    background: "url(" + commentsIcon + ")", 
                    cursor: "pointer",
                    textAlign: localStorage.getItem('lang') === 'he' ? "right" : "left",
                    textAlignLast: localStorage.getItem('lang') === 'he' ? "right" : "left",
                    padding: localStorage.getItem('lang') === 'he' ? "" : "0px 0px 0px 45px",
                    width: "100%"
                  }}
                  placeholder={t("contact_us.form.subject.placeholder")}
                  readOnly={true}
                  value={currentSubject.text}
                />
                <div className="drop-down-contents">
                  {subjects.map(subject => (
                    <div
                      key={subject.value}
                      className={
                        currentSubject.value === subject.value
                          ? "drop-down-option active"
                          : "drop-down-option"
                      }
                      onClick={() => handleChangeSubject(subject)}
                    >
                      {subject.text}
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
          <div
            className={
              errorFields.includes("body")
                ? "input-field error-field"
                : "input-field"
            }
          >
            <label>{t("contact_us.form.body.label")}</label>
            <textarea
              style={{ 
                background: "url(" + penListIcon + ")",
                textAlign: localStorage.getItem('lang') === 'he' ? "right" : "left",
                textAlignLast: localStorage.getItem('lang') === 'he' ? "right" : "left",
                padding: localStorage.getItem('lang') === 'he' ? "" : "10px 0px 0px 20px",
                width: "100%"
              }}
              placeholder={t("contact_us.form.body.placeholder")}
              value={currentBody}
              onChange={handleChangeBody}
            />
          </div>
          <div className="confirmation-window-footer">
            <button className="button white" onClick={handleCloseWindow}>
              {t("contact_us.cancel_btn")}
            </button>
            <button className="button" onClick={handleSend}>
              {t("contact_us.submit_btn")}
            </button>
              <a href="https://api.whatsapp.com/send?phone=972503755855" target="_blank" 
                className={localStorage.getItem('lang') === 'he' ? "button dialog" : "button dialog dialog-en"}>
              <img src={dialogIcon} alt="dialog" /> {t("contact_us.dialog_btn")}
            </a>
          </div>
        </ModalWindow>
      </div>
    </>
  )
}

function mapStateToProps(state) {
  return {
    userInfo: state.login.userInfo
  }
}

const mapDispatchToProps = {
  setError
}

export default connect(mapStateToProps, mapDispatchToProps)(ContactWindow)
