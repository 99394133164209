import React from "react"

function Tabs({ ...props }) {
  return (
    <>
      <div className="tabs">
        {props.tabs.map(tab => {
          const active = tab === props.activeTab ? "active" : ""

          return (
            <a
              className={"tab " + active}
              onClick={() => props.setActiveTab(tab)}
              key={tab}
            >
              {tab}
            </a>
          )
        })}
      </div>
      <div className="content">{props.children}</div>
    </>
  )
}

export default Tabs
