import React, { useRef, useState } from "react"

import "./Accordion.scss"

function Accordion(props) {
  const [setActive, setActiveState] = useState(false)
  const [setHeight, setHeightState] = useState("0px")

  const content = useRef(null)

  function toggleAccordion() {
    setActiveState(setActive ? false : true)
    setHeightState(setActive ? "0px" : `${content.current.scrollHeight}px`)
  }

  return (
    <div className="accordion__section">
      <div
        className={`accordion ${setActive ? "active" : ""}`}
        onClick={toggleAccordion}
      >
        <div className="accordion__title">{props.title}</div>

        <div
          ref={content}
          className="accordion__content"
          style={{ maxHeight: `${setHeight}` }}
        >
          <div className="accordion__text">{props.content}</div>
        </div>
      </div>
    </div>
  )
}

export default Accordion
