import React from "react"
import { useTranslation } from "react-i18next"
import Radio from '@material-ui/core//Radio';
import "./PaymentSelectionForm.scss"

function PaymentSelectionForm({fullAmount, selectedAmount, onChangeAmount, isFullAmountSelected, onSelectOption, isInvalid, amountInputRef}) {
  const {t} = useTranslation("main")

  return (
    <div className="payment-selection-form">
      <div className="payment-selection-form__option-selector">
        <div className={`payment-selection-form__option ${isFullAmountSelected && isInvalid ? "--error" : ""}`} onClick={() => onSelectOption("all")}>
          <Radio
            disableRipple
            checked={isFullAmountSelected}
            onChange={() => onSelectOption("all")}
          />
          <span className="payment-selection-form__option-text">{`₪${fullAmount}`}</span>
        </div>
        <div className="payment-selection-form__option" onClick={() => onSelectOption("part")}>
          <Radio
            disableRipple
            checked={!isFullAmountSelected}
            onChange={() => onSelectOption("part")}
          />
          <span className="payment-selection-form__option-text">{t("order.payment_selection_form.partial_amount")}</span>
        </div>
      </div>
      <div ref={amountInputRef} className="payment-selection-form__amount-input-wrapper">
        <input
          type="number"
          value={selectedAmount}
          disabled={isFullAmountSelected}
          onChange={(event) => onChangeAmount(event.target.value)}
          placeholder={t("order.payment_selection_form.enter_amount")}
          className={`payment-selection-form__amount-input ${isInvalid ? "--error" : ""}`}
          dir="ltr"
        />
      </div>
    </div>
  )
}

export default PaymentSelectionForm
