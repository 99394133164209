import React, { useState } from "react"
import ModalWindow from "../ModalWindow"
import { ReactComponent as RatingCircleIcon } from "../../images/history/rating_circle_icon.svg"
import { ReactComponent as RatingStar } from "../../images/history/rating_star_icon.svg"
import { ReactComponent as CloseCross } from "../../images/header/close-gray-icon.svg"
import penIcon from "../../images/history/red_pen_icon.svg"
import { useTranslation } from "react-i18next"
import { setError } from "../../redux/actions/errorHandlerActions"
import { connect } from "react-redux"
import { sendRating } from "./historyCrud"

function RatingWindow({ show, selectedId, onClose, onSubmit, setError }) {
    const { t, i18n } = useTranslation("main");

  const [rating, setRating] = useState(0)
  const [message, setMessage] = useState("")
  const [isSubmitting, setIsSubmitting] = useState(false)

  function handleSubmit() {
    setIsSubmitting(true)
    sendRating(rating, message, selectedId)
      .then(() => {
        setIsSubmitting(false)
        onSubmit(rating)
        setMessage("")
        setRating(0)
      })
      .catch(error => {
        setIsSubmitting(false)
        console.error(error)
        setError(error, t("history.api_errors.failed_to_set_rating"))
      })
  }

  return (
    <div className="rating-window">
      <ModalWindow show={show}>
        <CloseCross className="close-icon" onClick={onClose} />
        <div className="rating-window__tag">
          <RatingCircleIcon />
        </div>
        <div className="rating-window__title">{t("history.rating.title")}</div>
        <div className="rating-window__rating-row">
          {[1, 2, 3, 4, 5].map(index => (
            <RatingStar
              key={index}
              className={
                index <= rating ? "rating-star fill-red" : "rating-star"
              }
              onClick={() => setRating(index)}
            />
          ))}
        </div>
        <div className="input-wrapper">
          <div>
            <label>{t("history.rating.text_field.label")}</label>
            <div className="input-field-wrapper">
              <input
                name="message"
                type="text"
                placeholder={t("history.rating.text_field.placeholder")}
                value={message}
                onChange={event => setMessage(event.target.value)}
              />
              <img src={penIcon} alt="pen" className="input-type-icon" />
            </div>
          </div>
        </div>
        <div className="rating-window__button-row">
          <button
            className="rating-window__button"
            onClick={handleSubmit}
            disabled={isSubmitting || rating === 0}
          >
            {t("history.rating.submit_btn")}
          </button>
        </div>
      </ModalWindow>
    </div>
  )
}

const mapDispatchToProps = {
  setError
}

export default connect(null, mapDispatchToProps)(RatingWindow)
