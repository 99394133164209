import React, { useState } from "react"
import Header from "./components/header/Header"
import Navbar from "./components/navbar/Navbar"
import ContactWindow from "./components/contact/ContactWindow"
import ErrorWindow from "./components/ErrorWindow"
import SuccessWindow from "./components/SuccessWindow"
import { clearError } from "./redux/actions/errorHandlerActions"
import { clearSuccess } from "./redux/actions/successHandlerActions"
import { connect } from "react-redux"

function Layout({ errorMessage, successMessage, clearError, clearSuccess, setShowContactUs, showContactUs }) {
  const [open, setOpen] = useState(false)

  return (
    <>
      <Header
        open={open}
        setOpen={setOpen}
        setShowContactUs={setShowContactUs}
      />
      <Navbar
        open={open}
        setOpen={setOpen}
        setShowContactUs={setShowContactUs}
      />
      <ContactWindow
        show={showContactUs}
        onClose={() => setShowContactUs(false)}
      />
      <div className="error-snackbar">
        <ErrorWindow
          show={errorMessage.length !== 0}
          message={errorMessage}
          onClose={() => clearError()}
        />
      </div>
      <div className="success-snackbar">
        <SuccessWindow
            show={successMessage.length !== 0}
            message={successMessage}
            onClose={() => clearSuccess()}
        />
      </div>
    </>
  )
}

const mapDispatchToProps = {
  clearError,
  clearSuccess
}

export default connect(null, mapDispatchToProps)(Layout)
