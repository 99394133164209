import React, { useState } from "react"
import moment from "moment"
import { ReactComponent as ChevronLeft } from "../../images/history/chevron_left.svg"
import { ReactComponent as ChevronRight } from "../../images/history/chevron_right.svg"
import "moment/locale/he"
import "./Calendar.scss"

function Calendar({ initialRange, setSelectedRange, isDayForbidden, isSingleSelection, minDate }) {
  const [state, setState] = useState({
    currentMonth: initialRange.from ? initialRange.from : new Date(),
    selectedRange: { from: initialRange.from, to: initialRange.to },
    isStartSelected: true
  })

  function renderHeader() {
    const dateFormat = "MMMM"

    return (
      <div className="calendar-header row flex-middle">
        <div className="col col-start">
          <div className="icon" onClick={prevMonth}>
            <ChevronRight />
          </div>
        </div>
        <div className="col col-center">
          <span>
            {moment(state.currentMonth).locale("he").format(dateFormat)}
          </span>
        </div>
        <div className="col col-end" onClick={nextMonth}>
          <div className="icon">
            <ChevronLeft />
          </div>
        </div>
      </div>
    )
  }

  function renderDays() {
    const dateFormat = "dddd"
    const days = []

    let startDate = moment(state.currentMonth).startOf("week")

    for (let i = 0; i < 7; i++) {
      days.push(
        <div className="col col-center" key={i}>
          {moment(startDate).locale("he").add(i, "days").format(dateFormat)}
        </div>
      )
    }

    return <div className="days row">{days}</div>
  }

  function renderCells() {
    const { currentMonth } = state
    const monthStart = moment(currentMonth).startOf("month")
    const monthEnd = moment(monthStart).endOf("month")
    const startDate = moment(monthStart).startOf("week")
    const endDate = moment(monthEnd).endOf("week")

    const dateFormat = "D"
    const rows = []

    let days = []
    let day = startDate
    let formattedDate = ""

    while (day <= endDate) {
      for (let i = 0; i < 7; i++) {
        formattedDate = moment(day).format(dateFormat)
        const cloneDay = day
        const isDisabled = !moment(day).isSame(monthStart, "month")
        const isForbidden = isDayForbidden(day)
        days.push(
          <div
            className={`col cell ${
              isForbidden || isDisabled || (minDate && !minDate(cloneDay))
                ? "disabled"
                : moment(day).isBetween(
                    state.selectedRange.from,
                    state.selectedRange.to,
                    null,
                    "[]"
                  )
                ? "selected"
                : ""
            }`}
            key={day}
            onClick={() => {
              if (!(isForbidden || isDisabled || (minDate && !minDate(cloneDay)))) {
                onDateClick(cloneDay)
              }
            }}
          >
            <div className="number">{formattedDate}</div>
          </div>
        )
        day = moment(day).add(1, "days")
      }
      rows.push(
        <div className="row" key={day}>
          {days}
        </div>
      )
      days = []
    }
    return <div className="body">{rows}</div>
  }

  const onDateClick = day => {
    if (isSingleSelection) {
      const newRange = {}
      newRange.from = day
      newRange.to = day
      setState({
        ...state,
        selectedRange: newRange,
        isStartSelected: true
      })
      setSelectedRange(newRange)
    } else {
      if (state.isStartSelected) {
        setState({
          ...state,
          selectedRange: { from: day, to: day },
          isStartSelected: false
        })
      } else {
        const newRange = {}
        if (moment(day).isBefore(state.selectedRange.from)) {
          newRange.from = day
          newRange.to = state.selectedRange.from
        } else {
          newRange.from = state.selectedRange.from
          newRange.to = day
        }
        setState({
          ...state,
          selectedRange: newRange,
          isStartSelected: true
        })
        setSelectedRange(newRange)
      }
    }

  }

  const nextMonth = () => {
    setState({
      ...state,
      currentMonth: moment(state.currentMonth).add(1, "month")
    })
  }

  const prevMonth = () => {
    setState({
      ...state,
      currentMonth: moment(state.currentMonth)
        .subtract(1, "month")
    })
  }

  return (
    <div className="calendar">
      {renderHeader()}
      {renderDays()}
      {renderCells()}
    </div>
  )
}

export default Calendar
