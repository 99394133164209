import React from "react"
import { useTranslation } from "react-i18next"
import "../main/MainPage.scss"

function BudgetsDropdown({
  budgetsExpanded,
  onExpand,
  searchRef,
  budgetSearchText,
  handleSearchBudgetChange,
  budgets,
  selectedBudget,
  selectBudget
}) {
    const { t, i18n } = useTranslation("main");

    function getDate(createdDate) {
        var allDate = createdDate.split("T")[0];
        var year = allDate.split("-")[0];
        var month = allDate.split("-")[1];
        var day = allDate.split("-")[2];
        return day + '.' + month + '.' + year;
    }

  return (
    <div className={budgetsExpanded ? "drop-down expanded" : "drop-down"}>
      <a onClick={onExpand}>
          {budgetsExpanded
              ? t("main.select_budget")
              : selectedBudget
                  ? getDate(selectedBudget.from) + '-' + getDate(selectedBudget.to) + ' ' + selectedBudget.balance
                  : t("main.no_budget")}
      </a>
      <ul>
        {budgets.map((el, index) => (
          <li
            className={
              selectedBudget && selectedBudget.budgetID === el.budgetID
                ? "active"
                : ""
            }
            onClick={() => selectBudget(el)}
            key={el.budgetID}
          >
            <a>{getDate(el.from) + '-' + getDate(el.to) + ' ' + el.balance}</a>
          </li>
        ))}
      </ul>
    </div>
  )
}

export default BudgetsDropdown
