import React from "react"
import { NavLink } from "react-router-dom"
import "./Navbar.scss"

function NavItem({ name, img, url, handleClick }) {
  return (
    <NavLink
      onClick={handleClick}
      to={`/${url}`}
      className="menu-item"
      activeClassName="active"
    >
      <div className="menu-item-img">
        <img src={img} alt="" />
      </div>
      <span>{name}</span>
    </NavLink>
  )
}

export default NavItem
