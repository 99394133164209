import { Types } from "../actionTypes"

export function setError(data, message) {
  return {
    type: Types.SET_ERROR,
    data: { status: data.response ? data.response.status : 503, message }
  }
}
export function clearError() {
  return { type: Types.CLEAR_ERROR }
}
