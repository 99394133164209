import React from "react"
import NoResultsIcon from "../images/no_results_icon.svg"
import { useTranslation } from "react-i18next"

function NoResultsFiller({ message, hideIcon }) {

  const { t, i18n } = useTranslation("main");

  return (
    <div className={i18n.language === 'en' ? 'no-results-filler no-results-filler-reverse' : 'no-results-filler'}>
      {!hideIcon &&
        <img src={NoResultsIcon} alt="No results" />
      }
      <div>{message}</div>
    </div>
  )
}

export default NoResultsFiller
