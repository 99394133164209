import { Types } from "../actionTypes"

export default function loginReducer(
  state = {
    token: localStorage.getItem("Token")
      ? { ...JSON.parse(localStorage.getItem("Token")) }
      : undefined,
    userInfo: localStorage.getItem("UserInfo")
      ? { ...JSON.parse(localStorage.getItem("UserInfo")) }
      : undefined
  },
  action
) {
  switch (action.type) {
    case Types.SEND_OTP_SUCCESS:
      return {
        ...state,
        otp: { ...action.data }
      }
    case Types.SEND_OTP_FAILED:
      return {
        ...state,
        otp: { ...state.otp, error: action.data }
      }
    case Types.RESEND_OTP_SUCCESS:
      return {
        ...state,
        otp: { ...state.otp, NextResend: action.data.NextResend }
      }
    case Types.VALIDATE_OTP_SUCCESS: {
      return {
        ...state,
        token: { ...action.data, isValidated: true }
      }
    }
    case Types.VALIDATE_OTP_FAILED: {
      return {
        ...state,
        token: { ...state.token, error: action.data }
      }
    }
    case Types.UPDATE_USER_INFO: {
      localStorage.setItem(
        "UserInfo",
        JSON.stringify({ ...action.data, confirmedLogin: true })
      )
      return {
        ...state,
        userInfo: { ...action.data }
      }
    }
    case Types.UPDATE_USER_BANK_INFO: {
      return {
        ...state,
        bankInfo: { ...action.data }
      }
    }
    case Types.GET_USER_INFO_SUCCESS: {
      return {
        ...state,
        token: { Token: action.data.Token, ValidTo: action.data.ValidTo },
        userInfo: { ...action.data.UserInfo }
      }
    }
    case Types.GET_USER_INFO_FAILED: {
      return {
        ...state,
        token: { ...state.token, error: action.data }
      }
    }
    case Types.CONFIRM_LOGIN: {
      localStorage.setItem("Token", JSON.stringify(state.token))
      localStorage.setItem(
        "UserInfo",
        JSON.stringify({ ...state.userInfo, confirmedLogin: true })
      )
      return {
        ...state,
        userInfo: { ...state.userInfo, confirmedLogin: true }
      }
    }
    case Types.LOGOUT: {
      localStorage.removeItem("Token")
      localStorage.removeItem("UserInfo")
      localStorage.removeItem("favoriteItems")
      return {}
    }
    default:
      return state
  }
}
