import React from "react"
import ModalWindow from "./ModalWindow"
import { Trans, useTranslation } from "react-i18next"
import termsIcon from "../images/terms/terms_icon.svg"
import checkBox from "../images/terms/checkbox.svg"
import checkBoxCheck from "../images/terms/checkbox_check.svg"

function TermsOfServiceWindow({
  show,
  onChange,
  onClose,
  isAccepted,
  ...props
}) {
    const { t, i18n } = useTranslation("main");
  return (
    <ModalWindow show={show}>
      <div className="terms-tag">
        <img src={termsIcon} alt="Terms tag" />
      </div>
      <div className="info-row">
        {t("login.terms_window.info")}
        <div className="link-to-terms-row">
          <Trans t={t} i18nKey="login.terms_window.agree_text">
            I agree to the <a href="">terms and conditions of use</a>
          </Trans>
          <span className="checkbox-wrapper" onClick={onChange}>
            <img src={checkBox} alt="Checkbox" />
            <img
              src={checkBoxCheck}
              alt="checkbox check"
              className={
                isAccepted
                  ? "checkbox-check checked"
                  : "checkbox-check not-checked"
              }
            />
          </span>
        </div>
      </div>
      <button className="terms-confirm-button" onClick={onClose}>
        {t("login.terms_window.submitbtn")}
      </button>
    </ModalWindow>
  )
}

export default TermsOfServiceWindow
