import axios from "axios"

export function getSummary(userID, orderID) {
  return axios.post(`/User/${userID}/Orders/Summary`, {
    Id: orderID
  })
}

export function sendOrder(userID, data) {
  return axios.post(`/User/${userID}/Orders`, data)
}

export function submitOrder(orderID, userID, data) {
  return axios.post(`/Order/Submit`, null, {
    params: {
      userID,
      orderId: orderID,
      ...data
    }
  })
}

export function deleteItem(userID, orderID, itemID) {
  return axios.delete(`/User/${userID}/Orders/${orderID}/Item/${itemID}`)
}

export function changeItem(userID, orderID, item) {
  return axios.patch(`/User/${userID}/Orders/Item`, {
    Id: orderID,
    ItemId: item.Id,
    Item: item
  })
}

export function changeCombo(userID, orderID, item) {
  return axios.patch(`/User/${userID}/Orders/Combo`, {
    Id: orderID,
    ComboId: item.Id,
    Combo: item
  })
}

export function getPaymentStatus(userID) {
  return axios.post(`/User/${userID}/Orders/Payment/Status`, {})
}

export function getCreditCardToken(userID) {
  return axios.get("/User/Orders/GetCCTokent", {
    params: {
      userID
    }
  })
}

export function getLimitBalance(userID, orderID) {
  return axios.post(`/User/${userID}/Orders/LimitBalance`, {
    Id: orderID
  })
}

export function getBudget(userID, orderID) {
  return axios.get("/Order/GetBudget", {
    params: {
      userID,
      orderId: orderID
    }
  })
}

export function getCustomerCreditAvailable(userID, orderID) {
  return axios.get("/Order/GetCustomerCredit", {
    params: {
      userID,
      orderId: orderID
    }
  })
}
