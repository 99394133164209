import React, { useEffect, useState } from "react"
import { connect } from "react-redux"
import { useTranslation } from "react-i18next"
import { NavLink, withRouter } from "react-router-dom"
import { Link } from "react-router-dom"
import { useSelector } from "react-redux"
import dishTagRed from "../../images/dish_logo_full.svg"
import cartIcon from "../../images/header/cart_icon.svg"
import { logout } from "../../redux/actions/loginActions"
import { selectCustomer } from "../../redux/actions/adminActions"
import { setSearchText, setSearchUser } from "../../redux/actions/searchActions"
import girlWithPizza from "../../images/girl_with_pizza.png"
import crown from "../../images/crown-solid.png"
import "./Header.scss"
import { useHistory } from "react-router-dom";

function Header({
  open,
  setOpen,
  setShowContactUs,
  searchText,
  searchUser,
  setSearchText,
  setSearchUser,
  selectCustomer,
  logout,
  userInfo,
  ...props
}) {
  const history = useHistory();
  const { t, i18n } = useTranslation("main");
  const [showDropdown, setShowDropDown] = useState(false)
  const [showSidePicture, setShowSidePicture] = useState(true)

  const isAuthorized = userInfo ? userInfo.confirmedLogin : false
  const isRoleDelivery = isAuthorized && userInfo.Role === "Delivery"
  const isRoleAdmin = isAuthorized && userInfo.Role === "Admin"
  const isRoleDepartmentUser =
    isAuthorized && userInfo.Role === "Department User"

    const userCart = useSelector(state => state.cart[searchUser.UserID])

  useEffect(() => {
    if (showDropdown) {
      document.addEventListener("click", closeDropdown)
    }
  }, [showDropdown])

  useEffect(() => {
    document.addEventListener("scroll", e => {
      let scrolled = document.scrollingElement.scrollTop
      if (scrolled >= 80) {
        setShowSidePicture(false)
      } else {
        setShowSidePicture(true)
      }
    })
  }, [])

  function closeDropdown(event) {
    event.preventDefault()
    setShowDropDown(false)
    document.removeEventListener("click", closeDropdown)
  }

  function handleSearchTextChange(event) {
    setSearchText(event.target.value)
  }

  function handleRedirectToOrders() {
    props.history.push("/order")
  }

  function renderRole() {
    if (isRoleAdmin) {
      return t("navigation.admin")
    }
    if (isRoleDelivery) {
      return t("navigation.delivery")
    }
    return
  }
  function getDropdownOptionsLength() {
    if (isRoleDepartmentUser || isRoleAdmin || isRoleDelivery) {
      return 4
    }
    return 5
  }

  let headerStyle = {}
  if (process.env.REACT_APP_ENV === "DEV") {
    headerStyle = {
      background: "#48be25"
    }
  }

  return (
    <>
          {isAuthorized ? (
    <div>
       <div className={i18n.language === 'en' ? 'header-reverse' : 'header'} style={headerStyle}>
          {/*
          <img 
            className={`page-image ${!showSidePicture && "page-image--hidden"}`}
            src={girlWithPizza}
          ></img>
          */}
                      
          {isRoleAdmin && (
            <img src={crown} style={{ height: 30 }} alt="crown" />
          )}
          <div
            className={`drop-down-menu-wrapper ${
              showDropdown ? "expanded" : ""
            }`}
          >
            <div className="drop-down-menu-handler-wrapper">
              <div
                className="drop-down-menu-handler"
                onClick={() => setShowDropDown(true)}
              >
                <span className="user-name" dir={i18n.dir()}>
                  <div>{userInfo.FirstName + " " + userInfo.LastName}</div>
                  <div>
                    {userInfo.Customer ? userInfo.Customer.Name : renderRole()}
                  </div>
                </span>
              </div>
              <ul
                className="drop-down-menu"
                style={{
                  height: showDropdown ? 41 * getDropdownOptionsLength() : 0
                }}
              >
                {!isRoleDepartmentUser && !isRoleAdmin && !isRoleDelivery && (
                  <li className="drop-down-menu-item profile">
                    <Link to="/profile">{t("navigation.profile")}</Link>
                  </li>
                )}
                {!(process.env.REACT_APP_FLAVOR=='NY') && !isRoleDepartmentUser && !isRoleAdmin && !isRoleDelivery && (
                    <li className="drop-down-menu-item profile">
                      <Link to="/bank-profile">{t("navigation.bank_profile")}</Link>
                    </li>
                )}
                <li
                  className="drop-down-menu-item support"
                  onClick={() => setShowContactUs(true)}
                >
                  <Link to="support" className="disabled-link">
                    {t("navigation.support")}
                  </Link>
                </li>
                <li className="drop-down-menu-item faq">
                  <Link to="/faq">{t("navigation.faq")}</Link>
                </li>
                <li
                  className="drop-down-menu-item logout"
                  onClick={() => {
                    setSearchUser({})
                    selectCustomer(null)
                    logout()
                  }}
                >
                  {t("navigation.logout")}
                </li>
              </ul>
            </div>
          </div>
          {!isRoleDelivery && (
            <div className="cart-wrapper" onClick={handleRedirectToOrders}>
              <div className="cart-inner-wrapper">
                <img id="cart-icon-tag" src={cartIcon} alt="Cart Icon" />
                <div className="counter-wrapper">
                  <span className="cart-count">
                    {" "}
                    {userCart ? userCart.count : 0}{" "}
                  </span>
                </div>
              </div>
            </div>
          )}
          <input
            className="search-field"
            value={searchText}
            onChange={handleSearchTextChange}
          />
          <div className="navigation-list-wrapper">
            {!isRoleDelivery &&  (
              <div className="navigation-list">
                {!(process.env.REACT_APP_FLAVOR=='NY') && (
                  <NavLink
                    to="/store"
                    className="navigation-list-item"
                    activeClassName="active"
                  >
                    {t("navigation.store")}
                  </NavLink>
                )}
                {!isRoleDepartmentUser && (
                  <NavLink
                    to="/history"
                    className="navigation-list-item"
                    activeClassName="active"
                  >
                    {t("navigation.history")}
                  </NavLink>
                )}
                <NavLink
                  to="/main"
                  className="navigation-list-item"
                  activeClassName="active"
                >
                  {t("navigation.main")}
                </NavLink>
              </div>
            )}
          </div>
          <img id="small-dish-tag" src={dishTagRed} alt="Dish tag" onClick={ev => history.push("/main")}/>
          <div className="hamburger" onClick={() => setOpen(!open)}>
            <div className="hamburger-border"></div>
            <div className="hamburger-border"></div>
            <div className="hamburger-border"></div>
          </div>
         </div>

    </div>
        
      ) : null}
    </>
  )
}

function mapStateToProps(state) {
  return {
    userInfo: state.login.userInfo,
    headerVisible: state.header.headerVisible,
    searchText: state.search.searchText,
    searchUser: state.search.searchUser
  }
}

const mapDispatchToProps = {
  logout,
  setSearchText,
  setSearchUser,
  selectCustomer
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Header))
