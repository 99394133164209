import React from "react"
import { useTranslation } from "react-i18next"
import "../main/MainPage.scss"

function LocationsDropdown({
  locationsExpanded,
  onExpand,
  searchRef,
  locationSearchText,
  handleSearchLocationChange,
  locations,
  selectedLocation,
  selectLocation
}) {
    const { t, i18n } = useTranslation("main");

  return (
    <div className={locationsExpanded ? "drop-down expanded" : "drop-down"}>
      <a onClick={onExpand}>
        {locationsExpanded
          ? t("main.select_order_location")
          : selectedLocation
          ? selectedLocation.Name
          : t("main.no_location")}
      </a>
      <div ref={searchRef} className="search drop-down-search">
        <input
          className="search-field"
          value={locationSearchText}
          onChange={handleSearchLocationChange}
        />
      </div>
      <ul>
        {locations.map((el, index) => (
          <li
            className={
              selectedLocation && selectedLocation.LocationID === el.LocationID
                ? "active"
                : ""
            }
            onClick={() => selectLocation(el)}
            key={el.LocationID}
          >
            <a>{el.Name}</a>
          </li>
        ))}
      </ul>
    </div>
  )
}

export default LocationsDropdown
