import { Types } from "../actionTypes"
import axios from "axios"

export function sendOtpSuccess(data) {
  return { type: Types.SEND_OTP_SUCCESS, data }
}
export function sendOtpFailed(message, code = null) {
    var data = {
        message: message,
        code: code
    }
    return { type: Types.SEND_OTP_FAILED, data };
}
export function resendOtpSuccess(data) {
  return { type: Types.RESEND_OTP_SUCCESS, data }
}
export function validateOtpSuccess(data) {
  return { type: Types.VALIDATE_OTP_SUCCESS, data }
}
export function validateOtpFailed(data) {
  return { type: Types.VALIDATE_OTP_FAILED, data }
}
export function getUserInfoSuccess(data) {
  return { type: Types.GET_USER_INFO_SUCCESS, data }
}
export function getUserInfoFailed(data) {
  return { type: Types.GET_USER_INFO_FAILED, data }
}
export function updateUserInfo(data) {
  return { type: Types.UPDATE_USER_INFO, data }
}
export function editBankUserInfo(data) {
  return { type: Types.UPDATE_USER_BANK_INFO, data }
}
export function confirmLogin() {
  return { type: Types.CONFIRM_LOGIN }
}
export function logout() {
  return { type: Types.LOGOUT }
}

export function sendOtp(payload) {
  return function (dispatch) {
    return sendOtpRequest(payload)
      .then(({ data }) => {
        return dispatch(sendOtpSuccess(data));
      })
      .catch((error) => {
        let errorMessage = "Request Failed";
        let errorCode = null;

        if (error.response) {
          errorCode = error.response.status;
        }

        dispatch(sendOtpFailed(errorMessage, errorCode));
      });
  };
}

export function sendOtpRequest(data) {
  return axios.post(`${process.env.REACT_APP_BASE_PATH}/Login/SendOTP`, data, {
    headers: {
      "content-type": "application/json"
    }
  })
}

export function resendOtp(payload) {
  return function (dispatch) {
    return resendOtpRequest(payload)
      .then(({ data }) => {
        dispatch(resendOtpSuccess(data))
      })
      .catch(error => {
        throw error
      })
  }
}

export function resendOtpRequest(data) {
  return axios.post(`${process.env.REACT_APP_BASE_PATH}/Login/ResndOTP`, data, {
    headers: {
      "content-type": "application/json"
    }
  })
}

export function validateOtp(payload) {
  return function (dispatch) {
    return validateOtpRequest(payload)
      .then(({ data }) => {
        dispatch(validateOtpSuccess(data))
      })
      .catch(error => {
        dispatch(validateOtpFailed("Request Failed"))
      })
  }
}

export function validateOtpRequest(data) {
  return axios.post(
    `${process.env.REACT_APP_BASE_PATH}/Login/ValidateOTP`,
    data,
    {
      headers: {
        "content-type": "application/json"
      }
    }
  )
}

export function getUserInfo(payload) {
  return function (dispatch) {
    return getUserInfoRequest(payload)
      .then(({ data }) => {
        setOneSignalUserId(data.UserInfo.UserID)
        dispatch(getUserInfoSuccess(data))
      })
      .catch(error => {
        dispatch(getUserInfoFailed("Request Failed"))
      })
  }
}

function setOneSignalUserId(externalUserId ) {
  if (window.cordova) {
    window.plugins.OneSignal.setExternalUserId(externalUserId, results => {
      // The results will contain push and email success statuses
      console.log("Results of setting external user id")
      console.log(results)

      // Push can be expected in almost every situation with a success status, but
      // as a pre-caution its good to verify it exists
      if (results.push && results.push.success) {
        console.log("Results of setting external user id push status:")
        console.log(results.push.success)
      }

      // Verify the email is set or check that the results have an email success status
      if (results.email && results.email.success) {
        console.log("Results of setting external user id email status:")
        console.log(results.email.success)
      }
    })
  }
}

export function getUserInfoRequest(data) {
  return axios.get(`${process.env.REACT_APP_BASE_PATH}/Login/GetUserInfo`, {
    headers: {
      "content-type": "application/json",
      Authorization: data
    }
  })
}
