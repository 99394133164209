export const Types = {
  SEND_OTP_SUCCESS: "SEND_OTP_SUCCESS",
  SEND_OTP_FAILED: "SEND_OTP_FAILED",
  RESEND_OTP_SUCCESS: "RESEND_OTP_SUCCESS",
  VALIDATE_OTP_SUCCESS: "VALIDATE_OTP_SUCCESS",
  VALIDATE_OTP_FAILED: "VALIDATE_OTP_FAILED",
  GET_USER_INFO_SUCCESS: "GET_USER_INFO_SUCCESS",
  GET_USER_INFO_FAILED: "GET_USER_INFO_FAILED",
  UPDATE_USER_INFO: "UPDATE_USER_INFO",
  UPDATE_USER_BANK_INFO: "UPDATE_USER_BANK_INFO",
  CONFIRM_LOGIN: "CONFIRM_LOGIN",
  LOGOUT: "LOGOUT",

  GET_CUSTOMER_LIST_SUCCESS: "GET_CUSTOMER_LIST_SUCCESS",
  GET_USERS_LIST_SUCCESS: "GET_USERS_LIST_SUCCESS",
  SELECT_CUSTOMER: "SELECT_CUSTOMER",

  SET_HEADER_VISIBLE: "SET_HEADER_VISIBLE",

  SET_SEARCH_TEXT: "SET_SEARCH_TEXT",
  SET_SEARCH_DATE: "SET_SEARCH_DATE",
  SET_SEARCH_BUDGET: "SET_SEARCH_BUDGET",
  SET_SEARCH_USER: "SET_SEARCH_USER",
  SET_SEARCH_LOCATION: "SET_SEARCH_LOCATION",
  SET_SEARCH_MEAL_TYPE: "SET_SEARCH_MEAL_TYPE",
  SET_SEARCH_CONFIGURATION: "SET_SEARCH_CONFIGURATION",

  CLEAR_CART: "CLEAR_CART",
  INCREASE_COUNT: "INCREASE_COUNT",
  DECREASE_COUNT: "DECREASE_COUNT",

  ADD_CARD: "ADD_CARD",
  REMOVE_CARD: "REMOVE_CARD",

  SEND_ITEM_TO_TEMP_ORDER: "SEND_ITEM_TO_TEMP_ORDER",

  SET_ERROR: "SET_ERROR",
  SET_SUCCESS: "SET_SUCCESS",
  CLEAR_ERROR: "CLEAR_ERROR",
  CLEAR_SUCCESS: "CLEAR_SUCCESS"
}
