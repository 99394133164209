import React from "react"
import { useTranslation } from "react-i18next"
import "./ProfilePage.scss"

function Card({ number, expiration, removeCard }) {
    const { t, i18n } = useTranslation("main");

  return (
    <div className="card">
      <div className="card-number">
        <span className="parameter"> {t("profile.card.number")}:</span>
        <span className="value">{number}</span>
      </div>
      <div className="card-expiration">
        <span className="parameter"> {t("profile.card.expiration")}:</span>
        <span className="value">{expiration}</span>
      </div>
      <a className="card-remove-button" onClick={removeCard}>
        {t("profile.card.remove")}
      </a>
    </div>
  )
}

export default Card
