import React, { useEffect, useRef, useState } from "react"
import { connect } from "react-redux"
import { useTranslation } from "react-i18next"
import { setSearchText, setSearchUser } from "../../redux/actions/searchActions"
import moment from "moment"
import LoadingPage from "../LoadingPage"
import ConfirmationWindow from "../ConfirmationWindow"
import calendarIcon from "../../images/history/calendar_icon_filled.svg"
import deleteCircleIcon from "../../images/history/delete_circle_icon.svg"
import RatingWindow from "./RatingWindow"
import OrderAgainWindow from "./OrderAgainWindow"
import Calendar from "./Calendar"
import "./OrderHistoryPage.scss"
import Footer from "../footer/Footer"
import { setError } from "../../redux/actions/errorHandlerActions"
import NotifyWindow from "../NotifyWindow"
import NoResultsFiller from "../NoResultsFiller"
import { getItemHistory } from "./historyCrud"
import { getUserInfo, updateOrder, updateComboOrder, deleteOrder, deleteComboOrder, saveItemToTempStorageByAgainOrder, getAviableDays } from "../main/mainCrud"
import OrderDishPage from "../dish/OrderDishPage"
import OrderComboDishPage from "../dish/OrderComboDishPage"
import {
  formatDate,
  formatDateForApi
} from "../commonFunctions"
import DropdownPanel from "../configuration/DropdownPanel"
import axios from "axios"
import ItemAccordion from "./ItemAccordion"
import LogoFooter from "../main/LogoFooter"
import ItemSummary from "./ItemSummary";
import { logout } from "../../redux/actions/loginActions";
import {
    saveTempItemToOrder,
    incCount
} from "../../redux/actions/cartActions.js"
import { setSuccess } from "../../redux/actions/successHandlerActions";
// import DetailsWindow from "./DetailsWindow"

function OrderHistoryPage(props) {
    const { t, i18n } = useTranslation("main");
  const { innerWidth: width } = window

  const initialFrom = moment().startOf("day").subtract(1, "week")
  const [users, setUsers] = useState([])
  const [editItem, setEditItem] = useState(null)
  const [selectedRangeForItems, setSelectedRangeForItems] = useState({
    from: initialFrom,
    to: moment().add(10, "day")
  })
  const [itemHistory, setItemHistory] = useState({ data: [], isLoading: true })

  useEffect(() => {
      document.body.classList.toggle('lang-en', i18n.language === 'en');
  }, [i18n.language]);

  const [deleteDialogData, setDeleteDialogData] = useState({
    show: false,
    OrderId: null,
    ItemID: null
  })
  const [ratingDialogData, setRatingDialogData] = useState({
    show: false,
    id: null
  })
  const [orderAgainDialogData, setOrderAgainDialogData] = useState({
    show: false,
    id: null
  })

  const [showCalendar, setShowCalendar] = useState(false)
  // const [detailsData, setDetailsData] = useState({ show: false, data: {} })

  const [showSuccessDialog, setShowSuccessDialog] = useState(false)

  const calendarWindow = useRef(null)
  const [calendarWindowContainer, setCalendarWindowContainer] = useState(null)
  const [aviableDays, setAviableDays] = useState([]);

  useEffect(() => {
      if (orderAgainDialogData.show && orderAgainDialogData.id) {
          const cancelToken = axios.CancelToken.source()
          getAviableDays(props.searchUser.UserID, orderAgainDialogData.id, cancelToken.token)
              .then(({ data }) => {
                  setAviableDays(data);
              })
              .catch(error => {
                  props.setError(error, t("main.api_errors.failed_to_get_user_info"));
              });
      }
  }, [orderAgainDialogData, props.searchUser.UserID]);

  useEffect(() => {
    const isUserAdmin = props.userInfo.Role === "Admin"
    const id = !isUserAdmin
      ? props.userInfo.UserID
      : props.adminUsers.length && props.adminUsers[0].UserID
    if (id) {
      const cancelToken = axios.CancelToken.source()
      getUserInfo(id, cancelToken.token)
        .then((response) => {
          if (!response) {
            logout();
            return;
          }
          const data = response.data;
          const orderForUsers = !isUserAdmin
            ? data.OrderForUsers
            : props.adminUsers
          setUsers(orderForUsers)
          props.setSearchUser(
            data.OrderForUsers.find(
              user => user.UserID === props.searchUser.UserID
            ) ||
              (isUserAdmin
                ? orderForUsers.find(
                    user => user.UserID !== props.userInfo.UserID
                  )
                : {
                    UserID: props.userInfo.UserID,
                    Name: `${props.userInfo.FirstName} ${props.userInfo.LastName}`
                  })
          )
        })
        .catch(error => {
          if (!axios.isCancel(error)) {
            props.setError(error, t("main.api_errors.failed_to_get_user_info"))
          }
        })
      return () => cancelToken.cancel()
    }
  }, [props.adminUsers])

  useEffect(() => {
    loadInfo()
  }, [selectedRangeForItems, props.searchUser.UserID])

  function loadInfo() {
    if (props.searchUser.UserID && selectedRangeForItems.from) {
      const cancelToken = axios.CancelToken.source()
      setItemHistory({ ...itemHistory, isLoading: true })
      getItemHistory(
          props.searchUser.UserID,
          formatDateForApi(selectedRangeForItems.from),
          formatDateForApi(selectedRangeForItems.to),
          cancelToken.token
      )
          .then(({ data }) => {
            setItemHistory({ data, isLoading: false, summary: calculateItems(data) })
          })
          .catch(error => {
            if (!axios.isCancel(error)) {
              setItemHistory({ data: [], isLoading: false })
              console.error(error)
              props.setError(
                  error,
                  t("history.api_errors.failed_to_get_item_history")
              )
            }
          })
      return () => cancelToken.cancel()
    }
  }

    function calculateItems(items) {
    const cancelled = items.filter(item => item.Status === 2).length;
    const delivered = items.filter(item =>
        item.Status === 4 ||
        ((item.Status === 1 || item.Status === 5) && moment(item.MealDate.substring(0, 11) + item.DeliveryMinTime).isBefore())
        ).length;
    const refunded = items.filter(item => !!item.RefundDate).length;
    const inProgress = items.filter(item =>
        (item.Status === 1 || item.Status === 5) && !moment(item.MealDate.substring(0, 11) + item.DeliveryMinTime).isBefore()
        ).length;

    if (inProgress<0){
      inProgress = 0;
    }
    return {
      cancelled,
      delivered,
      refunded,
      inProgress
    }
  }

  function selectUser(id) {
    setItemHistory({ ...itemHistory, isLoading: true })
    getUserInfo(id)
      .then(() => {
        props.setSearchUser(users.find(user => user.UserID === id))
      })
      .catch(error => {
        console.error(error)
        props.setError(error, t("main.api_errors.failed_to_get_user_info"))
      })
  }

  useEffect(() => {
    if (showCalendar) {
      document.addEventListener("click", handleCloseCalendar)
    }
  }, [showCalendar])

  useEffect(() => {
    if (!calendarWindowContainer) {
      setCalendarWindowContainer(calendarWindow.current)
    }
  }, [calendarWindow.current])

  function handleCloseCalendar(event) {
    if (!calendarWindowContainer.contains(event.target)) {
      closeCalendar()
    }
  }

  function closeCalendar() {
    setShowCalendar(false)
    document.removeEventListener("click", handleCloseCalendar)
  }

  //-----------------------------------------------------------------------
  function renderItemList() {
    let summary = <></>;
    if (itemHistory.summary) {
      summary =
          <>
            <ItemSummary name={t("history.summary.totalCancelled")} value={itemHistory.summary.cancelled}></ItemSummary>
            <ItemSummary name={t("history.summary.totalDelivered")} value={itemHistory.summary.delivered}></ItemSummary>
            <ItemSummary name={t("history.summary.totalRefunded")} value={itemHistory.summary.refunded}></ItemSummary>
            <ItemSummary name={t("history.summary.totalInProgress")} value={itemHistory.summary.inProgress}></ItemSummary>
          </>
    }
    return <>
      {
        <div className="summary">
          <div>
            <span className="summary-item-name">
              { t("history.summary.totalCount") }
            </span>
            <span>
              { itemHistory.data.length }
            </span>
          </div>
          {
            summary
          }
        </div>
      }
      {
        itemHistory.data.map(item =>
            <ItemAccordion
                item={item}
                handleOpenEditDialog={handleOpenEditDialog}
                setRatingDialogData={setRatingDialogData}
                setOrderAgainDialogData={setOrderAgainDialogData}
                setDeleteDialogData={setDeleteDialogData}
            />
        )
      }
    </>


  }

  function renderCalendar() {
    return (
        <div className={i18n.language === 'en' ? 'input-calendar-wrapper-reverse' : 'input-calendar-wrapper'} dir={i18n.dir()}>
        <div className="input-calendar" onClick={() => setShowCalendar(true)}>
          <input
            value={
              selectedRangeForItems
                ? `${formatDate(selectedRangeForItems.from)} - ${formatDate(
                    selectedRangeForItems.to
                  )}`
                : ""
            }
            className="input-calendar__input"
            disabled={true}
          />
          <img
            src={calendarIcon}
            alt="Calendar"
            className="input-calendar__icon"
          />
        </div>
        <div ref={calendarWindow}>
          {showCalendar && (
            <div className="calendar-wrapper">
              <Calendar
                initialRange={selectedRangeForItems}
                setSelectedRange={range => {
                  setSelectedRangeForItems(range)
                  closeCalendar()
                }}
                isDayForbidden={day =>
                  moment(day).isAfter(moment().add(10, "day"), "day")
                }
              />
            </div>
          )}
        </div>
      </div>
    )
  }

  function renderHistoryContents() {
    function checkForResults(elementToRender) {
      return itemHistory.isLoading ? (
        <LoadingPage />
      ) : itemHistory.data.length === 0 ? (
        <NoResultsFiller message={t("history.no_results")} />
      ) : (
        elementToRender
      )
    }

    return (
      <div className="order-history w-100" dir={i18n.dir()}>
        {width < 1024 ? (
          <div className="content-wrapper">
            {renderCalendar()}
            {checkForResults(renderItemList())}
          </div>
        ) : (
          checkForResults(renderItemList())
        )}
      </div>
    )
  }
  //-----------------------------------------------------------------------

  function saveRating(rating) {
    const items = [...itemHistory.data]
    const changedItem = items.find(
      item => item.OrderItemID === ratingDialogData.id
    )
    changedItem.Rating = rating
    setItemHistory({ data: items, isLoading: false, summary: calculateItems(items) })
  }

  function editComboOrder(data) {
    return updateComboOrder(
        props.searchUser.UserID,
        editItem.OrderId,
        editItem.ComboID,
        data
    )
  }
  
  function editOrder(data) {
    return updateOrder(
      props.searchUser.UserID,
      editItem.OrderId,
      editItem.ItemID,
      data
    )
  }

  function handleOpenEditDialog(item) {
    const selected = []
    if (!item.ComboID) {
      if (item.Options) {
        item.Options.forEach(el => selected.push(...el.Options))
      }
    } else {
      item.Items.forEach(it => {
        it.Options.forEach(el => selected.push(...el.Options.map(i => {
          return {
            ...i,
            ItemID: it.ItemID
          }
        })))
      })
    }
    setEditItem({
      ItemID: item.ItemID,
      ComboID: item.ComboID,
      OrderId: item.OrderID,
      OrderItemID: item.OrderItemID,
      Id: item.Id,
      MealID: item.MealID,
      OrderItemQuantity: item.Quantity,
      OrderItemNotes: item.Notes,
      SelectedOptions: selected
    })
  }

  function confirmEditOrder(data) {
    const items = [...itemHistory.data]
    const changedItem = items.find(el => el.ItemID === editItem.ItemID)
    const optionGroups = []
    data.options.forEach(option => {
      const optionGroup = optionGroups.find(
        el => el.Name === option.OptionGroupName
      )
      if (!optionGroup) {
        optionGroups.push({
          GroupName: option.OptionGroupName,
          Icon: option.Icon,
          Options: [option]
        })
      } else {
        optionGroup.Options = [...optionGroup.Options, option]
      }
    })

    changedItem.Options = optionGroups
    changedItem.Notes = data.notes

    setItemHistory({
      data: items,
      isLoading: false,
      summary: calculateItems(items)
    })
  }

  function confirmComboEditOrder(data) {
    loadInfo();
  }
    function saveTempMealItemToOrder(orderItemId, date) {
        saveItemToTempStorageByAgainOrder(
            props.searchUser.UserID,
            orderItemId,
            formatDateForApi(date)
        )
            .then(({data}) => {
                props.saveTempItemToOrder(props.searchUser.UserID, data.Id)
                props.incCount(props.searchUser.UserID, data.Quantity, data.EmployeeSum)
                props.setSuccess({status: 1}, t("order_item.api_success.success_to_save_to_cart"))
            })
            .catch(error => {
                const errorMessage = error?.response?.data?.Message || error.message;
                if (errorMessage === "Order Item Limit") {
                    props.setError(error, t("order_item.api_errors.there_is_an_order_for_this_day_in_Cart"))
                } else if (errorMessage === "Invalid rating time") {
                    props.setError(error, t("order_item.api_errors.time_is_over"))
                } else {
                    props.setError(error, t("order_item.api_errors.failed_to_save_to_cart"))
                }
            })
    }
  
  function cancelOrder(orderID, itemID, comboID) {
    if (comboID) {
      deleteComboOrder(props.searchUser.UserID, orderID, comboID)
          .then(() => {
            loadInfo();
          })
          .catch(error => {
            console.error(error)
            props.setError(error, t("main.api_errors.failed_to_cancel_order"))
          })
      return;
    }
    deleteOrder(props.searchUser.UserID, orderID, itemID)
      .then(() => {
        const newItems = itemHistory.data.filter(
            item => item.OrderID !== orderID || item.ItemID !== itemID
        );
        setItemHistory({
          data: newItems,
          isLoading: false,
          summary: calculateItems(newItems)
        })
      })
      .catch(error => {
        console.error(error)
        props.setError(error, t("main.api_errors.failed_to_cancel_order"))
      })
  }

  return (
    <div className="main-page-content-wrapper">
      <div className="main-wrapper history-main-wrapper">
        <NotifyWindow
          show={showSuccessDialog}
          primaryText={t("history.success_window.primary")}
          confirmText={t("history.success_window.continue")}
          onConfirm={() => setShowSuccessDialog(false)}
          onClose={() => setShowSuccessDialog(false)}
        />
        {editItem?.ItemID && (
          <div className="main-order-wrapper">
            <OrderDishPage
              editData={editItem}
              confirmEditOrder={confirmEditOrder}
              submitData={editOrder}
              removeItemId={() => setEditItem(null)}
              goToOrder={() => {}}
              itemID={editItem.ItemID}
              mealID={editItem.MealID}
              userID={props.searchUser.UserID}
              date={editItem.selectedDate}
            />
          </div>
        )}
        {editItem?.ComboID && (
            <div className="main-order-wrapper">
              <OrderComboDishPage
                  editData={editItem}
                  confirmEditOrder={confirmComboEditOrder}
                  submitData={editComboOrder}
                  removeComboId={() => setEditItem(null)}
                  goToOrder={() => {}}
                  comboID={editItem.ComboID}
                  mealID={editItem.MealID}
                  userID={props.searchUser.UserID}
                  date={editItem.selectedDate}
              />
            </div>
        )}
        <div className="order-history">
          <ConfirmationWindow
            show={deleteDialogData.show}
            icon={deleteCircleIcon}
            primaryText={t("history.remove_dialog.primary")}
            confirmText={t("history.remove_dialog.continue")}
            cancelText={t("history.remove_dialog.cancel")}
            onConfirm={() =>
              setDeleteDialogData({
                show: false
              })
            }
            onCancel={() => {
              cancelOrder(deleteDialogData.OrderId, deleteDialogData.ItemID, deleteDialogData.ComboID)
              setDeleteDialogData({
                show: false
              })
            }}
            onClose={() =>
              setDeleteDialogData({
                show: false
              })
            }
          />
          {/* <DetailsWindow
            show={detailsData.show}
            itemData={detailsData.data}
            onClose={() => setDetailsData({ show: false, data: {} })}
          /> */}
          <RatingWindow
            show={ratingDialogData.show}
            selectedId={ratingDialogData.id}
            onSubmit={rating => {
              saveRating(rating)
              setShowSuccessDialog(true)
              setRatingDialogData({ show: false })
            }}
            onClose={() => setRatingDialogData({ show: false })}
          />
          
          <OrderAgainWindow
            show={orderAgainDialogData.show}
            selectedId={orderAgainDialogData.id}
            onSubmit={date => {
              saveTempMealItemToOrder(orderAgainDialogData.id, date)
              setOrderAgainDialogData({ show: false })
            }}
            aviableDays={aviableDays}
            onClose={() => setOrderAgainDialogData({ show: false })}
          />
        </div>
        <div className="configurations mobile-only">
          <DropdownPanel
            users={users}
            onSelectUser={selectUser}
            mobile={true}
          />
        </div>
        <div
          className={
            editItem
              ? "main-content-wrapper hidden-on-mobile"
              : "main-content-wrapper"
          }
        >
          <div className="configurations history-desktop-dropdown">
            <DropdownPanel users={users} onSelectUser={selectUser} />
          </div>
          <div className="dishes-list-title-wrapper desktop-only mr-0">
            {width >= 1024 && renderCalendar()}
          </div>
          <div className="dishes-list-wrapper history-list-wrapper">{renderHistoryContents()}</div>
        </div>
      </div>
      {width < 1024 ? <Footer/> :  <LogoFooter />}
    </div>
  )
}

function mapStateToProps(state) {
  return {
    userInfo: state.login.userInfo,
    searchText: state.search.searchText,
    searchUser: state.search.searchUser,
    adminUsers: state.admin.users,
    selectedCustomer: state.admin.selectedCustomer
  }
}

const mapDispatchToProps = {
  saveTempItemToOrder,
  incCount,
  setSearchText,
  setSearchUser,
  setError,
  setSuccess
}

export default connect(mapStateToProps, mapDispatchToProps)(OrderHistoryPage)
