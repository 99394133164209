import React from "react"
import ModalWindow from "./ModalWindow"
import closeGray from "../images/header/close-gray-icon.svg"
import successIcon from "../images/success_icon.svg"

function NotifyWindow({
  show,
  icon,
  primaryText,
  secondaryText,
  confirmText,
  onConfirm,
  onClose
}) {
  return (
    <ModalWindow show={show}>
      <img
        className="confirmation-window-close-icon"
        src={closeGray}
        alt="Close icon"
        onClick={onClose}
      />
      <div className="confirmation-window-tag">
        <img src={icon || successIcon} alt="success" />
      </div>
      <div className="confirmation-window-primary-text">{primaryText}</div>
      <div className="confirmation-window-secondary-text">{secondaryText}</div>
      <div className="confirmation-window-footer justify-content-center">
        <button className="button" onClick={onConfirm}>
          {confirmText}
        </button>
      </div>
    </ModalWindow>
  )
}

export default NotifyWindow
