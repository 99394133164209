import React, { useState } from "react";
import ModalWindow from "../ModalWindow";
import Calendar from "./Calendar";
import { ReactComponent as CloseCross } from "../../images/header/close-gray-icon.svg";
import { useTranslation } from "react-i18next";
import moment from "moment"
import "moment/locale/he"

function OrderAgainWindow({ show, onClose, onSubmit, aviableDays }) {
    const { t } = useTranslation("main");

    const [selectedDate, setSelectedDate] = useState(null);
    const [isSubmitting, setIsSubmitting] = useState(false);

    const isDayForbidden = (day) => {
        const today = moment().add(1, "day");
        const maxDate = moment().add(2, "weeks");
        const dayOfWeek = day.day();

        return (
            day.isBefore(today, "day") ||
            day.isAfter(maxDate, "day") ||
            !aviableDays.includes(dayOfWeek) 
        );
    };

    async function handleDateSubmit() {
        if (!selectedDate) return;

        setIsSubmitting(true);
        try {
            await onSubmit(selectedDate);
        } finally {
            setIsSubmitting(false);
        }
    }

    return (
        <div className="date-picker-window">
            <ModalWindow show={show}>
                <CloseCross className="close-icon" onClick={onClose} />
                <div className="date-picker-window__title">{t("history.date.title")}</div>
                <div className="date-picker-window__calendar">
                    <Calendar
                        initialRange={{ from: null, to: null }}
                        setSelectedRange={(range) => setSelectedDate(range.from)} 
                        isSingleSelection={true}
                        isDayForbidden={isDayForbidden}
                    />
                </div>
                <div className="date-picker-window__button-row">
                    <button
                        className="date-picker-window__button"
                        onClick={handleDateSubmit}
                        disabled={isSubmitting || !selectedDate}
                    >
                        {isSubmitting ? t("history.date.submitting") : t("history.date.submit_btn")}
                    </button>
                </div>
            </ModalWindow>
        </div>
    );
}

export default OrderAgainWindow;