import React from "react";

function OrderTitle({ title, description, type }) {
    return <div>
        <div className={type === 'small' ? 'meal-name-small' : 'meal-name'}>{title}</div>
        <div className={type === 'small' ? 'meal-description-small' : 'meal-description'}>
            {description}
        </div>
    </div>
}
export default OrderTitle
