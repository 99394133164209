import React from "react"
import Switch from '@material-ui/core/Switch';
import "./ProfilePage.scss"

function SwitchField({
  label,
  name,
  value,
  onChange,
  disabled
}) {

  function handleClick(event) {
    onChange({
      target: {
        name: name,
        value: !value
      }
    })
  }

  return (
    <div className="form-switch">
      <label>{label}</label>
      <Switch
        name={name}
        checked={value}
        onClick={handleClick}
        // onChange={onChange} Doesn't fire for some reason
        disabled={disabled}
        className="form-switch__switch"
      />
    </div>
  )
}

export default SwitchField
