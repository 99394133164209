import { Types } from "../actionTypes"

export function setSuccess(data, message) {
  return {
    type: Types.SET_SUCCESS,
    data: { status: data.response, message }
  }
}
export function clearSuccess() {
  return { type: Types.CLEAR_SUCCESS }
}
