import React from "react"
import { useTranslation } from "react-i18next"

function ModalWindow({ show, children, ...props }) {
  const { t, i18n } = useTranslation();
  if (!show) {
    return null
  }
  return (
    <div className="modal-overlay" dir={i18n.dir()}>
      <div className="modal-content">{children}</div>
    </div>
  )
}

export default ModalWindow
