import React from "react"
import errorIcon from "../images/login/error_icon.svg"

function ErrorWindow({ show, onClose, message }) {
  if (!show) {
    return null
  }
  return (
    <div className="error-window" onClick={onClose}>
      <div className="error-tag">
        <img src={errorIcon} alt="Error icon" />
      </div>
      <div className="info-row">{message}</div>
    </div>
  )
}

export default ErrorWindow
