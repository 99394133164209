import React from "react"
import { useTranslation } from "react-i18next"
import "./ProfilePage.scss"
import { withRouter } from "react-router"
import ProfileSidebarIcon from "../../images/profile_icon_outline_sidebar.svg"
import BillingInfoSidebarIcon from "../../images/billing_info_sidebar_mobile.svg"
import walletIcon from "../../images/wallet.png"
import { NavLink } from "react-router-dom"
import { Redirect } from "react-router-dom"

function AccountPage(props) {
    const { t, i18n } = useTranslation("main");

  const { innerWidth: width } = window

  const menuItems = [
    {
      itemId: 1,
      name: `${t("profile.personal_information")}`,
      icon: ProfileSidebarIcon,
      url: "profile?tab=personal",
    },
    {
      itemId: 2,
      name: `${t("profile.billing_information")}`,
      icon: BillingInfoSidebarIcon,
      url: "profile?tab=billing",
    },
    {
      itemId: 3,
      name: `${t("profile.wallet_information")}`,
      icon: walletIcon,
      url: "profile?tab=wallet",
    },
    {
      itemId: 4,
      name: `${t("profile.budget_information")}`,
      icon: walletIcon,
      url: "profile?tab=budget",
    },
  ]

  return (
    <div className="main-wrapper">
      <div className="account-page-wrapper">
        <h1>{t("profile.personal_information")}</h1>
        <nav className="menu-nav">
          {menuItems.map((el, index) => (
            <NavLink
              key={el.itemId}
              to={`/${el.url}`}
              className="menu-item"
            >
              <img
                src={el.icon}
                alt="icon"
                className="menu-item__icon"
              />
              <span>{el.name}</span>
            </NavLink>
          ))}
        </nav>
        {width >= 1024 && <Redirect to="/profile" />}
      </div>
    </div>
  )
}

export default withRouter(AccountPage)
