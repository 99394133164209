/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from "react"
import { useTranslation } from "react-i18next"
import { connect } from "react-redux"
import { removeCard } from "../../redux/actions/cardActions.js"
import { useDispatch, useSelector } from "react-redux"
import Card from "./Card"
import "./ProfilePage.scss"
import TranzilaWindow from "../order/TranzilaWindow"
import * as cardActions from "../../redux/actions/cardActions"

function BillingInformation({ cards, removeCard }) {
    const { t, i18n } = useTranslation("main");
    const [showTranzilaWindow, setShowTranzilaWindow] = useState(false);
    const userInfo = useSelector(state => state.login.userInfo)
    const isRoleAdmin = userInfo.Role === "Admin"
    const selectedCustomer = useSelector(state => state.admin.selectedCustomer)
    const isRoleDepartmentUser = userInfo.Role === "Department User"

    // dispatcher for redux actions
    const dispatch = useDispatch()

    function saveCard(creditCardToken, expirationMonth, expirationYear) {
        if (cards.find(el => el.creditCardToken === creditCardToken) === undefined) {
            const newCard = { creditCardToken, expirationMonth, expirationYear }
            dispatch(cardActions.addCard(newCard))
            cards.push(newCard)
        }
    }

    return (
         <div className="profile-content-wrapper" dir={i18n.dir()}>
            <div className="cards-wrapper">
                <h1>{t("profile.billing_information")}</h1>
                {cards.map((el, index) => {
                    return el.creditCardToken ? (
                        <Card
                            number={`**** **** *** ${el.creditCardToken?.slice(-4)}`}
                            expiration={el.expirationMonth + "/" + el.expirationYear}
                            removeCard={() => removeCard(el.creditCardToken)}
                            key={index}
                        />
                    ) : <></>
                })}

                <a
                    className="add-card-button"
                    onClick={() => setShowTranzilaWindow(true)}
                >
                    {t("order.add-new-card")}
                </a>
            </div>
            

            {showTranzilaWindow && (
                <TranzilaWindow
                    show={showTranzilaWindow}
                    src={`https://direct.tranzila.com/${
                        process.env.REACT_APP_TRANZIL_TREMINAL
                    }/iframenew.php?hidesum=1&sum=1&currency=1&cred_type=1&lang=il&tranmode=VK&nologo=1&notify_url_address=${encodeURIComponent(
                        `${process.env.REACT_APP_BASE_PATH}/User/Orders/Notify`
                    )}&userId=${userInfo.UserID}&company=${encodeURIComponent(
                        !isRoleAdmin ? userInfo.Customer.Name : selectedCustomer.Name
                    )}&contact=${encodeURIComponent(userInfo.FirstName + " " + userInfo.LastName)}&email=${encodeURIComponent(
                        userInfo.Email
                    )}&phone=${userInfo.Mobile}`}
                    saveCard={saveCard}
                    onClose={() => setShowTranzilaWindow(false)}
                />
            )}
        </div>
    )
}

function mapStateToProps(state) {
    return {
        cards: state.cards
    }
}

const mapDispatchToProps = {
    removeCard
}
export default connect(mapStateToProps, mapDispatchToProps)(BillingInformation)
